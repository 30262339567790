import React, { useEffect, useState, useRef } from 'react'
import { FaSearch, FaChevronDown, FaTimes } from 'react-icons/fa'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'
import classNames from 'classnames'

const Loader = () => (
  <>
    <div className="animate-pulse my-4 h-4 w-full rounded-xl bg-gray-300" />
    <div className="animate-pulse my-4 h-4 w-full rounded-xl bg-gray-300" />
    <div className="animate-pulse my-4 h-4 w-full rounded-xl bg-gray-300" />
  </>
)

const Dropdown = ({
  name,
  items,
  emptyMessage,
  searchState = [],
  isLoading,
  onIntersecting,
  onDropdownOpen,
  onItemChange,
  error,
  value,
  disabled,
  disposable
}) => {
  const [visibleDropdown, setVisibleDropdown] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [search, setSearch] = searchState

  const firstRender = useRef(true)

  const handleSearch = e => {
    setSearch(e.target.value)
  }

  const openDropdown = () => {
    onDropdownOpen && onDropdownOpen()
    setVisibleDropdown(true)
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    onItemChange && onItemChange(selectedItem)
  }, [selectedItem])

  const selectItem = item => {
    setSelectedItem(item)
    setVisibleDropdown(false)
  }

  const getItems = () => items.map((item, i) => (
    <div
      key={i}
      className="py-2 border-b-2 border-gray-200 cursor-pointer"
      onClick={() => selectItem(item)}
    >
      {item.value} 
    </div>
  ))

  const clearSelectedItem = e => {
    e.stopPropagation()
    setSelectedItem({})
  }

  return (
    <div className="relative">
      <button
        type="button"
        className={classNames({
          'p-2 cursor-pointer text-left w-full border border-gray-300 rounded-lg flex items-center': true,
          'bg-white': !disabled,
          'border-red-500': !!error,
          'bg-gray-50 cursor-auto': disabled
        })}
        onClick={openDropdown}
        disabled={disabled}
      >
        {
          disposable && (selectedItem.value || value?.value) &&  (
            <FaTimes
              className="text-gray-400 mr-1.5"
              onClick={e => clearSelectedItem(e)}
            />
          )
        }

        {
          !selectedItem.value && !!value?.value && (
            <div>
              {value.value}
            </div>
          )
        }
        {
          !selectedItem.value && !value?.value && (
            <div className="text-gray-400">
              {name}
            </div>
          )
        }
        {
          !!selectedItem.value && (
            <div>
              {selectedItem.value}
            </div>
          )
        }

        <FaChevronDown className="ml-auto text-gray-500" />
      </button>

      {
        visibleDropdown && (
          <div
            onClick={() => setVisibleDropdown(false)}
            className="fixed top-0 left-0 h-screen w-screen z-10"
          />
        )
      }

      {
        visibleDropdown && (
          <div className="absolute left-0 mt-2 max-h-60 overflow-y-auto shadow rounded-xl w-full bg-white z-20 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
            {
              !!searchState.length && (
                <div className="my-2 mx-4 p-2 mb-2 border border-gray-300 rounded-lg flex items-center sticky top-0 left-0 bg-white">
                  <FaSearch className="text-gray-400 mr-1.5" />
                  <input
                    type="text"
                    className="w-11/12 text-sm outline-none"
                    onChange={handleSearch}
                    value={search || ''}
                  />
                </div>
              )
            }

            <div className="py-3 px-4">
              {
                !!items.length && (
                  <>
                    {getItems()}
                    <InView as="div" onChange={inView => inView && onIntersecting && onIntersecting()}>
                      <div />
                    </InView>
                  </>
                )
              }
              {
                isLoading && <Loader />
              }
              {
                !items.length && !isLoading && (
                  <div className="text-center my-4">
                    {emptyMessage}
                  </div>
                )
              }
            </div>
          </div>
        )
      }
    </div>
  )
}

Dropdown.propTypes = {
  name: PropTypes.string,
  items: PropTypes.array,
  emptyMessage: PropTypes.string,
  searchState: PropTypes.array,
  isLoading: PropTypes.bool,
  onDropdownOpen: PropTypes.func,
  onItemChange: PropTypes.func,
  error: PropTypes.bool,
  value: PropTypes.object,
  disabled: PropTypes.bool,
  disposable: PropTypes.bool
}

export default Dropdown