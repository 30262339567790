import React, { useState } from 'react'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

import Modal from '@/components/Modal'

const TextComponent = ({ visibleState, onSubmit, initial }) => {

  const [editorState, setEditorState] = useState(() => {
    if (initial) {
      const blocksFromHtml = htmlToDraft(initial)
      const { contentBlocks, entityMap } = blocksFromHtml
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
      return EditorState.createWithContent(contentState)
    }
    return EditorState.createEmpty()
  })

  const submit = () => {
    const raw = convertToRaw(editorState.getCurrentContent())
    onSubmit && onSubmit({
      type: 'TEXT',
      text: draftToHtml(raw)
    })

    visibleState[1](false)

    setEditorState(EditorState.createEmpty())
  }

  return (
    <Modal visibleState={visibleState}>
      <section className="flex flex-col">
        <div className="font-semibold text-lg">
          Text
        </div>

        <hr className="my-3" />

        <section className="mt-2">
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            editorClassName="border border-gray-100 px-2"
            toolbar={{
              options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'emoji', 'image', 'history'],
              inline: {
                options: ['bold', 'italic', 'underline', 'strikethrough']
              },
              list: {
                options: ['unordered', 'ordered']
              },
              blockType: {
                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
              }
            }}
        />
        </section>

        <section className="mt-4 self-end">
          <button
            className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-2"
            onClick={() => visibleState[1](false)}
            type="button"
          >
            Cancel
          </button>
          <button
            className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
            onClick={submit}
          >
            Create
          </button>
        </section>
      </section>
    </Modal>
  )
}

export default TextComponent