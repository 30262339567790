import React, { useEffect, useState } from 'react'
import { FaUpload } from 'react-icons/fa'
import toast from 'react-hot-toast'
import { uploadProductImage } from '@/store/slices/image'
import { useDispatch } from 'react-redux'

import Header from '@/components/Header'
import FullPageLoader from '@/components/FullPageLoader'

const ImageUploaderPage = () => {
  const [files, setFiles] = useState([])
  const [previewImages, setPreviewImages] = useState([])
  const [images, setImages] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const dispatch = useDispatch()

  const readFileAsDataUrl = file => new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(file)
  })

  const handleImageChange = async e => {
    const newFiles = Array.from(e.target.files)
    setFiles([
      ...files,
      ...newFiles
    ])

    const promises = newFiles.map(f => readFileAsDataUrl(f))
    const previewImageDataUrls = await Promise.all(promises)
    setPreviewImages([
      ...previewImages,
      ...previewImageDataUrls
    ])
  }

  const upload = async () => {
    if (!files.length) {
      toast.error('Please upload image first')
      return
    }

    setIsUploading(true)

    try {
      const form = new FormData()
      files.forEach(file => {
        form.append('files', file)
      })
      const response = await dispatch(uploadProductImage(form)).unwrap()
      setImages(response.data)
    } catch(err) {
      toast.error('Oops something wrong, please try again')
    }

    setIsUploading(false)
  }

  return (
    <>
      {isUploading && <FullPageLoader />}
      <Header title="Image Uploader">
        <div className="flex flex-wrap">
          {
            !!previewImages.length && previewImages.map((preview, index) => (
              <img key={index} src={preview} className="border border-gray-200 w-48 h-48 object-contain aspect-square mr-3 mb-3" />
            ))
          }
          <div className="w-48 h-48 border-2 border-dotted border-blue-300">
            <label htmlFor="image-input" className="w-full h-full flex flex-col items-center justify-center cursor-pointer">
              <FaUpload className="text-3xl text-blue-300" />
              <div className="mt-1 text-blue-300">
                Upload
              </div>
            </label>

            <input
              id="image-input"
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleImageChange}
              style={{
                width: '0.1px',
                height: '0.1px',
                opacity: '0',
                overflow: 'hidden',
                position: 'absolute',
                zIndex: '-1'
              }}
              multiple
            />
          </div>
        </div>

        <div className="mt-4">
          <button
            className="px-6 py-2.5 rounded-full border bg-gray-800 border-gray-800 text-white cursor-pointer"
            onClick={upload}
          >
            Get Url
          </button>
        </div>

        {
          !!images.length && (
            <>
              <div className="mt-4">
                List:
              </div>
              <ol>
                {
                  images.map(image => (
                    <li
                      className="list-disc"
                      key={image.url}
                    >
                      {image.url}
                    </li>
                  ))
                }
              </ol>

              <div className="mt-2">
                Combined
              </div>
              <div>
                {images.map(image => image.url).join(',')}
              </div>
            </>
          )
        }
      </Header>
    </>
  )
}

export default ImageUploaderPage