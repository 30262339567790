import React, { useEffect, useState } from 'react'
import { userGrantsSelector } from '@/store/slices/user'
import { useDispatch, useSelector } from 'react-redux'
import { updateRole, getRoleDetail, deleteRole } from '@/store/slices/role'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import config from '@/constant/config'
import toast from 'react-hot-toast'
import { toCapitalCase } from '../utils/common'

import Header from '@/components/Header'
import Modal from '@/components/Modal'
import FullPageLoader from '@/components/FullPageLoader'

const { resource } = config.role
const { path } = config

const actions = [
  'read',
  'create',
  'update',
  'delete'
]

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  grants: yup.array().of(yup.string()).min(1)
})

const EditRolePage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const grants = useSelector(userGrantsSelector)

  const { roleId } = useParams()

  const [isLoading, setIsLoading] = useState()
  const [roleDetail, setRoleDetail] = useState({})
  const [visibleDeleteRoleModal, setVisibleDeleteRoleModal] = useState(false)

  const fetchRoleDetail = async () => {
    try {
      setIsLoading(true)

      const { data } = await dispatch(getRoleDetail(roleId)).unwrap()
      setRoleDetail(data)

      setIsLoading(false)
    } catch(err) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
      navigate(path.accessibility.role)
    }
  }

  useEffect(() => {
    fetchRoleDetail()
  }, [])

  useEffect(() => {
    const { grants = [] } = roleDetail
    reset({
      name: roleDetail.name,
      grants: grants.flatMap(grant => grant.actions.map(action => `${grant.resource}:${action}`))
    })
  }, [roleDetail])

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const hasDeleteRoleAccess = () => grants.role?.includes('delete')

  const doUpdateRole = async data => {
    try {
      setIsLoading(true)

      const grantsObject = data.grants.reduce((acc, grant) => {
        const [resource, action] = grant.split(':')
        return {
          ...acc,
          [resource]: {
            resource,
            actions: acc[resource] ? [...acc[resource].actions, action] : [action]
          }
        }
      }, {})

      const requestBody = {
        grants: Object.values(grantsObject)
      }
      await dispatch(updateRole({
        name: roleId,
        body: requestBody
      })).unwrap()

      setIsLoading(false)
      navigate(path.accessibility.role)
      toast.success('Success updating role')
    } catch(err) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const doDeleteRole = async () => {
    try {
      setIsLoading(true)

      await dispatch(deleteRole(roleDetail.name)).unwrap()

      setIsLoading(false)
      navigate(path.accessibility.role)
      toast.success('Success deleting role')
    } catch(err) {
      setIsLoading(false)
      setVisibleDeleteRoleModal(false)

      if (err.data.error === 'ROLE_IN_USE') {
        toast.error(`User with role ${roleDetail.name} is exist.\n Either delete user or change the role.`)
        return
      }
      toast.error('Oops something wrong, please try again')
    }
  }

  return (
    <>
      {isLoading && <FullPageLoader />}

      {
        visibleDeleteRoleModal && (
          <Modal visibleState={[visibleDeleteRoleModal, setVisibleDeleteRoleModal]}>
            <div className="flex flex-col">
              <div className="font-bold text-xl mb-2">
                Delete Role
              </div>

              <p>
                Are you sure to delete role {roleDetail.name}?
              </p>

              <div className="self-end mt-4">
                <button
                  className="px-6 py-2 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-3"
                  onClick={() => setVisibleDeleteRoleModal(false)}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="px-6 py-2 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
                  type="button"
                  onClick={doDeleteRole}
                >
                  Delete
                </button>
              </div>
            </div>
          </Modal>
        )
      }

      <Header title="Edit Role">
        <form
          onSubmit={handleSubmit(doUpdateRole)}
          className="flex flex-col"
        >
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-1">
              <div className="font-semibold mb-1 text-sm">
                Name
              </div>
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded-lg"
                disabled
                {...register('name')}
              />
            </div>

            <div className="col-span-3">
              <div className="font-semibold">
                Grants
              </div>

              {
                errors.grants && (
                  <small className="text-red-500">
                    Select at least 1 grant
                  </small>
                )
              }
            </div>

            {
              Object.values(resource).map(r => (
                <div
                  className="col-span-1 border border-gray-300 p-3 rounded"
                  key={r}
                >
                  <div className="font-semibold text-sm mb-2">
                    {toCapitalCase(r)}
                  </div>

                  <div>
                    {
                      actions.map((action, i) => (
                        <div
                          className="my-1"
                          key={i}
                        >
                          <div className="flex items-center">
                            <input
                              id={`${r}:${action}`}
                              type="checkbox"
                              value={`${r}:${action}`}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                              {...register('grants')}
                              disabled={
                                `${r}:${action}` === 'order:create' ||
                                `${r}:${action}` === 'order:delete' ||
                                `${r}:${action}` === 'homepage_product:create' ||
                                `${r}:${action}` === 'homepage_product:delete' ||
                                `${r}:${action}` === 'newsletter:update'
                              }
                            />
                            <label
                              htmlFor={`${r}:${action}`}
                              className="ml-2 text-sm"
                            >
                              {toCapitalCase(action)}
                            </label>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))
            }
          </div>

          <div className="flex mt-5">
            <button
              type="button"
              className="mr-2 py-2 px-10 border border-gray-700 bg-white text-black rounded-full"
              onClick={() => navigate(path.accessibility.role)}
            >
              Back
            </button>

            <div className="ml-auto" />

            {
              hasDeleteRoleAccess() && (
                <button
                  className="ml-auto px-6 py-2.5 rounded-full border border-red-500 text-red-500 cursor-pointer mr-3"
                  onClick={() => setVisibleDeleteRoleModal(true)}
                  type="button"
                >
                  Delete
                </button>
              )
            }

            <input
              type="submit"
              value="Update"
              className="py-2 px-10 border border-gray-700 bg-gray-700 text-white rounded-full cursor-pointer"
            />
          </div>
        </form>
      </Header>
    </>
  )
}

export default EditRolePage