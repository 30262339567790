import { DateTime } from 'luxon'

export const getRandomString = (length = 10) => {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let result = ''

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length)
    result += chars[randomIndex]
  }

  return result
}

export const toResponse = response => ({
  status: response.status,
  data: response.data
})

export const numberFormatter = (number, currency = '') => {
  if (!Number.isInteger(number)) {
    return undefined
  }
  return currency + number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
}

const whitelistKey = [
  84, // tab
  66, // backspace
  68, // delete
  65 // arrow
]

export const numberInput = e => {
  const charCode = e.key.charCodeAt(0)

  const isCharacter = charCode > 31
  const isNonNumber = charCode < 48 || charCode > 57
  const isWhitelisted = whitelistKey.includes(charCode)

  if (isWhitelisted) {
    return true
  }

  if (isCharacter && isNonNumber) {
    e.preventDefault()
    return
  }
  return true
}

export const formatDate = (date, monthYear = false) => {
  if (!date) return
  const options = {
    year: 'numeric',
    month: '2-digit'
  }
  if (!monthYear) {
    options.day = '2-digit'
  }
  return date.toLocaleDateString('en-GB', options)
}

export const toFormattedDate = (millis, format = 'dd MMMM yyyy, HH:mm') => {
  return DateTime.fromMillis(millis)
    .toFormat(format)
}

export const toAsiaJakartaZone = date => {
  return DateTime.fromMillis(date)
    .setZone('Asia/Jakarta')
    .toMillis()
}

export const toCapitalCase = sentence => {
  if (!sentence) {
    return null
  }
  const lowered = sentence.toLowerCase()
  const words = lowered.split(' ')
  return words.map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}