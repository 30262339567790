import React, { useEffect, useState, useCallback } from 'react'
import {
  getProducts,
  getBulkUploadTemplate,
  bulkUpload,
  getBulkUploadStatus,
  setSelectedPage,
  setSelectedAvailability
} from '@/store/slices/product'
import { userGrantsSelector } from '@/store/slices/user'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import debounce from '@/utils/debouncer'
import toast from 'react-hot-toast'
import config from '@/constant/config'
import className from 'classnames'
import { Buffer } from 'buffer'
import useDidUpdateEffect from '@/hooks/did-update-effect'

import Pagination from '@/components/Pagination'
import Header from '@/components/Header'
import Table from '@/components/Table'
import Modal from '@/components/Modal'
import FullPageLoader from '@/components/FullPageLoader'
import BulkProductStatus from '@/components/BulkProductStatus'
import LineLoader from '@/components/LineLoader'
import CategoryDropdown from '@/components/CategoryDropdown'
import BrandDropdown from '@/components/BrandDropdown'
import CharacterDropdown from '@/components/CharacterDropdown'
import SeriesDropdown from '@/components/SeriesDropdown'

const { path, wording, product } = config

const SPREADSHEET_TYPE = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']

const TABLE_HEADERS = [
  {
    text: 'SKU',
    value: 'sku'
  },
  {
    text: 'Images',
    value: 'images',
    slot: item => item.images && item.images[0] ? (
      <img
        className="w-32 h-32 object-contain"
        src={item.images[0]}
        alt="Product image"
      />
    ) : (
      <div />
    )
  },
  {
    text: 'Name',
    value: 'name'
  },
  {
    text: 'Original Price',
    value: 'price.original',
    currency: 'Rp.'
  },
  {
    text: 'Offer Price',
    value: 'price.offer',
    currency: 'Rp.'
  },
  {
    text: 'Stock',
    value: 'stock',
    number: true
  },
  {
    text: 'Status',
    value: 'status',
    slot: item => (
      <div className={className({
        'p-1 text-center rounded-full text-white': true,
        'bg-green-500': item.status === 'PUBLISHED',
        'bg-blue-500': item.status === 'DRAFT'
      })}>
        {wording.product.status[item.status]}
      </div>
    )
  },
  {
    text: 'Availability',
    value: 'availability',
    slot: item => (
      <div className={className({
        'p-1 text-center rounded-full text-white whitespace-nowrap': true,
        'bg-green-500': item.availability === 'READY_STOCK',
        'bg-blue-500': item.availability === 'PREORDER' || item.availability === 'LATE_PREORDER',
        'bg-gray-500': item.availability === 'WAITING_LIST'
      })}>
        {wording.product.availability[item.availability]}
      </div>
    )
  }
]

const PRODUCT_FILTERS = product.listPageFilter

const ProductPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const grants = useSelector(userGrantsSelector)
  const lastSelectedPage = useSelector(state => state.product.listingSelectedPage)
  const lastSelectedAvailability = useSelector(state => state.product.lisitingSelectedAvailability)

  const [paging, setPaging] = useState({})
  const [search, setSearch] = useState('')
  const [category, setCategory] = useState('')
  const [brand, setBrand] = useState('')
  const [character, setCharacter] = useState('')
  const [series, setSeries] = useState('')
  const [products, setProducts] = useState([])
  const [isFetchingProduct, setIsFetchingProduct] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(lastSelectedAvailability)
  const [visibleBulkUploadModal, setVisibleBulkUploadModal] = useState(false)
  const [file, setFile] = useState()
  const [isBulkUploading, setIsBulkUploading] = useState(false)
  const [bulkUploadProcessId, setBulkUploadProcessId] = useState()
  const [bulkUploadStatus, setBulkUploadStatus] = useState()
  const [isBulkUploadInProgress, setIsBulkUploadInProgress] = useState(false)

  useEffect(() => {
    if (!bulkUploadProcessId) {
      return
    }

    let interval

    const getProcessStatus = async () => {
      const { data } = await dispatch(getBulkUploadStatus(bulkUploadProcessId)).unwrap()

      if (data.status === 'SUCCESS' || data.status === 'FAILED') {
        clearInterval(interval)
        setBulkUploadStatus(data)
        fetchProducts(1)
        setIsBulkUploadInProgress(false)
      }
    }
    getProcessStatus()

    interval = setInterval(getProcessStatus, 3000)

    return () => interval && clearInterval(interval)
  }, [bulkUploadProcessId])

  useEffect(() => {
    fetchProducts(lastSelectedPage, res => {
      setProducts(res.data)
      setPaging({
        page: lastSelectedPage,
        totalPage: res.paging.totalPage
      })
    })
  }, [])

  useDidUpdateEffect(() => {
    fetchProducts(1)
  }, [search, selectedFilter, category, brand, series, character])

  useEffect(() => {
    dispatch(setSelectedAvailability(selectedFilter))
    dispatch(setSelectedPage(1))
  }, [selectedFilter])

  const hasCreateProductAccess = () => grants.product?.includes('create')

  const hasUpdateProductAccess = () => grants.product?.includes('update')

  const defaultFetchProductCb = useCallback(res => {
    setProducts(res.data)
    setPaging({
      page: res.paging.page,
      totalPage: res.paging.totalPage
    })
  }, [])

  const fetchProducts = async (page, cb = defaultFetchProductCb) => {
    try {
      setIsFetchingProduct(true)

      const params = {
        page,
        search,
        category,
        brand,
        character,
        series,
        ...(selectedFilter.value !== 'ALL' ? { [selectedFilter.field]: selectedFilter.value } : {})
      }

      const productResponse = await dispatch(getProducts(params)).unwrap()
      cb(productResponse)

      setIsFetchingProduct(false)
    } catch(err) {
      toast.error('Oops something wrong, please try again')
      setIsFetchingProduct(false)
    }
  }

  const handleSearch = e => {
    debounce(() => setSearch(e.target.value), 300, 'search')
  }

  const generateBulkUploadTemplate = async () => {
    const { value, contentType } = await dispatch(getBulkUploadTemplate()).unwrap()

    const buff = Buffer.from(value.data)
    const dataUrl = new Blob([buff], { type: contentType })
    const url = URL.createObjectURL(dataUrl)

    const link = document.createElement('a')
    link.href = url
    link.download = 'product-template.xlsx'
    link.click();
  }

  const doBulkUpload = async e => {
    e.preventDefault()

    if (!file || !SPREADSHEET_TYPE.includes(file.type)) {
      toast.error('Please upload excel file')
      return
    }

    setIsBulkUploading(true)

    try {
      const form = new FormData()
      form.append('file', file)
      const { data } = await dispatch(bulkUpload(form)).unwrap()

      setBulkUploadProcessId(data)
      setIsBulkUploading(false)
      setVisibleBulkUploadModal(false)
      setIsBulkUploadInProgress(true)

      toast.success(`Uploading . . .`)
    } catch(err) {
      setIsBulkUploading(false)

      if (err.data.error === 'ONGOING_BULK_UPLOAD') {
        toast.error('There\'s processed bulk upload right now, please try again later')
        return;
      }
      toast.error('Oops something wrong, please try again')
    }
  }

  return (
    <>
      {
        visibleBulkUploadModal && (
          <Modal visibleState={[visibleBulkUploadModal, setVisibleBulkUploadModal]}>
            <form
              className="flex flex-col"
              onSubmit={doBulkUpload}
            >
              <div className="text-xl font-bold mb-2">
                Bulk Upload
              </div>
              <div className="mx-6">
                <ol className="list-decimal">
                  <li>
                    <p>
                      Download the template below and follow the guide.
                    </p>
                    <button
                      type="button"
                      className="text-blue-300"
                      onClick={generateBulkUploadTemplate}
                    >
                      Product template.xlsx
                    </button>
                  </li>
                  <li className="mt-2">
                    <p>
                      Upload your excel file here.
                    </p>
                    <input
                      className="mt-2"
                      type="file"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={e => setFile(e.target.files[0])}
                    />
                  </li>
                </ol>
              </div>
              <div className="self-end mt-4">
                <button
                  type="button"
                  onClick={() => setVisibleBulkUploadModal(false)}
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-2"
                >
                  Cancel
                </button>
                <input
                  type="submit"
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
                  value="Upload"
                />
              </div>
            </form>
          </Modal>
        )
      }

      <Header
        title="Product"
        slot={() => hasCreateProductAccess() && (
          <div className="ml-auto">
            <button
              className="mr-2 py-2 px-10 border border-gray-700 bg-gray-700 text-white rounded-full"
              onClick={() => navigate(path.productCreate)}
            >
              Create
            </button>
            <button
              className="py-2 px-10 border border-gray-700 bg-white rounded-full"
              onClick={() => setVisibleBulkUploadModal(true)}
            >
              Upload
            </button>
          </div>
        )}
      >
        <div className="flex items-center">
          <div className="flex">
            {
              PRODUCT_FILTERS.map(filter => (
                <button
                  key={filter.value}
                  className={className({
                    'py-4 px-6 font-semibold cursor-pointer': true,
                    'text-blue-600': filter.value === selectedFilter.value
                  })}
                  onClick={() => setSelectedFilter(filter)}
                >
                  {filter.text}
                </button>
              ))
            }
          </div>
        </div>

        <hr className="my-2" />

        {
          isBulkUploadInProgress && (
            <div className="my-2">
              <div className="mb-1.5 text-blue-500">
                Bulk upload in progress . . .
              </div>
              <LineLoader />
            </div>
          )
        }
        {
          bulkUploadStatus && !isBulkUploadInProgress && (
            <BulkProductStatus status={bulkUploadStatus} />
          )
        }

        <input
          type="text"
          className=" p-2 w-1/4 border border-gray-300 rounded-lg my-4"
          placeholder="Search sku or name"
          onChange={handleSearch}
        />

        <div className=" flex items-center mb-4 gap-x-3">
          <div className="w-1/4">
            <CategoryDropdown
              useForm={false}
              onItemChange={item => setCategory(item.key)}
              disposable
              disabled={isFetchingProduct}
            />
          </div>
          <div className="w-1/4">
            <BrandDropdown
              useForm={false}
              onItemChange={item => setBrand(item.key)}
              disposable
              disabled={isFetchingProduct}
            />
          </div>
          <div className="w-1/4">
            <CharacterDropdown
              useForm={false}
              onItemChange={item => setCharacter(item.key)}
              disposable
              disabled={isFetchingProduct}
            />
          </div>
          <div className="w-1/4">
            <SeriesDropdown
              useForm={false}
              onItemChange={item => setSeries(item.key)}
              disposable
              disabled={isFetchingProduct}
            />
          </div>
        </div>

        <Table
          headers={TABLE_HEADERS}
          items={products}
          isLoading={isFetchingProduct}
          {...hasUpdateProductAccess() && { onRowClick: item => navigate(path.productEdit.replace(':sku', item.sku)) }}
        />

        {
          !isFetchingProduct && !!products.length && (
            <div className="self-end mt-3">
              <Pagination
                currentPage={paging.page}
                totalPage={paging.totalPage}
                setPage={page => {
                  fetchProducts(page)
                  dispatch(setSelectedPage(page))
                }}
              />
            </div>
          )
        }
      </Header>

      {isBulkUploading && <FullPageLoader />}
    </>
  )
}

export default ProductPage