import React, { useEffect, useState } from 'react'
import { userGrantsSelector } from '@/store/slices/user'
import { useDispatch, useSelector } from 'react-redux'
import { getRoles } from '@/store/slices/role'
import { updateUser, getUserDetail, deleteUser } from '@/store/slices/user'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import config from '@/constant/config'
import classNames from 'classnames'
import toast from 'react-hot-toast'

import Header from '@/components/Header'
import Modal from '@/components/Modal'
import Dropdown from '@/components/Dropdown'
import FullPageLoader from '@/components/FullPageLoader'

const { path } = config

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  name: yup.string().required(),
  role: yup.string().required(),
  resetPassword: yup.string().optional()
})

const EditUserAdminPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const grants = useSelector(userGrantsSelector)

  const { userId } = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [roles, setRoles] = useState([])
  const [userDetail, setUserDetail] = useState()
  const [visibleDeleteUserModal, setVisibleDeleteUserModal] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    reset,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const role = useWatch({
    control,
    name: 'role'
  })

  const fetchRoles = async () => {
    try {
      setIsLoading(true)

      const { data: roleResponse } = await dispatch(getRoles()).unwrap()
      setRoles(roleResponse)

      setIsLoading(false)
    } catch(err) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const fetchUserDetail = async () => {
    try {
      setIsLoading(true)

      const { data: userDetail } = await dispatch(getUserDetail(window.atob(userId))).unwrap()
      setUserDetail(userDetail)

      setIsLoading(false)
    } catch(err) {
      setIsLoading(false)

      if (err.data.error === 'USER_NOT_EXIST') {
        toast.error('User not exist')
        navigate(path.accessibility.user)
        return
      }
      toast.error('Oops something wrong, please try again')
    }
  }

  useEffect(() => {
    fetchRoles()
    fetchUserDetail()
    register('role')
  }, [])

  useEffect(() => {
    if (!userDetail) {
      return
    }
    reset({
      email: userDetail.email,
      name: userDetail.name,
      role: userDetail.role
    })
  }, [userDetail])

  const rolesDropdownItem = roles.map(r => ({ key: r.name, value: r.name }))

  const hasDeleteUserAccess = () => grants.user?.includes('delete')

  const roleChangeHandler = selectedRole => {
    setValue('role', selectedRole.key)
    trigger('role')
  }

  const doUpdateUser = async data => {
    try {
      setIsLoading(true)

      const requestBody = {
        name: data.name,
        role: data.role,
        resetPassword: data.resetPassword
      }
      await dispatch(updateUser({
        email: data.email,
        body: requestBody
      })).unwrap()

      setIsLoading(false)
      toast.success('Success updating user')
      navigate(path.accessibility.user)
    } catch(err) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const doDeleteUser = async () => {
    try {
      setIsLoading(true)

      await dispatch(deleteUser(userDetail.email)).unwrap()

      setIsLoading(false)
      toast.success('Success deleting user')
      navigate(path.accessibility.user)
    } catch(err) {
      setIsLoading(false)
      setVisibleDeleteUserModal(false)

      if (err.data.error === 'LAST_SUPER_ADMIN') {
        toast.error('Can\'t delete last super admin user')
        return
      }
      toast.error('Oops something wrong, please try again')
    }
  }

  return (
    <>
      {isLoading && <FullPageLoader />}

      {
        visibleDeleteUserModal && (
          <Modal visibleState={[visibleDeleteUserModal, setVisibleDeleteUserModal]}>
            <div className="flex flex-col">
              <div className="font-bold text-xl mb-2">
                Delete User
              </div>

              <p>
                Are you sure to delete user {userDetail.email}?
              </p>

              <div className="self-end mt-4">
                <button
                  className="px-6 py-2 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-3"
                  onClick={() => setVisibleDeleteUserModal(false)}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="px-6 py-2 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
                  type="button"
                  onClick={doDeleteUser}
                >
                  Delete
                </button>
              </div>
            </div>
          </Modal>
        )
      }

      <Header title="Update User">
        <form
          onSubmit={handleSubmit(doUpdateUser)}
          className="flex flex-col"
        >
          <div className="grid grid-cols-2 gap-4 w-2/3">
            <div className="col-span-1">
              <div className="font-semibold mb-1 text-sm">
                Email
              </div>
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded-lg"
                disabled
                {...register('email')}
              />
            </div>

            <div className="col-span-1">
              <div className="font-semibold mb-1 text-sm">
                Name
              </div>
              <input
                type="text"
                className={classNames({
                  'w-full p-2 border border-gray-300 rounded-lg': true,
                  'border-red-500': !!errors.name
                })}
                {...register('name')}
              />
              {
                errors.name && (
                  <small className="ml-2 text-red-500">
                    Name must not empty
                  </small>
                )
              }
            </div>

            <div className="col-span-1">
              <div className="font-semibold mb-1 text-sm flex">
                <div>
                  Reset Password
                </div>
                <small className="text-gray-400 ml-1">(Optional)</small>
              </div>
              <input
                type="password"
                className="w-full p-2 border border-gray-300 rounded-lg"
                {...register('resetPassword')}
              />
            </div>

            <div className="col-span-1">
              <div className="font-semibold mb-1 text-sm">
                Role
              </div>
              <Dropdown
                name="Select role"
                items={rolesDropdownItem}
                onItemChange={roleChangeHandler}
                error={!!errors.role}
                value={rolesDropdownItem.find(r => r.key === role)}
              />
              {
                !!errors.role && (
                  <small className="ml-2 text-red-500">
                    Select role
                  </small>
                )
              }
            </div>
          </div>

          <div className="flex mt-5">
            <button
              type="button"
              className="mr-2 py-2 px-10 border border-gray-700 bg-white text-black rounded-full"
              onClick={() => navigate(path.accessibility.user)}
            >
              Back
            </button>

            <div className="ml-auto" />

            {
              hasDeleteUserAccess() && (
                <button
                  className="px-6 py-2.5 rounded-full border border-red-500 text-red-500 cursor-pointer mr-3"
                  type="button"
                  onClick={() => setVisibleDeleteUserModal(true)}
                >
                  Delete
                </button>
              )
            }

            <input
              type="submit"
              value="Update"
              className="py-2 px-10 border border-gray-700 bg-gray-700 text-white rounded-full cursor-pointer"
            />
          </div>
        </form>
      </Header>
    </>
  )
}

export default EditUserAdminPage