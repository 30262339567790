import React, { useState } from 'react'
import Modal from '@/components/Modal'
import classNames from 'classnames'

const TABS = [
  {
    text: 'Success',
    value: 'SUCCESS'
  },
  {
    text: 'Failed',
    value: 'FAILED'
  }
]

const BulkProductStatus = ({ status }) => {
  const [visibleModal, setVisibleModal] = useState(false)
  const [selectedTab, setSelectedTab] = useState(TABS[0])

  return (
    <>
      <Modal visibleState={[visibleModal, setVisibleModal]}>
        <div className="flex">
          {
            TABS.map(tab => (
              <button
                key={tab.value}
                className={classNames({
                  'w-1/2 text-center font-semibold': true,
                  'text-blue-600': tab.value === selectedTab.value
                })}
                type="button"
                onClick={() => setSelectedTab(tab)}
              >
                {tab.text}
              </button>
            ))
          }
        </div>

        <hr className="my-4" />

        {
          selectedTab.value === 'SUCCESS' && (
            <div>
              <div>
                Total inserted sku: <strong>{status.successfulSku.length}</strong>
              </div>

              <div className="font-semibold">
                List:
              </div>

              <div className="h-96 overflow-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
                {
                  status?.successfulSku?.map(sku => (
                    <div
                      className="mb-1 p-2 border-b border-gray-200"
                      key={sku}
                    >
                      {sku}
                    </div>
                  ))
                }
              </div>
            </div>
          )
        }

        {
          selectedTab.value === 'FAILED' && (
            <div>
              <div>
                Total failed sku: <strong>{status.failedSku.length}</strong>
              </div>

              <div className="font-semibold">
                List:
              </div>

              <div className="h-96 overflow-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
                <div className="mb-1 p-2 border-b border-gray-200 flex font-semibold">
                  <div className="w-1/4">
                    Row
                  </div>
                  <div className="w-1/4">
                    SKU
                  </div>
                  <div className="w-1/2">
                    Reason
                  </div>
                </div>
                {
                  status?.failedSku?.map(({ sku, reason, row }) => (
                    <div
                      className="mb-1 p-2 border-b border-gray-200 flex"
                      key={sku}
                    >
                      <div className="w-1/4">
                        {row}
                      </div>
                      <div className="w-1/4">
                        {sku}
                      </div>
                      <div className="w-1/2">
                        {reason}
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          )
        }
      </Modal>

      <div>
        <button
          type="button"
          className="text-blue-400 font-semibold cursor-pointer"
          onClick={() => setVisibleModal(true)}
        >
          Bulk upload result
        </button>
      </div>
    </>
  )
}

export default BulkProductStatus