import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api, product } = config

export const getProducts = createAsyncThunk(
  'product/getProducts',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.product, {
        params
      })
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const getProductsBySkus = createAsyncThunk(
  'product/getProductsBySkus',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.productSkus, {
        params
      })
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const getProductDetail = createAsyncThunk(
  'product/getProductDetail',
  async (sku, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.productDetail(sku))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const saveProduct = createAsyncThunk(
  'product/saveProduct',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.product, body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const updateProduct = createAsyncThunk(
  'product/updateProduct',
  async ({ sku, body }, { rejectWithValue }) => {
    try {
      const response = await axios.put(api.productDetail(sku), body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const deleteProduct = createAsyncThunk(
  'product/deleteProduct',
  async (sku, { rejectWithValue }) => {
    try {
      const response = await axios.delete(api.productDetail(sku))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const hideProduct = createAsyncThunk(
  'product/hideProduct',
  async (sku, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.hideProduct(sku))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const unhideProduct = createAsyncThunk(
  'product/unhideProduct',
  async (sku, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.unhideProduct(sku))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const getBulkUploadTemplate = createAsyncThunk(
  'product/getBulkUploadTemplate',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.bulkUploadTemplate)
      return {
        value: response.data.data.value,
        contentType: response.headers['content-type']
      }
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const getBulkUploadStatus = createAsyncThunk(
  'product/getBulkUploadStatus',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.bulkUploadStatus(id))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const bulkUpload = createAsyncThunk(
  'product/bulkUpload',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.bulkUpload, formData ,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    listingSelectedPage: 1,
    lisitingSelectedAvailability: product.listPageFilter[0]
  },
  reducers: {
    setSelectedPage(state, actions) {
      state.listingSelectedPage = actions.payload
    },
    setSelectedAvailability(state, actions) {
      state.lisitingSelectedAvailability = actions.payload
    }
  }
})

export const { setSelectedPage, setSelectedAvailability } = productSlice.actions

export default productSlice.reducer