import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getRoles } from '@/store/slices/role'
import { saveUser } from '@/store/slices/user'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import config from '@/constant/config'
import classNames from 'classnames'
import toast from 'react-hot-toast'

import Header from '@/components/Header'
import Dropdown from '@/components/Dropdown'
import FullPageLoader from '@/components/FullPageLoader'

const { path } = config

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  name: yup.string().required(),
  password: yup.string().required(),
  confirmPassword: yup.string()
    .test('validPassword', 'Password is different', function(confirmPassword) {
      return confirmPassword === this.parent.password
    })
    .required('Confirm password must not empty'),
  role: yup.string().required()
})

const CreateUserPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [roles, setRoles] = useState([])

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const fetchRoles = async () => {
    setIsLoading(true)
    const { data } = await dispatch(getRoles()).unwrap()
    setRoles(data)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchRoles()
    register('role')
  }, [])

  const rolesDropdownItem = roles.map(r => ({ key: r.name, value: r.name }))

  const roleChangeHandler = selectedRole => {
    setValue('role', selectedRole.key)
    trigger('role')
  }

  const createUser = async data => {
    try {
      setIsLoading(true)

      const requestBody = {
        email: data.email,
        name: data.name,
        password: data.password,
        role: data.role
      }
      await dispatch(saveUser(requestBody)).unwrap()

      setIsLoading(false)
      toast.success('Success creating user')
      navigate(path.accessibility.user)
    } catch(err) {
      setIsLoading(false)
      if (err.data.error === 'USER_ALREADY_EXIST') {
        toast.error(`User with email ${data.email} already exist`)
        return
      }
      toast.error('Oops something wrong, please try again')
    }
  }

  return (
    <>
      {isLoading && <FullPageLoader />}
      <Header title="Create User">
        <form
          onSubmit={handleSubmit(createUser)}
          className="flex flex-col"
        >
          <div className="grid grid-cols-2 gap-4 w-2/3">
            <div className="col-span-1">
              <div className="font-semibold mb-1 text-sm">
                Email
              </div>
              <input
                type="text"
                className={classNames({
                  'w-full p-2 border border-gray-300 rounded-lg': true,
                  'border-red-500': !!errors.email
                })}
                {...register('email')}
              />
              {
                errors.email && (
                  <small className="ml-2 text-red-500">
                    Email must valid
                  </small>
                )
              }
            </div>

            <div className="col-span-1">
              <div className="font-semibold mb-1 text-sm">
                Name
              </div>
              <input
                type="text"
                className={classNames({
                  'w-full p-2 border border-gray-300 rounded-lg': true,
                  'border-red-500': !!errors.name
                })}
                {...register('name')}
              />
              {
                errors.name && (
                  <small className="ml-2 text-red-500">
                    Name must not empty
                  </small>
                )
              }
            </div>

            <div className="col-span-1">
              <div className="font-semibold mb-1 text-sm">
                Password
              </div>
              <input
                type="password"
                className={classNames({
                  'w-full p-2 border border-gray-300 rounded-lg': true,
                  'border-red-500': !!errors.password
                })}
                {...register('password')}
              />
              {
                errors.password && (
                  <small className="ml-2 text-red-500">
                    Password must not empty
                  </small>
                )
              }
            </div>

            <div className="col-span-1">
              <div className="font-semibold mb-1 text-sm">
                Confirm Password
              </div>
              <input
                type="password"
                className={classNames({
                  'w-full p-2 border border-gray-300 rounded-lg': true,
                  'border-red-500': !!errors.confirmPassword
                })}
                {...register('confirmPassword')}
              />
              {
                errors.confirmPassword && (
                  <small className="ml-2 text-red-500">
                    {errors.confirmPassword.message}
                  </small>
                )
              }
            </div>

            <div className="col-span-1">
              <div className="font-semibold mb-1 text-sm">
                Role
              </div>
              <Dropdown
                name="Select role"
                items={rolesDropdownItem}
                onItemChange={roleChangeHandler}
                error={!!errors.role}
              />
              {
                !!errors.role && (
                  <small className="ml-2 text-red-500">
                    Select role
                  </small>
                )
              }
            </div>
          </div>

          <div className="flex ml-auto mt-5">
            <button
              type="button"
              className="mr-2 py-2 px-10 border border-gray-700 bg-white text-black rounded-full"
              onClick={() => navigate(path.accessibility.user)}
            >
              Back
            </button>
            <input
              type="submit"
              value="Create"
              className="py-2 px-10 border border-gray-700 bg-gray-700 text-white rounded-full cursor-pointer"
            />
          </div>
        </form>
      </Header>
    </>
  )
}

export default CreateUserPage