import React, { useEffect, useState } from 'react'
import config from '@/constant/config'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateCustomLayout, getCustomLayoutDetail } from '@/store/slices/custom-layout'
import { getProductsBySkus } from '@/store/slices/product'
import { deleteImage } from '@/store/slices/image'
import toast from 'react-hot-toast'
import { ReactSortable } from 'react-sortablejs'
import { getRandomString } from '@/utils/common'

import Header from '@/components/Header'
import FullPageLoader from '@/components/FullPageLoader'
import ComponentDrawer from '@/components/CustomLayout/ComponentDrawer'
import BannerItem from '@/components/CustomLayout/BannerItem'
import CarouselItem from '@/components/CustomLayout/CarouselItem'
import ProductSetItem from '@/components/CustomLayout/ProductSetItem'
import TextItem from '@/components/CustomLayout/TextItem'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

const { path } = config

const EditCustomLayoutPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const [components, setComponents] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [title, setTitle] = useState('')
  const [slug, setSlug] = useState('')

  useEffect(() => {
    fetchCustomLayout()
  }, [])

  const modifyComponents = async componentResponses => {
    const components = []

    for (let component of componentResponses) {
      component.id = getRandomString()

      if (component.type === 'PRODUCT_SET') {
        const productDetails = await fetchProductDetails(component.products)
        component.products = productDetails
      }

      if (component.type === 'BANNER' || component.type === 'CAROUSEL') {
        component.images = component.images.map(img => ({
          ...img,
          preview: img.url
        }))
      }

      components.push(component)
    }

    return components
  }

  const fetchCustomLayout = async () => {
    try {
      setIsLoading(true)

      const { data } = await dispatch(getCustomLayoutDetail(id)).unwrap()
      setTitle(data.title)
      setSlug(data.slug)

      const components = await modifyComponents(data.components)

      setComponents(components)

      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const fetchProductDetails = async skus => {
    const { data } = await dispatch(getProductsBySkus({ sku: skus })).unwrap()
    return data
  }

  const getComponentsReqBody = () => {
    return components.map(comp => {
      if (comp.type === 'BANNER') {
        return {
          type: comp.type,
          images: comp.images,
          orientation: comp.orientation
        }
      }

      if (comp.type === 'CAROUSEL') {
        return {
          type: comp.type,
          images: comp.images,
        }
      }

      if (comp.type === 'TEXT') {
        return {
          type: comp.type,
          text: comp.text,
        }
      }

      if (comp.type === 'PRODUCT_SET') {
        return {
          type: comp.type,
          products: comp.products.map(p => p.sku),
        }
      }

      return comp
    })
  }

  const editCustomLayout = async () => {
    if (!title || !slug || !components.length) {
      toast.error('You must fill title, slug, and at least 1 component')
      return
    }

    try {
      setIsLoading(true)

      await dispatch(updateCustomLayout({
        id,
        body: {
          title,
          slug,
          components: getComponentsReqBody()
        }
      })).unwrap()

      setIsLoading(false)

      toast.success('Custom layout updated')

      navigate(path.content.customLayout)
    } catch(error) {
      setIsLoading(false)

      if (error.data?.error === 'SLUG_ALREADY_USED') {
        toast.error('Slug already used')
        return
      }

      toast.error('Oops something wrong, please try again')
    }
  }

  const addComponent = comp => {
    setComponents([
      ...components,
      {
        id: getRandomString(),
        ...comp
      }
    ])
  }

  const updateComponent = (comp, index) => {
    const updatedComps = [...components]
    const f = updatedComps.findIndex((_, i) => index === i)

    if (f === -1) {
      return
    }

    updatedComps[f] = comp
    updatedComps[f].id = getRandomString()
    setComponents(updatedComps)
  }

  const removeComponent = async (comp, i) => {
    try {
      setIsLoading(true)

      if (comp.type === 'BANNER' || comp.type === 'CAROUSEL') {
        const filenames = comp.images.map(img => img.filename)
        await dispatch(deleteImage(filenames)).unwrap()
      }
      setComponents(components.filter((_, idx) => idx !== i))

      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const renderComp = (comp, i) => {
    if (comp.type === 'BANNER') {
      return (
        <BannerItem
          component={comp}
          index={i}
          onRemoveComponent={removeComponent}
          onUpdateComponent={updateComponent}
        />
      )
    }

    if (comp.type === 'CAROUSEL') {
      return (
        <CarouselItem
          component={comp}
          index={i}
          onRemoveComponent={removeComponent}
          onUpdateComponent={updateComponent}
        />
      )
    }

    if (comp.type === 'TEXT') {
      return (
        <TextItem
          component={comp}
          index={i}
          onRemoveComponent={removeComponent}
          onUpdateComponent={updateComponent}
        />
      )
    }

    if (comp.type === 'PRODUCT_SET') {
      return (
        <ProductSetItem
          component={comp}
          index={i}
          onRemoveComponent={removeComponent}
          onUpdateComponent={updateComponent}
        />
      )
    }

    return <div />
  }

  return (
    <Header
      title="Update Custom Layout"
      freeWidth
    >
      {isLoading && <FullPageLoader />}

      <section>
        <div className="mb-4 flex gap-x-4 items-center w-5/6">
          <div className="w-1/4">
            <div className="font-semibold">
              Title
            </div>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-lg"
              onChange={e => setTitle(e.target.value)}
              value={title}
            />
          </div>

          <div className="w-1/4">
            <div className="font-semibold">
              Slug
              <small className="ml-1.5 text-gray-400 text-xs">
                *Layout url, ex: https://www.multitoys.id/page/<strong>your-slug-here</strong>
              </small>
            </div>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-lg"
              onChange={e => setSlug(e.target.value)}
              value={slug}
            />
          </div>

          <div className="ml-auto">
            <button
              className="px-6 py-2 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
              onClick={editCustomLayout}
            >
              Update
            </button>
          </div>
        </div>

        <section className="flex gap-x-4 items-start">
          <ComponentDrawer onAddComponent={addComponent} />

          <section className="w-2/3 shadow rounded py-4 px-6">
            <div className="text-3xl font-bold">
              {title ? title : 'Your Title Here'}
            </div>

            <ReactSortable
              list={components}
              setList={setComponents}
              className="mt-4 flex flex-col gap-y-4"
              handle=".cl-drag-handle"
            >
              {components.map((comp, i) => (
                <div key={comp.id}>
                  {renderComp(comp, i)}
                </div>
              ))}
            </ReactSortable>
          </section>
        </section>
      </section>
    </Header>
  )
}

export default EditCustomLayoutPage