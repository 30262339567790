import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getOrderDetail } from '@/store/slices/order'
import { toFormattedDate, toCapitalCase, numberFormatter } from '@/utils/common'
import toast from 'react-hot-toast'
import config from '@/constant/config'

import Header from '@/components/Header'
import FullPageLoader from '@/components/FullPageLoader'
import OrderStatusLabel from '@/components/OrderStatusLabel'
import OrderDetailItem from '@/components/OrderDetailItem'

const { path } = config

const OrderDetailPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { orderId } = useParams()

  const [orderDetail, setOrderDetail] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    fetchOrderDetail()
  }, [])

  const fetchOrderDetail = async () => {
    try {
      setIsLoading(true)

      const { data } = await dispatch(getOrderDetail(orderId)).unwrap()
      setOrderDetail(data)

      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)

      if (error.data?.error === 'ORDER_NOT_FOUND') {
        toast.error('Order not found')
        navigate(path.order.base)
        return
      }
      toast.error('Oops something wrong, please try again')
    }
  }

  const fetchPlainOrderDetail = async () => {
    const { data } = await dispatch(getOrderDetail(orderId)).unwrap()
    setOrderDetail(data)
  }

  const address = orderDetail?.address || {}
  const payment = orderDetail?.payment || []
  const items = orderDetail?.items || []

  const addressText = () => `${toCapitalCase(address.province?.name)}, ${toCapitalCase(address.regency?.name)}, ${toCapitalCase(address.district?.name)}, ${toCapitalCase(address.village?.name)}, ${address.postalCode}`

  const priceSummaries = () => {
    const grouped = orderDetail?.prices.reduce((acc, price) => {
      return {
        ...acc,
        [price.id]: {
          ...price,
          nominal: acc[price.id] ? acc[price.id].nominal + price.nominal : price.nominal,
          id: price.id
        }
      }
    }, {})

    return Object.values(grouped)
  }

  const getDescriptionText = desc => {
    if (desc === 'FIRST_PAYMENT') {
      return 'First Payment'
    }
    if (desc.startsWith('SETTLEMENT')) {
      return 'Settlement'
    }
    return 'Adjustment'
  }

  return isLoading || !orderDetail ? <FullPageLoader /> : (
    <Header title="Order Detail">
      <section className="grid grid-cols-3 gap-x-3 gap-y-6">
        <div className="col-span-1">
          <div className="font-bold">
            Information
          </div>

          <hr className="my-2.5" />

          <div className="flex flex-col items-start">
            <div className="mb-1.5">
              <OrderStatusLabel status={orderDetail.status} />
            </div>
            <div>
              Order Id: {orderDetail.id}
            </div>
            <div>
              Order Date: {toFormattedDate(orderDetail.date)}
            </div>
          </div>
        </div>

        <div className="col-span-1">
          <div className="font-bold">
            User
          </div>

          <hr className="my-2.5" />

          <div className="flex flex-col items-start">
            <div>
              Email: {orderDetail.user?.id}
            </div>
            <div>
              Name: {orderDetail.user?.name}
            </div>
            <div>
              Phone: {orderDetail.user?.phone}
            </div>
          </div>
        </div>

        <div className="col-span-1">
          <div className="font-bold">
            Notes
          </div>

          <hr className="my-2.5" />

          {
            orderDetail.notes ? (
              <div
                className="line-clamp-5"
                title={orderDetail.notes}
              >
                {orderDetail.notes}
              </div>
            ) : (
              <div>-</div>
            )
          }
        </div>

        <div className="col-span-1">
          <div className="font-bold">
            Address
          </div>

          <hr className="my-2.5" />

          <div>
            <div className="font-semibold">
              {address.name}
            </div>
            <div>
              Recipient name: {address.recipient?.name}
            </div>
            <div>
              Recipient phone: {address.recipient?.phone}
            </div>
            <div className="mt-1">
              {address.detail}
            </div>
            <div>
              {addressText()}
            </div>
            {
              address.notes && (
                <div className="italic text-sm mt-1">
                  Notes: {address.notes}
                </div>
              )
            }
          </div>
        </div>

        <div className="col-span-1" />

        <div className="col-span-1">
          <div className="font-bold">
            Prices
          </div>

          <hr className="my-2.5" />

          <div>
            {
              priceSummaries().map(price => (
                <div
                  key={price.id}
                  className="flex justify-between"
                >
                  <div>
                    {price.text}
                  </div>
                  <div>
                    {numberFormatter(price.nominal, 'Rp')}
                  </div>
                </div>
              ))
            }

            <div className="font-bold flex justify-between mt-1">
              <div>
                Total
              </div>
              <div>
                {numberFormatter(orderDetail.totalPrice, 'Rp')}
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-3">
          <div className="font-bold">
            Payment
          </div>

          <hr className="my-2.5" />

          <div className="flex flex-col gap-y-3">
            {
              payment.map(p => (
                <div
                  key={p.invoiceId}
                  className="flex flex-col"
                >
                  <div className="font-bold">
                    {getDescriptionText(p.description)}
                  </div>
                  <div>
                    Invoice Id: {p.invoiceId}
                  </div>
                  <div>
                    Amount: {numberFormatter(p.amount, 'Rp')}
                  </div>
                  <div>
                    Expiry Date: {p.expiryDate}
                  </div>
                  <div>
                    Invoice URL: {p.invoiceUrl}
                  </div>
                  <div>
                    Status: {p.status}
                  </div>
                  {
                    p.method && (
                      <div>
                        Method: {p.method}
                      </div>
                    )
                  }
                  {
                    p.channel && (
                      <div>
                        Channel: {p.channel}
                      </div>
                    )
                  }
                </div>
              ))
            }
          </div>
        </div>

        <div className="col-span-3">
          <div className="font-bold">
            Items
          </div>

          <hr className="my-2.5" />

          <section className="flex flex-col gap-y-6">
            {
              items.map((item, index) => (
                <OrderDetailItem
                  key={item.id}
                  index={index}
                  orderId={orderDetail.id}
                  id={item.id}
                  itemId={item.itemId}
                  status={item.status}
                  shipping={item.shipping}
                  subtotal={item.subtotal}
                  products={item.products}
                  onSetOrderReadyToShip={fetchPlainOrderDetail}
                  onSetOrderConfirmed={fetchPlainOrderDetail}
                  onCreateShippingOrder={fetchPlainOrderDetail}
                  onCancelShippingOrder={fetchPlainOrderDetail}
                  onAdjustPrice={fetchPlainOrderDetail}
                  cancelationReason={item.cancelationReason}
                  adjustment={item.adjustment}
                />
              ))
            }
          </section>
        </div>
      </section>
    </Header>
  )
}

export default OrderDetailPage