import React from 'react'

import Header from '@/components/Header'

const HomePage = () => {
  return (
    <Header title="Home">
      Welcome to Multi Toys Dashboard
    </Header>
  )
}

export default HomePage