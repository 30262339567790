import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { getNewsletters, getSubscribers, deleteSubscriber } from '@/store/slices/newsletter'
import { FaTrash } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { userGrantsSelector } from '@/store/slices/user'
import toast from 'react-hot-toast'
import useDidUpdateEffect from '@/hooks/did-update-effect'
import config from '@/constant/config'

import Header from '@/components/Header'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination'
import Modal from '@/components/Modal'

const { path } = config

const MENUS = [
  {
    text: 'Newsletter',
    value: 'NEWSLETTER'
  },
  {
    text: 'Subscriber',
    value: 'SUBSCRIBER'
  }
]

const NewsletterPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const grants = useSelector(userGrantsSelector)

  const [menu, setMenu] = useState(MENUS[0])
  const [subscribers, setSubscribers] = useState([])
  const [subscriberPaging, setSubscriberPaging] = useState({})
  const [newsletters, setNewsletters] = useState([])
  const [newsletterPaging, setNewsletterPaging] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [visibleDeleteSubscriberModal, setVisibleDeleteSubscriberModal] = useState(false)
  const [deletedSubscriber, setDeletedSubscriber] = useState()

  const hasReadAccess = () => grants.newsletter?.includes('read')
  const hasCreateAccess = () => grants.newsletter?.includes('create')
  const hasDeleteAccess = () => grants.newsletter?.includes('delete')

  const SUBSCRIBER_TABLE_HEADERS = [
    {
      text: 'Email',
      value: 'email'
    },
    {
      text: '',
      value: 'delete',
      slot: item => (
        hasDeleteAccess() ? (
          <FaTrash
            className="cursor-pointer"
            onClick={() => setDeletedSubscriber(item)}
          />
        ) : <></>
      )
    }
  ]

  const NEWSLETTER_TABLE_HEADERS = [
    {
      text: 'Subject',
      value: 'subject'
    },
    {
      text: 'Created At',
      value: 'createdAt',
      date: true
    },
    {
      text: 'Created By',
      value: 'createdBy'
    },
    {
      text: 'Recipients',
      value: 'recipient',
      number: true
    },
    {
      text: 'Sent',
      value: 'sent',
      number: true
    },
    {
      text: 'Failed',
      value: 'failed',
      number: true
    }
  ]

  useDidUpdateEffect(() => {
    if (deletedSubscriber) {
      setVisibleDeleteSubscriberModal(true)
    }
  }, [deletedSubscriber])

  useEffect(() => {
    if (menu.value === 'SUBSCRIBER') {
      fetchSubscribers()
    } else {
      fetchNewsletter()
    }
  }, [menu])

  const fetchSubscribers = async (page = 1) => {
    try {
      setIsLoading(true)

      const { data, paging } = await dispatch(getSubscribers(page)).unwrap()
      setSubscribers(data)
      setSubscriberPaging(paging)

      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const fetchNewsletter = async (page = 1) => {
    try {
      setIsLoading(true)

      const { data, paging } = await dispatch(getNewsletters(page)).unwrap()
      setNewsletters(data)
      setNewsletterPaging(paging)

      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const doDeleteSubscriber = async () => {
    try {
      setIsLoading(true)

      await dispatch(deleteSubscriber(deletedSubscriber.email)).unwrap()
      setVisibleDeleteSubscriberModal(false)

      setDeletedSubscriber(null)

      await fetchSubscribers()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setVisibleDeleteSubscriberModal(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  return (
    <Header title="Newsletter">
      {
        visibleDeleteSubscriberModal && (
          <Modal visibleState={[visibleDeleteSubscriberModal, setVisibleDeleteSubscriberModal]}>
            <div className="flex flex-col">
              <div className="text-xl font-bold mb-2">
                Remove Subscriber
              </div>
              <div>
                Are you sure to remove subscriber?
              </div>
              <div className="self-end mt-4">
                <button
                  type="button"
                  onClick={() => setVisibleDeleteSubscriberModal(false)}
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-2"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
                  onClick={doDeleteSubscriber}
                >
                  Remove
                </button>
              </div>
            </div>
          </Modal>
        )
      }


      <div className="flex">
        {
          MENUS.map(m => (
            <button
              key={m.value}
              className={classNames({
                'py-4 pr-6 font-semibold cursor-pointer': true,
                'text-blue-600': m.value === menu.value
              })}
              onClick={() => setMenu(m)}
            >
              {m.text}
            </button>
          ))
        }
      </div>

      <hr className="mt-2 mb-4" />

      {
        menu.value === 'NEWSLETTER' && (
          <div className="flex flex-col">
            {
              hasCreateAccess() && (
                <button
                  type="button"
                  className="mr-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
                  onClick={() => navigate(path.engagement.newsletterCreate)}
                >
                  Create
                </button>
              )
            }

            <section className="mt-4">
              {
                !isLoading && !!newsletters.length && (
                  <>
                    <Table
                      headers={NEWSLETTER_TABLE_HEADERS}
                      items={newsletters}
                      isLoading={isLoading}
                      {...hasReadAccess() && { onRowClick: item => navigate(path.engagement.newsletterDetail.replace(':id', item.id)) } }
                    />
                    <div className="self-end mt-3">
                      <Pagination
                        currentPage={newsletterPaging.page}
                        totalPage={newsletterPaging.totalPage}
                        setPage={page => fetchNewsletter(page)}
                      />
                    </div>
                  </>
                )
              }
              {
                !isLoading && !newsletters.length && (
                  <div className="ml-auto font-bold text-xl">
                    No Newsletter Yet
                  </div>
                )
              }
            </section>
          </div>
        )
      }

      {
        menu.value === 'SUBSCRIBER' && (
          <div className="w-1/2 flex flex-col">
            <Table
              headers={SUBSCRIBER_TABLE_HEADERS}
              items={subscribers}
              isLoading={isLoading}
            />
            {
              !isLoading && !!subscribers.length && (
                <div className="self-end mt-3">
                  <Pagination
                    currentPage={subscriberPaging.page}
                    totalPage={subscriberPaging.totalPage}
                    setPage={page => fetchSubscribers(page)}
                  />
                </div>
              )
            }
          </div>
        )
      }
    </Header>
  )
}

export default NewsletterPage