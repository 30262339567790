import { configureStore } from '@reduxjs/toolkit'

import categoryReducer from './slices/category'
import brandReducer from './slices/brand'
import characterReducer from './slices/character'
import seriesReducer from './slices/series'
import userReducer from './slices/user'
import productReducer from './slices/product'
import imageReducer from './slices/image'
import bannerReducer from './slices/banner'
import voucherReducer from './slices/voucher'
import roleReducer from './slices/role'
import orderReducer from './slices/order'
import shippingReducer from './slices/shipping'
import configReducer from './slices/config'
import newsletterReducer from './slices/newsletter'
import storeLocationReducer from './slices/store-location'
import customLayoutReducer from './slices/custom-layout'

const store = configureStore({
  reducer: {
    category: categoryReducer,
    brand: brandReducer,
    character: characterReducer,
    series: seriesReducer,
    user: userReducer,
    product: productReducer,
    image: imageReducer,
    banner: bannerReducer,
    voucher: voucherReducer,
    role: roleReducer,
    order: orderReducer,
    shipping: shippingReducer,
    config: configReducer,
    newsletter: newsletterReducer,
    storeLocation: storeLocationReducer,
    customLayout: customLayoutReducer
  }
})

export default store
