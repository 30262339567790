import React from 'react'

const AVAILABILITY_STYLES = {
  WAITING_LIST: {
    text: 'Waiting List',
    background: 'bg-blue-multi'
  },
  READY_STOCK: {
    text: 'Ready Stock',
    background: 'bg-green-multi'
  },
  PREORDER: {
    text: 'Pre-order',
    background: 'bg-orange-multi'
  },
  LATE_PREORDER: {
    text: 'Late Pre-order',
    background: 'bg-red-multi'
  }
}

const ProductAvailabilityStatus = ({ availability, stock, hidden }) => {
  const isOutOfStock = () => {
    return stock !== undefined && stock <= 0
  }

  const getProductStatus = () => {
    if (isOutOfStock()) {
      return (
        <div className="text-white py-1.5 px-2 font-semibold text-[0.65rem] bg-gray-multi">
          Out of Stock
        </div>
      )
    }

    if (hidden) {
      return (
        <div className="text-white py-1.5 px-2 font-semibold text-[0.65rem] bg-gray-multi">
          Product Unavailable
        </div>
      )
    }

    return (
      <div className={`text-white py-1.5 px-2 font-semibold text-[0.65rem] ${AVAILABILITY_STYLES[availability].background}`}>
        {AVAILABILITY_STYLES[availability].text}
      </div>
    )
  }

  return getProductStatus()
}

export default ProductAvailabilityStatus