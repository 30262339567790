import React, { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { loginInfo, setUser, setIsGettingUser } from '@/store/slices/user'
import { useDispatch } from 'react-redux'
import router from './constant/routes'

const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const getLoginInfo = async () => {
      try {
        const response = await dispatch(loginInfo()).unwrap()
        dispatch(setIsGettingUser(false))
        dispatch(setUser(response.data))
      } catch(err) {
        dispatch(setIsGettingUser(false))
      }
    }
    getLoginInfo()
  }, [])

  return (
    <>
      <RouterProvider router={router} />
      <Toaster />
    </>
  )
}

export default App