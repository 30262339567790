import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getFeaturedProducts = createAsyncThunk(
  'featuredProduct/getFeaturedProducts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.featuredProduct)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const getFeaturedProductDetail = createAsyncThunk(
  'featuredProduct/getFeaturedProductDetail',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.featuredProductDetail(id))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const saveFeaturedProduct = createAsyncThunk(
  'featuredProduct/saveFeaturedProduct',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.featuredProduct, body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const updateFeaturedProduct = createAsyncThunk(
  'featuredProduct/updateFeaturedProduct',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const response = await axios.put(api.featuredProductDetail(id), body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const updateFeaturedProductSequence = createAsyncThunk(
  'featuredProduct/updateFeaturedProductSequence',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const response = await axios.put(api.featuredProductSequence(id), body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const deleteFeaturedProduct = createAsyncThunk(
  'featuredProduct/deleteFeaturedProduct',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(api.featuredProductDetail(id))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const featuredProductSlice = createSlice({
  name: 'featuredProduct',
  initialState: {},
  reducers: {}
})

export default featuredProductSlice.reducer