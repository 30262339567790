import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getRoles = createAsyncThunk(
  'role/getRoles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.role)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const getRoleDetail = createAsyncThunk(
  'role/getRoleDetail',
  async (name, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.roleDetail(name))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const saveRole = createAsyncThunk(
  'role/saveRole',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.role, body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const updateRole = createAsyncThunk(
  'role/updateRole',
  async ({ name, body }, { rejectWithValue }) => {
    try {
      const response = await axios.put(api.roleDetail(name), body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const deleteRole = createAsyncThunk(
  'role/deleteRole',
  async (name, { rejectWithValue }) => {
    try {
      const response = await axios.delete(api.roleDetail(name))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)


export const roleSlice = createSlice({
  name: 'role',
  initialState: {},
  reducers: {}
})

export default roleSlice.reducer
