import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getSeries = createAsyncThunk(
  'series/getSeries',
  async ({ page, size, search }, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.series, { params: { page, search, size } })
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const getSeriesDetail = createAsyncThunk(
  'series/getSeriesDetail',
  async (seriesId, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.seriesDetail(seriesId))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const saveSeries = createAsyncThunk(
  'series/saveSeries',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.series, body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const updateSeries = createAsyncThunk(
  'series/updateSeries',
  async ({ seriesId, body }, { rejectWithValue }) => {
    try {
      const response = await axios.put(api.seriesDetail(seriesId), body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const deleteSeries = createAsyncThunk(
  'series/deleteSeries',
  async (seriesId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(api.seriesDetail(seriesId))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const seriesSlice = createSlice({
  name: 'series',
  initialState: {},
  reducers: {}
})

export default seriesSlice.reducer
