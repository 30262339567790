import React, { useState } from 'react'
import { FaTimes } from 'react-icons/fa'

const ProductImage = ({ url, onRemove }) => {
  const [hover, setHover] = useState(false)

  return (
    <div
      className="relative mr-5 border border-gray-200"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <img
        src={url}
        className="w-48 h-48 object-contain"
      />

      {
        hover && onRemove && (
          <>
            <div className="absolute left-0 top-0 w-full h-full bg-black opacity-30" />
            <FaTimes
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-2xl cursor-pointer"
              onClick={onRemove}
            />
          </>
        )
      }
    </div>
  )
}

export default ProductImage