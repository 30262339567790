import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOrderReadyToShip, setOrderConfirmed, cancelOrder, adjustPrice } from '@/store/slices/order'
import { createOrder } from '@/store/slices/shipping'
import { userGrantsSelector } from '@/store/slices/user'
import { numberFormatter } from '@/utils/common'
import toast from 'react-hot-toast'
import classNames from 'classnames'

import OrderStatusLabel from '@/components/OrderStatusLabel'
import ProductAvailabilityLabel from '@/components/ProductAvailabilityLabel'
import Modal from '@/components/Modal'
import FullPageLoader from '@/components/FullPageLoader'
import PriceAdjustmentModal from '@/components/PriceAdjustmentModal'

const OrderDetailItem = ({
  index,
  orderId,
  id,
  itemId,
  status,
  shipping,
  subtotal,
  products,
  onSetOrderReadyToShip,
  onSetOrderConfirmed,
  onCreateShippingOrder,
  onCancelShippingOrder,
  onAdjustPrice,
  cancelationReason,
  adjustment
}) => {
  const dispatch = useDispatch()

  const grants = useSelector(userGrantsSelector)

  const hasUpdateOrderAccess = () => grants.order?.includes('update')

  const [isLoading, setIsLoading] = useState(false)
  const [visibleReadyToShipConfirmationModal, setVisibleReadyToShipConfirmationModal] = useState(false)
  const [visibleCreateShippingConfirmationModal, setVisibleCreateShippingConfirmationModal] = useState(false)
  const [visibleCancelOrderConfirmationModal, setVisibleCancelOrderConfirmationModal] = useState(false)
  const [visibleConfirmOrderModal, setVisibleConfirmOrderModal] = useState(false)
  const [visibleAdjustmentModal, setVisibleAdjustmentModal] = useState(false)
  const [cancelReason, setCancelReason] = useState('')

  const setReadyToShip = async () => {
    try {
      setIsLoading(true)

      await dispatch(setOrderReadyToShip({
        orderId,
        orderItemId: id
      })).unwrap()
      await onSetOrderReadyToShip()
      setVisibleReadyToShipConfirmationModal(false)

      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)
      setVisibleReadyToShipConfirmationModal(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const setConfirmed = async () => {
    try {
      setIsLoading(true)

      await dispatch(setOrderConfirmed({
        orderId,
        orderItemId: id
      })).unwrap()
      await onSetOrderConfirmed()
      setVisibleConfirmOrderModal(false)

      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)
      setVisibleConfirmOrderModal(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const createShippingOrder = async () => {
    try {
      setIsLoading(true)

      await dispatch(createOrder({
        orderId,
        orderItemId: id
      })).unwrap()
      await onCreateShippingOrder()
      setVisibleCreateShippingConfirmationModal(false)

      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)
      setVisibleCreateShippingConfirmationModal(false)

      if (error.data?.error === 'BITESHIP_ERROR_40002037') {
        toast.error('Same Day service time must between 09.00 and 15.00.')
        return
      }
      toast.error('Oops something wrong, please try again')
    }
  }

  const isReadyStockOrderItem = () => id === 'READY_STOCK'

  const isAvailableForCreatingShippingOrder = () => {
    const readyStockReadyToShip = status === 'READY_TO_SHIP' && id === 'READY_STOCK'
    const poPaymentSettled = status === 'PAYMENT_SETTLED' && id !== 'READY_STOCK'
    return readyStockReadyToShip || poPaymentSettled
  }

  const isAvailableForCancelOrder = () => {
    return (
      status === 'PROCESSED' ||
      status === 'CONFIRMED' ||
      status === 'READY_TO_SHIP' ||
      status === 'PAYMENT_SETTLED' ||
      status === 'IN_DELIVERY'
    )
  }

  const isAvailableForAdjustment = () => {
    return !isReadyStockOrderItem() && !adjustment && (
      status === 'PROCESSED' ||
      status === 'CONFIRMED' ||
      status === 'READY_TO_SHIP' ||
      status === 'PAYMENT_SETTLED'
    )
  }

  const isReadyStockProcessed = () => isReadyStockOrderItem() && status === 'PROCESSED'

  const isPreOrderConfirmed = () => !isReadyStockOrderItem() && status === 'CONFIRMED'

  const doCancelOrder = async () => {
    try {
      if (!cancelReason) {
        toast.error('Fill cancelation reason!')
        return
      }

      setIsLoading(true)

      await dispatch(cancelOrder({
        orderId,
        orderItemId: id,
        reason: cancelReason
      })).unwrap()

      setVisibleCancelOrderConfirmationModal(false)
      await onCancelShippingOrder()
      toast.success('Order canceled')

      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)
      setVisibleCancelOrderConfirmationModal(false)

      if (error.data?.error === 'BITESHIP_ERROR_40002053') {
        toast.error('Order already picked up by driver and cant be canceled')
        return
      }

      toast.error('Oops something wrong, please try again')
    }
  }

  const submitAdjustment = async (adjustments, reason) => {
    try {
      setIsLoading(true)

      await dispatch(adjustPrice({
        orderId,
        orderItemId: id,
        adjustments,
        reason
      })).unwrap()
      setVisibleAdjustmentModal(false)
      await onAdjustPrice()
      toast.success('Adjust price success')

      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)
      setVisibleAdjustmentModal(false)
      if (error.data?.error === 'NOT_AVAILABLE_FOR_ADJUSTMENT') {
        toast.error('Order item is not available for adjustment')
        return
      }
      toast.error('Oops something wrong, please try again')
    }
  }

  return (
    <>
      {isLoading && <FullPageLoader />}

      {
        visibleAdjustmentModal && (
          <PriceAdjustmentModal
            visibleState={[visibleAdjustmentModal, setVisibleAdjustmentModal]}
            onSubmit={submitAdjustment}
            products={products}
          />
        )
      }

      {
        visibleReadyToShipConfirmationModal && (
          <Modal visibleState={[visibleReadyToShipConfirmationModal, setVisibleReadyToShipConfirmationModal]}>
            <div className="flex flex-col">
              <div className="text-xl font-bold mb-2">
                Update Order Status
              </div>
              <div>
                Are you sure to update order status to ready to ship?
              </div>
              <div>
                Customer will be notified via email when their order is ready to ship
              </div>
              <div className="self-end mt-4">
                <button
                  type="button"
                  onClick={() => setVisibleReadyToShipConfirmationModal(false)}
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-2"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
                  onClick={setReadyToShip}
                >
                  Update
                </button>
              </div>
            </div>
          </Modal>
        )
      }

      {
        visibleConfirmOrderModal && (
          <Modal visibleState={[visibleConfirmOrderModal, setVisibleConfirmOrderModal]}>
            <div className="flex flex-col">
              <div className="text-xl font-bold mb-2">
                Update Order Status
              </div>
              <div>
                Are you sure to update order status to confirmed?
              </div>
              <div className="self-end mt-4">
                <button
                  type="button"
                  onClick={() => setVisibleConfirmOrderModal(false)}
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-2"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
                  onClick={setConfirmed}
                >
                  Update
                </button>
              </div>
            </div>
          </Modal>
        )
      }

      {
        visibleCreateShippingConfirmationModal && (
          <Modal visibleState={[visibleCreateShippingConfirmationModal, setVisibleCreateShippingConfirmationModal]}>
            <div className="flex flex-col">
              <div className="text-xl font-bold mb-2">
                Create Shipping Order
              </div>
              <div>
                Are you sure to create shipping order?
              </div>
              <div className="self-end mt-4">
                <button
                  type="button"
                  onClick={() => setVisibleCreateShippingConfirmationModal(false)}
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-2"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
                  onClick={createShippingOrder}
                >
                  Create
                </button>
              </div>
            </div>
          </Modal>
        )
      }

      {
        visibleCancelOrderConfirmationModal && (
          <Modal visibleState={[visibleCancelOrderConfirmationModal, setVisibleCancelOrderConfirmationModal]}>
            <div className="flex flex-col">
              <div className="text-xl font-bold mb-2">
                Cancel Order
              </div>
              <div>
                Are you sure to cancel this order item?
              </div>

              <input
                type="text"
                onChange={e => setCancelReason(e.target.value)}
                className="my-2 w-full p-2 border border-gray-300 rounded-lg"
                placeholder="Reason"
              />

              <div className="self-end mt-4">
                <button
                  type="button"
                  onClick={() => setVisibleCancelOrderConfirmationModal(false)}
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-2"
                >
                  Back
                </button>
                <button
                  type="button"
                  className="ml-auto px-6 py-1.5 rounded-full border border-red-500 text-red-500 cursor-pointer"
                  onClick={doCancelOrder}
                >
                  Proceed to Cancel
                </button>
              </div>
            </div>
          </Modal>
        )
      }

      <div className="border">
        <div className="p-3 font-semibold flex items-center gap-x-2 bg-gray-100">
          Order {index + 1} - {itemId} - {id}
          <OrderStatusLabel status={status} />
        </div>

        <div className="p-3 grid grid-cols-4 gap-y-6">
          <div className="col-span-2">
            <div className="font-semibold">
              Shipping method
            </div>

            {
              shipping.rate ? (
                <div className="flex flex-col">
                  <div>
                    Courier Name: {shipping.rate.courierName}
                  </div>
                  <div>
                    Courier Service: {shipping.rate.courierServiceName}
                  </div>
                  <div>
                    Rate: {numberFormatter(shipping.rate.price, 'Rp')}
                  </div>
                </div>
              ) : (
                <div>
                  -
                </div>
              )
            }
          </div>

          <div className="col-span-1">
            <div className="font-semibold">
              Subtotal
            </div>

            <div>
              {
                subtotal.map(total => (
                  <div
                    key={total.id}
                    className="flex gap-x-4 text-sm"
                  >
                    <div>
                      {total.text}:
                    </div>
                    <div>
                      {numberFormatter(total.nominal, 'Rp')}
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          {
            hasUpdateOrderAccess() && isAvailableForAdjustment() ? (
              <div className="col-span-1 ml-auto">
                <button
                  type="button"
                  className="col-span-1 underline text-blue-700"
                  onClick={() => setVisibleAdjustmentModal(true)}
                >
                  Price Adjustment
                </button>
              </div>
            ) : <div className="col-span-1" />
          }

          <div className="col-span-4">
            <div className="font-semibold mb-3">
              Products
            </div>

            <div>
              {
                products.map(product => (
                  <div
                    key={product._id}
                    className="mb-3 flex"
                  >
                    <img
                      src={product.images[0]}
                      className="w-24 aspect-square object-contain bg-gray-200"
                      alt="product image"
                    />

                    <div className="mx-3 flex flex-col items-start">
                      <div className="flex">
                        <strong>
                          {product._id}
                        </strong>
                        <div className="ml-2">
                          <ProductAvailabilityLabel availability={product.availability} />
                        </div>
                      </div>
                      <div
                        className="font-semibold line-clamp-2 mt-2"
                        title={product.name}
                      >
                        {product.name}
                      </div>
                      {
                        product.downPayment && (
                          <small className="text-gray-500">
                            50% Down Payment
                          </small>
                        )
                      }
                      <div className="text-sm mt-2">
                        {product.quantity}x &nbsp;&nbsp;
                        {numberFormatter(product.price.offer, 'Rp')}
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          {
            adjustment && (
              <div className="col-span-4">
                <div className="font-semibold">
                  Price Adjustment
                </div>

                <div className="flex flex-col">
                  <div>
                    Status: {adjustment.status}
                  </div>
                  <div>
                    Reason: {adjustment.reason}
                  </div>
                  <div className="flex">
                    Deficit Amount:
                    <div className="text-red-500 ml-1">
                      {numberFormatter(adjustment.deficit, 'Rp')}
                    </div>
                  </div>

                  <table className="w-1/4 mt-2 border-collapse border border-gray-500">
                    <thead>
                      <tr>
                        <th>SKU</th>
                        <th>Qty</th>
                        <th>Old Price</th>
                        <th>New Price</th>
                        <th>Deficit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        adjustment.products.map(p => (
                          <tr className="text-center">
                            <td className="p-3">{p.sku}</td>
                            <td className="p-3">{p.qty || 0}</td>
                            <td className="p-3">{numberFormatter(p.old, 'Rp')}</td>
                            <td className="p-3">{numberFormatter(p.new, 'Rp')}</td>
                            <td className="p-3 text-red-500">{numberFormatter(p.deficit || 0, 'Rp')}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            )
          }

          {
            shipping.detail && (
              <div className="col-span-4">
                <div className="font-semibold">
                  Shipping Detail
                </div>

                <div className="flex flex-col">
                  <div>
                    Shipping Id: {shipping.detail.id}
                  </div>
                  <div>
                    Actual Rate: {numberFormatter(shipping.detail.price, 'Rp')}
                  </div>
                  <div>
                    Tracking Id: {shipping.detail.courier?.trackingId}
                  </div>
                  <div>
                    Waybill Number: {shipping.detail.courier?.waybillId}
                  </div>
                  <div>
                    Status: {shipping.detail.status}
                  </div>
                </div>
              </div>
            )
          }

          {
            shipping.detail && shipping.detail.priceChange && (
              <div className="flex flex-col">
                <div className="font-semibold">
                  Shipping Price Change
                </div>
                <div>
                  Previous: {numberFormatter(shipping.detail.priceChange.previous, 'Rp')}
                </div>
                <div>
                  Updated: {numberFormatter(shipping.detail.priceChange.value, 'Rp')}
                </div>

                {
                  shipping.detail.priceChange.diff && (
                    <div className={classNames({
                      'text-red-500': shipping.detail.priceChange.diff < 0,
                      'text-green-500': shipping.detail.priceChange.diff >= 0
                    })}>
                      Difference: {numberFormatter(shipping.detail.priceChange.diff, 'Rp')}
                    </div>
                  )
                }
              </div>
            )
          }

          {
            cancelationReason && (
              <div className="flex flex-col">
                <div className="font-semibold">
                  Cancelation Reason
                </div>
                <div>
                  {cancelationReason}
                </div>
              </div>
            )
          }
        </div>

        <hr />

        <div className="p-3 flex items-center">
          {
            hasUpdateOrderAccess() && isAvailableForCancelOrder() && (
              <button
                type="button"
                className="mr-2 py-2 px-10 border border-red-500 text-red-500 rounded-full"
                onClick={() => setVisibleCancelOrderConfirmationModal(true)}
              >
                Cancel
              </button>
            )
          }

          {
            hasUpdateOrderAccess() && !isReadyStockOrderItem() && status === 'PROCESSED' && (
              <button
                type="button"
                className="ml-auto mr-2 py-2 px-10 border border-gray-700 bg-gray-700 text-white rounded-full"
                onClick={() => setVisibleConfirmOrderModal(true)}
              >
                Confirm Order
              </button>
            )
          }

          {
            hasUpdateOrderAccess() && (isReadyStockProcessed() || isPreOrderConfirmed()) && (
              <button
                type="button"
                className="ml-auto mr-2 py-2 px-10 border border-gray-700 bg-gray-700 text-white rounded-full"
                onClick={() => setVisibleReadyToShipConfirmationModal(true)}
              >
                Ready to Ship
              </button>
            )
          }

          {
            hasUpdateOrderAccess() && isAvailableForCreatingShippingOrder() && (
              <button
                type="button"
                className="ml-auto mr-2 py-2 px-10 border border-gray-700 bg-gray-700 text-white rounded-full"
                onClick={() => setVisibleCreateShippingConfirmationModal(true)}
              >
                Create Shipping Order
              </button>
            )
          }

          {
            status === 'READY_TO_SHIP' && id !== 'READY_STOCK' && (
              <div className="italic ml-auto">
                Waiting customer to finish their payment
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}

export default OrderDetailItem