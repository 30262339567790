import React from 'react'

const Modal = ({ children, visibleState, width = 'w-1/3' }) => {
  const [visible, setVisible] = visibleState

  return visible ? (
    <>
      <div
        className="fixed top-0 left-0 h-screen w-screen bg-black opacity-30 z-40"
        onClick={() => setVisible(false)}
      />
      <div className={'fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40 bg-white rounded-xl p-5 shadow ' + width}>
        <div className="max-h-[85vh] overflow-y-auto">
          {children}
        </div>
      </div>
    </>
  ) : <></>
}

export default Modal