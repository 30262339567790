import React, { useState } from 'react'

import BaseItem from '@/components/CustomLayout/BaseItem'
import TextComponent from '@/components/CustomLayout/TextComponent'

const TextItem = ({ component, index, onUpdateComponent, onRemoveComponent }) => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      {
        visible && (
          <TextComponent
            visibleState={[visible, setVisible]}
            onSubmit={comp => onUpdateComponent(comp, index)}
            initial={component.text}
          />
        )
      }

      <BaseItem
        onUpdateComponent={() => setVisible(true)}
        onRemoveComponent={() => onRemoveComponent(component, index)}
      >
        <div
          className="react-draft-preview"
          dangerouslySetInnerHTML={{ __html: component.text }}
        />
      </BaseItem>
    </>
  )
}

export default TextItem