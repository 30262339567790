import React from 'react'

const AVAILABILITY_MAP = {
  READY_STOCK: {
    text: 'Ready Stock',
    color: 'green-500'
  },
  PREORDER: {
    text: 'Pre-order',
    color: 'blue-500'
  },
  LATE_PREORDER: {
    text: 'Late Pre-order',
    color: 'blue-500'
  },
  WAITING_LIST: {
    text: 'Waiting List',
    color: 'gray-500'
  }
}

const ProductAvailabilityLabel = ({ availability }) => {
  const availabilityDetail = AVAILABILITY_MAP[availability]
  return (
    <div className={`rounded-full text-center text-white text-[11px] px-2 py-1 bg-${availabilityDetail?.color}`}>
      {availabilityDetail?.text}
    </div>
  )
}

export default ProductAvailabilityLabel