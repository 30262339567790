import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { FaEdit, FaTrash, FaTimes, FaCheck } from 'react-icons/fa'
import classNames from 'classnames'

const AttributeTableItem = ({ number, name, onEdit, onDelete, index, hasEditAccess, hasDeleteAccess }) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [inputName, setInputName] = useState(name)
  const inputRef = useRef(null)

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus()
      setInputName(name)
    }
  }, [isEditing])

  const handleNameChange = e => {
    setInputName(e.target.value)
  }

  const doEdit = () => {
    onEdit(inputName)
    setIsEditing(false)
  }

  const doDelete = () => {
    onDelete()
    setIsDeleting(false)
  }

  return (
    <tr className={classNames({
      'bg-gray-100': index % 2 === 1
    })}>
      <td width="4%" className="p-4 text-center">
        {number}.
      </td>

      <td className="p-4">
        {
          isEditing ? (
            <input
              ref={inputRef}
              type="text"
              value={inputName}
              onChange={handleNameChange}
              className="p-1.5 border border-black"
            />
          ) : (
            name
          )
        }
      </td>

      <td width="5%">
        {
          isEditing && (
            <div className="flex">
              <FaCheck
                onClick={doEdit}
                className="mr-4 cursor-pointer"
              />
              <FaTimes
                onClick={() => setIsEditing(false)}
                className="cursor-pointer"
              />
            </div>
          )
        }
        {
          !isEditing && !isDeleting && hasEditAccess && (
            <FaEdit
              className="cursor-pointer"
              onClick={() => setIsEditing(true)}
            />
          )
        }
      </td>

      <td width="5%">
        {
          isDeleting && (
            <div className="flex">
              <FaCheck
                onClick={doDelete}
                className="mr-4 cursor-pointer"
              />
              <FaTimes
                onClick={() => setIsDeleting(false)}
                className="cursor-pointer"
              />
            </div>
          )
        } 
        {
          !isDeleting && !isEditing && hasDeleteAccess && (
            <FaTrash
              className="cursor-pointer"
              onClick={() => setIsDeleting(true)}
            />
          )
        }
      </td>
    </tr>
  )
}

AttributeTableItem.propTypes = {
  number: PropTypes.number,
  name: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  index: PropTypes.number,
  hasEditAccess: PropTypes.bool,
  hasDeleteAccess: PropTypes.bool
}

export default AttributeTableItem