import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getStoreLocations = createAsyncThunk(
  'storeLocation/getStoreLocations',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.storeLocation)
      return data
    } catch(error) {
      console.log(error)
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const getStoreLocationDetail = createAsyncThunk(
  'storeLocation/getStoreLocationDetail',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.storeLocationDetail(id))
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const saveStoreLocation = createAsyncThunk(
  'storeLocation/saveStoreLocation',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.storeLocation, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const updateStoreLocation = createAsyncThunk(
  'storeLocation/updateStoreLocation',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(api.storeLocationDetail(id), body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const deleteStoreLocation = createAsyncThunk(
  'storeLocation/deleteStoreLocation',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(api.storeLocationDetail(id))
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const storeLocationSlice = createSlice({
  name: 'storeLocation',
  initialState: {},
  reducers: {}
})

export default storeLocationSlice.reducer