import React, { useEffect, useState } from 'react'
import {
  getBrands,
  saveBrand,
  updateBrand,
  deleteBrand
} from '@/store/slices/brand'
import { userGrantsSelector } from '@/store/slices/user'
import { useDispatch, useSelector } from 'react-redux'
import debounce from '@/utils/debouncer'
import toast from 'react-hot-toast'

import Header from '@/components/Header'
import Pagination from '@/components/Pagination'
import AttributeTableItem from '@/components/AttributeTableItem'
import TableLoader from '@/components/TableLoader'

const BrandPage = () => {
  const dispatch = useDispatch()

  const grants = useSelector(userGrantsSelector)

  const [brands, setBrands] = useState([])
  const [paging, setPaging] = useState({})
  const [search, setSearch] = useState('')
  const [brandName, setBrandName] = useState('')
  const [isFetchingBrand, setIsFetchingBrand] = useState(false)

  useEffect(() => {
    fetchBrands()
  }, [search])

  const fetchBrands = async (page = 1) => {
    try {
      setIsFetchingBrand(true)

      const brandResponses = await dispatch(getBrands({ page, search })).unwrap()
      setBrands(brandResponses.data)
      setPaging(brandResponses.paging)

      setIsFetchingBrand(false)
    } catch(err) {
      toast.error('Oops something wrong, please try again')
      setIsFetchingBrand(false)
    }
  }

  const hasCreateBrandAccess = () => grants.brand?.includes('create')

  const hasUpdateBrandAccess = () => grants.brand?.includes('update')

  const hasDeleteBrandAccess = () => grants.brand?.includes('delete')

  const handleSearch = e => {
    debounce(() => setSearch(e.target.value), 300, 'search')
  }

  const createBrand = async () => {
    if (brandName.length < 3) {
      toast.error('Brand name must have minimal 3 letter\'s')
      return
    }

    try {
      await dispatch(saveBrand({ name: brandName })).unwrap()
      toast.success('Success creating ' + brandName)
      setBrandName('')
      fetchBrands()
    } catch(err) {
      toast.error('Fail creating ' + brandName + ', please try again')
    }
  }

  const onEdit = async (brandId, name) => {
    if (name.length < 3) {
      toast.error('Brand name must have minimal 3 letter\'s')
      return
    }

    try {
      await dispatch(updateBrand({ brandId, body: { name } })).unwrap()
      toast.success('Brand edited')
      fetchBrands()
    } catch(err) {
      toast.error('Fail editing ' + brandName + ', please try again')
    }
  }

  const onDelete = async brand => {
    try {
      await dispatch(deleteBrand(brand.id)).unwrap()
      toast.success('Brand deleted')
      fetchBrands()
    } catch(err) {
      if (err.data.error === 'BRAND_IN_USE') {
        toast.error(`${brand.name} is still used on product or banner`)
        return
      }
      toast.error('Fail deleting ' + brand.name + ', please try again')
    }
  }

  return (
    <Header title="Brands">
      <div className="flex items-center justify-between mb-8 mt-4">
        <input
          type="text"
          className="p-2 w-1/4 border border-gray-300 rounded-lg mr-4"
          placeholder="Search"
          onChange={handleSearch}
        />

        {
          hasCreateBrandAccess() && (
            <>
              <input
                type="text"
                className="p-2 ml-auto w-1/5 border border-gray-300 rounded-lg mr-4"
                placeholder="Brand name"
                value={brandName}
                onChange={e => setBrandName(e.target.value)}
                disabled={isFetchingBrand}
              />
              <button
                className="py-2 px-10 bg-gray-700 text-white rounded-full"
                onClick={createBrand}
                disabled={isFetchingBrand}
              >
                Create
              </button>
            </>
          )
        }
      </div>

      <table className="w-full">
        <thead className="border border-gray-300 w-full">
          <tr>
            <th width="4%" className="p-4 text-left">
              No
            </th>
            <th className="p-4 text-left">
              Name
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody className="border border-gray-300">
          {
            isFetchingBrand && <TableLoader />
          }
          {
            !isFetchingBrand && brands.map((brand, idx) => (
              <AttributeTableItem
                key={brand.id}
                index={idx}
                number={((paging.page - 1) * paging.size) + idx + 1}
                name={brand.name}
                onEdit={newName => onEdit(brand.id, newName)}
                onDelete={() => onDelete(brand)}
                hasEditAccess={hasUpdateBrandAccess()}
                hasDeleteAccess={hasDeleteBrandAccess()}
              />
            ))
          }
        </tbody>
      </table>

      {
        !isFetchingBrand && !!brands.length && (
          <div className="self-end mt-3">
            <Pagination
              currentPage={paging.page}
              totalPage={paging.totalPage}
              setPage={page => fetchBrands(page)}
            />
          </div>
        )
      }
    </Header>
  )
}

export default BrandPage