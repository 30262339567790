import React, { useEffect, useState } from 'react'
import { getProducts } from '@/store/slices/product'
import { useDispatch } from 'react-redux'
import debounce from '@/utils/debouncer'
import toast from 'react-hot-toast'

import Dropdown from '@/components/Dropdown'

const ProductDropdown = ({ error, value, onItemChange, disabled }) => {
  const dispatch = useDispatch()

  const [products, setProducts] = useState({ data: [], paging: {} })
  const [productSearch, setProductSearch] = useState(undefined)
  const [isFetchingProduct, setIsFetchingProduct] = useState(false)

  useEffect(() => {
    if (productSearch === undefined) {
      return
    }
    debounce(() => fetchProduct(1, false), 200, 'product-search')
  }, [productSearch])

  const fetchProduct = async (page, append = true) => {
    try {
      setIsFetchingProduct(true)

      const productResponses = await dispatch(getProducts({ page, status: 'PUBLISHED', search: productSearch })).unwrap()
      setProducts({
        data: append ? [...products.data, ...productResponses.data] : productResponses.data,
        paging: productResponses.paging
      })

      setIsFetchingProduct(false)
    } catch(err) {
      toast.error('Oops something wrong, please try again')
      setIsFetchingProduct(false)
    }
  }

  const getNextPageProduct = () => {
    if (products.paging.page >= products.paging.totalPage) {
      return
    }

    fetchProduct(products.paging.page + 1)
  }

  const productDropdownItems = () => (products.data || []).map(b => ({ key: b.sku, value: `${b.sku} - ${b.name}` }))

  const handleOnItemChange = selectedItem => {
    onItemChange && onItemChange(selectedItem)
  }

  return (
    <Dropdown
      name="Product"
      items={productDropdownItems()}
      emptyMessage="No Products Yet"
      searchState={[productSearch, setProductSearch]}
      onDropdownOpen={() => fetchProduct(1, false)}
      isLoading={isFetchingProduct}
      onIntersecting={getNextPageProduct}
      onItemChange={handleOnItemChange}
      error={error}
      value={value}
      disabled={disabled}
    />
  )
}

export default ProductDropdown