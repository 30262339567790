import React from 'react'
import ReactDOM from 'react-dom/client'
import store from '@/store'
import App from '@/App'
import { Provider } from 'react-redux'

import './index.scss'
import 'react-datepicker/dist/react-datepicker.css'
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
