import React, { useEffect, useState } from 'react'
import {
  getCharacters,
  saveCharacter,
  updateCharacter,
  deleteCharacter
} from '@/store/slices/character'
import { userGrantsSelector } from '@/store/slices/user'
import { useDispatch, useSelector } from 'react-redux'
import debounce from '@/utils/debouncer'
import toast from 'react-hot-toast'

import Header from '@/components/Header'
import Pagination from '@/components/Pagination'
import AttributeTableItem from '@/components/AttributeTableItem'
import TableLoader from '@/components/TableLoader'

const CharacterPage = () => {
  const dispatch = useDispatch()
  
  const grants = useSelector(userGrantsSelector)

  const [characters, setCharacters] = useState([])
  const [paging, setPaging] = useState({})
  const [search, setSearch] = useState('')
  const [characterName, setCharacterName] = useState('')
  const [isFetchingCharacter, setIsFetchingCharacter] = useState(false)

  useEffect(() => {
    fetchCharacters()
  }, [search])

  const fetchCharacters = async (page = 1) => {
    try {
      setIsFetchingCharacter(true)

      const characterResponses = await dispatch(getCharacters({ page, search })).unwrap()
      setCharacters(characterResponses.data)
      setPaging(characterResponses.paging)

      setIsFetchingCharacter(false)
    } catch(err) {
      toast.error('Oops something wrong, please try again')
      setIsFetchingCharacter(false)
    }
  }

  const hasCreateCharacterAccess = () => grants.character?.includes('create')

  const hasUpdateCharacterAccess = () => grants.character?.includes('update')

  const hasDeleteCharacterAccess = () => grants.character?.includes('delete')

  const handleSearch = e => {
    debounce(() => setSearch(e.target.value), 300, 'search')
  }

  const createCharacter = async () => {
    if (characterName.length < 3) {
      toast.error('Character name must have minimal 3 letter\'s')
      return
    }

    try {
      await dispatch(saveCharacter({ name: characterName })).unwrap()
      toast.success('Success creating ' + characterName)
      setCharacterName('')
      fetchCharacters()
    } catch(err) {
      toast.error('Fail creating ' + characterName + ', please try again')
    }
  }

  const onEdit = async (characterId, name) => {
    if (name.length < 3) {
      toast.error('Character name must have minimal 3 letter\'s')
      return
    }

    try {
      await dispatch(updateCharacter({ characterId, body: { name } })).unwrap()
      toast.success('Character edited')
      fetchCharacters()
    } catch(err) {
      toast.error('Fail editing ' + characterName + ', please try again')
    }
  }

  const onDelete = async character => {
    try {
      await dispatch(deleteCharacter(character.id)).unwrap()
      toast.success('Character deleted')
      fetchCharacters()
    } catch(err) {
      if (err.data.error === 'CHARACTER_IN_USE') {
        toast.error(`${character.name} is still used on product or banner`)
        return
      }
      toast.error('Fail deleting ' + character.name + ', please try again')
    }
  }

  return (
    <Header title="Character">
      <div className="flex items-center justify-between mb-8 mt-4">
        <input
          type="text"
          className="p-2 w-1/4 border border-gray-300 rounded-lg mr-4"
          placeholder="Search"
          onChange={handleSearch}
        />

        {
          hasCreateCharacterAccess() && (
            <>
              <input
                type="text"
                className="p-2 ml-auto w-1/5 border border-gray-300 rounded-lg mr-4"
                placeholder="Character name"
                value={characterName}
                onChange={e => setCharacterName(e.target.value)}
                disabled={isFetchingCharacter}
              />
              <button
                className="py-2 px-10 bg-gray-700 text-white rounded-full"
                onClick={createCharacter}
                disabled={isFetchingCharacter}
              >
                Create
              </button>
            </>
          )
        }
      </div>

      <table className="w-full">
        <thead className="border border-gray-300 w-full">
          <tr>
            <th width="4%" className="p-4 text-left">
              No
            </th>
            <th className="p-4 text-left">
              Name
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody className="border border-gray-300">
          {
            isFetchingCharacter && <TableLoader />
          }
          {
            !isFetchingCharacter && characters.map((character, idx) => (
              <AttributeTableItem
                key={character.id}
                index={idx}
                number={((paging.page - 1) * paging.size) + idx + 1}
                name={character.name}
                onEdit={newName => onEdit(character.id, newName)}
                onDelete={() => onDelete(character)}
                hasEditAccess={hasUpdateCharacterAccess()}
                hasDeleteAccess={hasDeleteCharacterAccess()}
              />
            ))
          }
        </tbody>
      </table>

      {
        !isFetchingCharacter && !!characters.length && (
          <div className="self-end mt-3">
            <Pagination
              currentPage={paging.page}
              totalPage={paging.totalPage}
              setPage={page => fetchCharacters(page)}
            />
          </div>
        )
      }
    </Header>
  )
}

export default CharacterPage