import React from 'react'
import { numberFormatter, formatDate, toFormattedDate } from '@/utils/common'
import classNames from 'classnames'
import tw from 'tailwind-styled-components'

import TableLoader from '@/components/TableLoader'

const Td = tw.td`p-5 text-sm`

const Table = ({ headers, items, isLoading, onRowClick }) => {
  const traverse = (nestedKey, obj) => {
    const parts = nestedKey.split('.')
    return parts.reduce((acc, curr, idx) => {
      if (acc[curr] !== undefined || acc[curr] !== null) {
        return acc[curr]
      }
      if (idx === (parts.length - 1)) {
        return undefined
      }
      return {}
    }, obj)
  }

  const cell = (item, header) => {
    const content = traverse(header.value, item)

    if (header.slot) {
      return (
        <Td key={header.value}>
          {header.slot(item, header) }
        </Td>
      )
    }

    if (header.currency) {
      return (
        <Td key={header.value}>
          {numberFormatter(content, header.currency)}
        </Td>
      )
    }

    if (header.number) {
      return (
        <Td key={header.value}>
          {numberFormatter(content)}
        </Td>
      )
    }

    if (header.date) {
      if (header.hms) {
        return (
          <Td key={header.value}>
            {toFormattedDate(content)}
          </Td>
        )
      }
      return (
        <Td key={header.value}>
          {formatDate(new Date(content))}
        </Td>
      )
    }

    return (
      <Td key={header.value}>
        {content}
      </Td>
    )
  }

  return (
    <table className="w-full">
      <thead className="border border-gray-300">
        <tr>
          {
            headers.map(header => (
              <th key={header.value} className="p-5 text-left border-b border-gray-200">
                {header.text}
              </th>
            ))
          }
        </tr>
      </thead>
      <tbody className="border border-gray-300">
        {
          isLoading && <TableLoader column={headers.length} /> 
        }
        {
          !isLoading && items.map((item, i) => (
            <tr
              key={i}
              className={classNames({
                'border-b border-gray-200': true,
                'bg-gray-100': i % 2 === 1,
                'cursor-pointer': !!onRowClick
              })}
              onClick={e => onRowClick ? onRowClick(item, e) : e => e}
            >
              {
                headers.map(header => (
                  cell(item, header)
                ))
              }
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

export default Table