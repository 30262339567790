import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { uploadCustomLayoutImage } from '@/store/slices/image'
import toast from 'react-hot-toast'
import { readFileAsDataUrl } from '@/utils/file'
import { ReactSortable } from 'react-sortablejs'
import { FaTrash } from 'react-icons/fa'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'

import FullPageLoader from '@/components/FullPageLoader'
import Modal from '@/components/Modal'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

const CarouselComponent = ({ visibleState, onSubmit, initial }) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [images, setImages] = useState(() => {
    if (initial?.length) {
      return initial.map(i => ({
        ...i,
        preview: i.url
      }))
    }
    return []
  })

  const handleImageChange = async e => {
    e.preventDefault && e.preventDefault()

    const file = e.target.files[0]
    if (!file) {
      return
    }

    const { width, height, src } = await readFileAsDataUrl(file)

    if (width !== 1200 || height !== 600) {
      toast.error('Image must be 600x400 pixel')
      return
    }

    setImages([
      ...images,
      {
        file,
        preview: src
      }
    ])
  }

  const getImageUrls = async () => {
    const urls = []

    for (const image of images) {
      if (image.url && image.filename) {
        urls.push(image)
        continue
      }

      const form = new FormData()
      form.append('files', image.file)
      const { data = [] } = await dispatch(uploadCustomLayoutImage(form)).unwrap()
      const [imgResp] = data

      urls.push({
        filename: imgResp.name,
        url: imgResp.url
      })
    }

    return urls
  }

  const submit = async () => {
    if (images.length < 3) {
      const remainingImageNeeded = 3 - images.length
      toast.error(`Please upload ${remainingImageNeeded} more images`)
      return
    }

    try {
      setIsLoading(true)

      const images = await getImageUrls()

      onSubmit && onSubmit({
        type: 'CAROUSEL',
        images
      })

      visibleState[1](false)

      setImages([])

      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  return (
    <>
      {isLoading && <FullPageLoader />}
      <Modal visibleState={visibleState} width="w-1/2">
        <section className="flex flex-col">
          <div className="font-semibold text-lg">
            Carousel
          </div>

          <hr className="my-3" />

          <section className="mt-2">
            <div className="text-sm text-gray-600 mb-4">
              <div>1. Image must be 1200x600 (WxH) pixel</div>
              <div>2. You must upload min 3 images</div>
            </div>

            <input
              className="mt-2"
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleImageChange}
              onClick={e => {
                e.target.value = ''
              }}
            />

            {
              !!images.length && (
                <div className="my-5">
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={3}
                    modules={[Pagination, Navigation]}
                    pagination={{
                      dynamicBullets: true
                    }}
                    navigation={{
                      enabled: true
                    }}
                    className="p-5"
                  >
                    {
                      images.map((image, i) => (
                        <SwiperSlide key={i} className="shadow-lg rounded-xl">
                          <img
                            src={image.preview}
                            alt="Banner"
                            className="rounded-xl"
                          />
                        </SwiperSlide>
                      ))
                    }
                  </Swiper>
                </div>
              )
            }

            <ReactSortable
              list={images}
              setList={setImages}
              className="mt-4 flex flex-wrap gap-3"
            >
              {
                images.map((image, index) => (
                  <div
                    key={index}
                    className="p-2 border-2 border-dotted border-blue-500 cursor-move flex items-center"
                  >
                    <img
                      src={image.preview}
                      className="w-[400px] h-[200px] mr-2"
                    />
                    <button onClick={() => setImages(images.filter((_, i) => i !== index))}>
                      <FaTrash className="text-blue-500" />
                    </button>
                  </div>
                ))
              }
            </ReactSortable>
          </section>

          <section className="mt-4 self-end">
            <button
              className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-2"
              onClick={() => visibleState[1](false)}
              type="button"
            >
              Cancel
            </button>
            <button
              className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
              onClick={submit}
            >
              Create
            </button>
          </section>
        </section>
      </Modal>
    </>
  )
}

export default CarouselComponent