import React from 'react'
import ProductAvailabilityStatus from '@/components/ProductAvailabilityStatus'
import { numberFormatter } from '@/utils/common'
import { FaTimes } from 'react-icons/fa'

const ProductCard = ({
  id,
  name,
  brand,
  image,
  price,
  originalPrice,
  availability,
  stock,
  hidden,
  closeIconClick
}) => {
  return (
    <div className="relative flex flex-col">
      {
        closeIconClick && (
          <button
            type="button"
            className="absolute right-0 top-0 p-1.5 bg-white opacity-50"
            onClick={() => closeIconClick(id)}
          >
            <FaTimes  />
          </button>
        )
      }
      <img
        src={image}
        className="aspect-square object-contain"
      />

      <div className="p-1.5 flex flex-col w-full">
        <div
          className="font-bold text-sm line-clamp-2 mt-1"
          title={name}
        >
          {name}
        </div>
        <div className="text-sm text-gray-500 mb-1.5 line-clamp-1">
          {brand}
        </div>
        <div className="font-bold text-red-500">
          {numberFormatter(price, 'Rp')}
        </div>

        {
          originalPrice > price && (
            <div className="font-bold text-xs text-gray-500 line-through">
              {numberFormatter(originalPrice, 'Rp')}
            </div>
          )
        }
      </div>

      <div className="mt-1">
        <ProductAvailabilityStatus
          availability={availability}
          stock={stock}
          hidden={hidden}
        />
      </div>
    </div>
  )
}

export default ProductCard