import React, { useState } from 'react'
import config from '@/constant/config'
import { FaTrash, FaEdit } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { deleteBanner } from '@/store/slices/banner'
import toast from 'react-hot-toast'
import classNames from 'classnames'

import EditBannerModal from '@/components/EditBannerModal'
import FullPageLoader from '@/components/FullPageLoader'

const { wording } = config

const BannerItem = ({
  id,
  filePath,
  fileName,
  originalName,
  link,
  onBannerDeleted,
  onBannerUpdated,
  hasUpdateAccess,
  hasDeleteAccess
}) => {
  const dispatch = useDispatch()

  const [visibleEditBannerModal, setVisibleEditBannerModal] = useState(false)
  const [isDeletingBanner, setIsDeletingBanner] = useState(false)

  const doDeleteBanner = async bannerId => {
    try {
      setIsDeletingBanner(true)

      await dispatch(deleteBanner(bannerId)).unwrap()
      toast.success('Banner deleted')
      onBannerDeleted && onBannerDeleted()

      setIsDeletingBanner(false)
    } catch(err) {
      setIsDeletingBanner(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  return (
    <>
      {
        isDeletingBanner && <FullPageLoader />
      }

      {
        visibleEditBannerModal && (
          <EditBannerModal
            visibleState={[visibleEditBannerModal, setVisibleEditBannerModal]}
            bannerId={id}
            onBannerUpdated={onBannerUpdated}
          />
        )
      }

      <div
        className={classNames({
          'py-3 px-3 border-2 border-dotted border-blue-300 rounded flex items-center text-sm': true,
          'cursor-move': hasUpdateAccess
        })}
        style={{ width: '48%' }}
      >
        <img
          src={filePath}
          alt="Banner image"
          className="w-1/6 object-contain aspect-square"
        />

        <div className="mx-5 w-1/2">
          <div className="mb-2">
            <div className="font-bold text-blue-400">
              File name
            </div>
            <div
              className="line-clamp-1"
              title={fileName}
            >
              {fileName}
            </div>
          </div>

          <div>
            <div className="font-bold text-blue-400">
              Original name
            </div>
            <div
              className="line-clamp-1"
              title={originalName}
            >
              {originalName}
            </div>
          </div>
        </div>

        <div>
          <div className="font-bold text-blue-400">
            Link type
          </div>
          <div>
            {wording.banner.linkType[link.type]}
          </div>
        </div>

        <div className="ml-auto flex">
          {
            hasUpdateAccess && (
              <FaEdit
                className="text-blue-400 cursor-pointer mr-5"
                onClick={() => setVisibleEditBannerModal(true)}
              />
            )
          }

          {
            hasDeleteAccess && (
              <FaTrash
                className="text-blue-400 cursor-pointer"
                onClick={() => doDeleteBanner(id)}
              />
            )
          }
        </div>
      </div>
    </>
  )
}

export default BannerItem