import React, { useEffect, useState } from 'react'
import { getSeries } from '@/store/slices/series'
import { useDispatch } from 'react-redux'
import debounce from '@/utils/debouncer'
import toast from 'react-hot-toast'

import Dropdown from '@/components/Dropdown'

const SeriesDropdown = ({ form, error, value, onItemChange, disabled, disposable, useForm = true }) => {
  const dispatch = useDispatch()

  const [series, setSeries] = useState({ data: [], paging: {} })
  const [seriesSearch, setSeriesSearch] = useState(undefined)
  const [isFetchingSeries, setIsFetchingSeries] = useState(false)

  useEffect(() => {
    if (useForm) {
      form.register('series')
    }
  }, [])

  useEffect(() => {
    if (seriesSearch === undefined) {
      return
    }
    debounce(() => fetchSeries(1, false), 200, 'series-search')
  }, [seriesSearch])

  const fetchSeries = async (page, append = true) => {
    try {
      setIsFetchingSeries(true)

      const seriesResponses = await dispatch(getSeries({ page, search: seriesSearch })).unwrap()
      setSeries({
        data: append ? [...series.data, ...seriesResponses.data] : seriesResponses.data,
        paging: seriesResponses.paging
      })

      setIsFetchingSeries(false)
    } catch(err) {
      toast.error('Oops something wrong, please try again')
      setIsFetchingSeries(false)
    }
  }

  const getNextPageSeries = () => {
    if (series.paging.page >= series.paging.totalPage) {
      return
    }

    fetchSeries(series.paging.page + 1)
  }

  const seriesDropdownItems = () => (series.data || []).map(b => ({ key: b.id, value: b.name }))

  const handleOnItemChange = selectedItem => {
    if (useForm) {
      form.setValue('series', selectedItem)
      form.trigger('series')
    }
    onItemChange && onItemChange(selectedItem)
  }

  return (
    <Dropdown
      name="Select series"
      items={seriesDropdownItems()}
      emptyMessage="No Series Yet"
      searchState={[seriesSearch, setSeriesSearch]}
      onDropdownOpen={() => fetchSeries(1, false)}
      isLoading={isFetchingSeries}
      onIntersecting={getNextPageSeries}
      onItemChange={handleOnItemChange}
      error={error}
      value={value}
      disposable={disposable}
      disabled={disabled}
    />
  )
}

export default SeriesDropdown