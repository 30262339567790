import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination as SwiperPagination, Navigation } from 'swiper'

import BaseItem from '@/components/CustomLayout/BaseItem'
import CarouselComponent from '@/components/CustomLayout/CarouselComponent'

const CarouselItem = ({ component, index, onUpdateComponent, onRemoveComponent }) => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      {
        visible && (
          <CarouselComponent
            visibleState={[visible, setVisible]}
            onSubmit={comp => onUpdateComponent(comp, index)}
            initial={component.images}
          />
        )
      }

      <BaseItem
        onUpdateComponent={() => setVisible(true)}
        onRemoveComponent={() => onRemoveComponent(component, index)}
      >
        <Swiper
          spaceBetween={10}
          slidesPerView={3}
          modules={[SwiperPagination, Navigation]}
          pagination={{
            dynamicBullets: true
          }}
          navigation={{
            enabled: true
          }}
          className="p-5"
        >
          {
            component.images.map((image, i) => (
              <SwiperSlide key={i}>
                <img
                  src={image.url}
                  alt="Banner"
                  className="rounded-xl"
                />
              </SwiperSlide>
            ))
          }
        </Swiper>
      </BaseItem>
    </>
  )
}

export default CarouselItem