import React, { useEffect, useState } from 'react'
import { userGrantsSelector } from '@/store/slices/user'
import { useDispatch, useSelector } from 'react-redux'
import { getBanners, updateBannerSequence } from '@/store/slices/banner'
import { ReactSortable } from 'react-sortablejs'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'
import toast from 'react-hot-toast'
import classNames from 'classnames'

import Header from '@/components/Header'
import CreateBannerModal from '@/components/CreateBannerModal'
import BannerItem from '@/components/BannerItem'
import FullPageLoader from '@/components/FullPageLoader'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

const BannerPage = () => {
  const dispatch = useDispatch()

  const grants = useSelector(userGrantsSelector)

  const [banners, setBanners] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isMovingBanner, setIsMovingBanner] = useState(false)
  const [visibleCreateBannerModal, setVisibleCreateBannerModal] = useState(false)

  const fetchBanners = async () => {
    try {
      setIsLoading(true)
  
      const { data } = await dispatch(getBanners()).unwrap()
      setBanners(data)
  
      setIsLoading(false)
    } catch(err) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  useEffect(() => {
    fetchBanners()
  }, [])

  const hasCreateBannerAccess = () => grants.banner?.includes('create')

  const hasUpdateBannerAccess = () => grants.banner?.includes('update')

  const hasDeleteBannerAccess = () => grants.banner?.includes('delete')

  const moveBanner = async e => {
    if (e.oldIndex === e.newIndex) {
      return
    }

    setIsMovingBanner(true)

    const movedBanner = banners[e.oldIndex]
    await dispatch(updateBannerSequence({
      id: movedBanner.id,
      sequence: e.newIndex + 1
    })).unwrap()

    setIsMovingBanner(false)
  }

  return (
    <>
      {
        isMovingBanner && <FullPageLoader />
      }

      {
        visibleCreateBannerModal && (
          <CreateBannerModal
            visibleState={[visibleCreateBannerModal, setVisibleCreateBannerModal]}
            newSequence={banners.length + 1}
            onBannerCreated={fetchBanners}
          />
        )
      }

      <Header title="Banner">

        {
          !banners.length && !isLoading && (
            <div className="flex flex-col justify-center items-center my-10">
              <div className="font-bold text-xl mb-4">
                You don't have any banner yet
              </div>

              {
                hasCreateBannerAccess() && (
                  <div>
                    <button
                      className="py-2 px-10 border rounded-full border-gray-700 bg-gray-700 text-white"
                      onClick={() => setVisibleCreateBannerModal(true)}
                    >
                      Create
                    </button>
                  </div>
                )
              }
            </div>
          )
        }

        {
          !!banners.length && !isLoading && (
            <>
              <div className="my-5">
                <Swiper
                  spaceBetween={10}
                  slidesPerView={3}
                  modules={[Pagination, Navigation]}
                  pagination={{
                    dynamicBullets: true
                  }}
                  navigation={{
                    enabled: true
                  }}
                  className="p-5"
                >
                  {
                    banners.map(banner => (
                      <SwiperSlide key={banner.id} className="shadow-lg rounded-xl">
                        <img
                          src={banner.filePath}
                          alt="Banner"
                          className="rounded-xl"
                        />
                      </SwiperSlide>
                    ))
                  }
                </Swiper>
              </div>

              {
                hasCreateBannerAccess() && (
                  <div className="mb-6">
                    <button
                      className={classNames({
                        'py-2 px-10 border rounded-full': true,
                        'border-gray-700 bg-gray-700 text-white': banners.length < 10,
                        'border-gray-200 bg-gray-200 text-gray-400 cursor-default': banners.length >= 10
                      })}
                      disabled={banners.length >= 10}
                      onClick={() => setVisibleCreateBannerModal(true)}
                    >
                      Create
                    </button>
                  </div>
                )
              }


              {
                banners.length < 3 && (
                  <small className="mb-3 text-gray-500">
                    Your banner won't be visible if it's less than 3
                  </small>
                )
              }

              <ReactSortable
                list={banners}
                setList={setBanners}
                className="flex flex-col gap-4 cursor-default"
                onEnd={moveBanner}
                style={{ height: '500px' }}
                disabled={!hasUpdateBannerAccess()}
              >
                {
                  banners.map(banner => (
                    <BannerItem
                      key={banner.id}
                      id={banner.id}
                      filePath={banner.filePath}
                      fileName={banner.fileName}
                      originalName={banner.originalName}
                      link={banner.link}
                      onBannerDeleted={fetchBanners}
                      onBannerUpdated={fetchBanners}
                      hasUpdateAccess={hasUpdateBannerAccess()}
                      hasDeleteAccess={hasDeleteBannerAccess()}
                    />
                  ))
                }
              </ReactSortable>
            </>
          )
        }

        {
          isLoading && (
            <div className="flex flex-wrap gap-4">
              <div className="animate-pulse bg-gray-300 h-16 rounded-lg" style={{ width: '48%' }} />
              <div className="animate-pulse bg-gray-300 h-16 rounded-lg" style={{ width: '48%' }} />
              <div className="animate-pulse bg-gray-300 h-16 rounded-lg" style={{ width: '48%' }} />
              <div className="animate-pulse bg-gray-300 h-16 rounded-lg" style={{ width: '48%' }} />
              <div className="animate-pulse bg-gray-300 h-16 rounded-lg" style={{ width: '48%' }} />
              <div className="animate-pulse bg-gray-300 h-16 rounded-lg" style={{ width: '48%' }} />
            </div>
          )
        }

      </Header>
    </>
  )
}

export default BannerPage