import React, { useEffect, useState } from 'react'
import config from '@/constant/config'
import DatePicker from 'react-datepicker'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { exportOrder } from '@/store/slices/order'
import { Buffer } from 'buffer'

import Modal from '@/components/Modal'
import Dropdown from '@/components/Dropdown'
import BrandDropdown from '@/components/BrandDropdown'
import RadioButton from '@/components/RadioButton'
import FullPageLoader from '@/components/FullPageLoader'

const ORDER_STATUSES = config.order.status
  .filter(status => status.value !== 'PENDING')
  .map(status => ({
    key: status.value,
    value: status.text
  }))

const ExportOrderModal = ({ visibleState }) => {
  const dispatch = useDispatch()

  const [selectedStatus, setSelectedStatus] = useState({})
  const [selectedBrand, setSelectedBrand] = useState({})
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [useDateRange, setUseDateRange] = useState(false)
  const [isExportingOrder, setIsExportingOrder] = useState(false)

  useEffect(() => {
    if (!visibleState[0]) {
      setSelectedBrand({})
      setSelectedStatus({})
      setStartDate(null)
      setEndDate(null)
      setUseDateRange(false)
    }
  }, [visibleState[0]])

  const handleStatusChange = selected => {
    setSelectedStatus(selected)
  }

  const handleBrandChange = selected => {
    setSelectedBrand(selected)
  }

  const handleRadioChange = value => {
    setUseDateRange(value)
    setEndDate(null)
    setStartDate(null)
  }

  const doExportOrder = async e => {
    try {
      e.preventDefault();

      setIsExportingOrder(true)

      const { value, contentType } = await dispatch(exportOrder({
        status: selectedStatus.key,
        brand: selectedBrand.key,
        startDate: startDate && startDate.getTime(),
        endDate: endDate && endDate.getTime()
      })).unwrap()

      const buff = Buffer.from(value.data)
      const dataUrl = new Blob([buff], { type: contentType })
      const url = URL.createObjectURL(dataUrl)

      const link = document.createElement('a')
      link.href = url
      link.download = 'orders.xlsx'
      link.click();

      visibleState[1](false)
      toast.success('Order exported')

      setIsExportingOrder(false)
    } catch(error) {
      setIsExportingOrder(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  return (
    <>
      {isExportingOrder && <FullPageLoader />}

      <Modal
        visibleState={visibleState}
        width="w-1/2"
      >
        <div className="text-xl font-bold">
          Export Order
        </div>

        <hr className="my-3" />

        <form
          className="grid grid-cols-2 gap-5"
          onSubmit={doExportOrder}
        >
          <div className="flex flex-col">
            <div className="mb-1.5">
              Select status &nbsp;
              <small className="text-gray-500">
                (Optional)
              </small>
            </div>
            <Dropdown
              name="Status"
              items={ORDER_STATUSES}
              onItemChange={handleStatusChange}
              disposable
            />
          </div>

          <div className="flex flex-col">
            <div className="mb-1.5">
              Select brand &nbsp;
              <small className="text-gray-500">
                (Optional)
              </small>
            </div>
            <BrandDropdown
              useForm={false}
              onItemChange={handleBrandChange}
              disposable
            />
          </div>

          <div className="col-span-2 flex flex-col">
            <div className="mb-1.5">
              Select date range
            </div>

            <RadioButton
              id="NONE"
              text="None"
              value={false}
              name="DATE_RANGE"
              checked={useDateRange === false}
              onChange={() => handleRadioChange(false)}
              className="mb-2"
            />
            <RadioButton
              id="USE_DATE_RANGE"
              text="Select start date and end date"
              value={true}
              name="DATE_RANGE"
              checked={useDateRange === true}
              onChange={() => handleRadioChange(true)}
              className="mb-2"
            />

            {
              useDateRange && (
                <div className="flex items-center gap-x-3">
                  <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="w-full text-left p-2 border border-gray-300 rounded-lg"
                    maxDate={new Date()}
                  />

                  <div>
                    -
                  </div>

                  <DatePicker
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="w-full text-left p-2 border border-gray-300 rounded-lg"
                    minDate={startDate}
                    maxDate={new Date()}
                  />
                </div>
              )
            }
          </div>

          <div className="col-span-2 flex">
            <button
              type="button"
              className="ml-auto px-6 py-2.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-3"
              onClick={() => visibleState[1](false)}
            >
              Back
            </button>

            <button
              type="submit"
              className="px-6 py-2.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
            >
              Export
            </button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ExportOrderModal