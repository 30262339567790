import React, { useEffect, useState } from 'react'
import { getProducts } from '@/store/slices/product'
import { useDispatch } from 'react-redux'
import { numberFormatter } from '@/utils/common'
import debounce from '@/utils/debouncer'
import useDidUpdateEffect from '@/hooks/did-update-effect'
import toast from 'react-hot-toast'

import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'
import ProductAvailabilityStatus from '@/components/ProductAvailabilityStatus'

const ProductListModal = ({
  visibleState = [],
  initialProducts = [],
  onSubmit
}) => {
  const dispatch = useDispatch()
  const [visible, setVisible] = visibleState

  const [search, setSearch] = useState('')
  const [productPage, setProductPage] = useState({})
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState(initialProducts)

  useEffect(() => {
    fetchProducts()
  }, [])

  useDidUpdateEffect(() => {
    if (visible) {
      fetchProducts()
    }
  }, [visible, search])

  const fetchProducts = async (page = 1) => {
    try {
      setIsLoading(true)

      const { data, paging } = await dispatch(getProducts({
        page,
        size: 5,
        search
      })).unwrap()
      setProducts(data)
      setProductPage(paging)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const handleSearch = e => {
    debounce(() => setSearch(e.target.value), 300, 'search')
  }

  const handleCheck = (e, selectedProduct) => {
    if (e.target.checked) {
      setSelectedProducts([
        ...selectedProducts,
        selectedProduct
      ])
    } else {
      setSelectedProducts(selectedProducts.filter(product => product.sku !== e.target.value))
    }
  }

  const add = () => {
    onSubmit && onSubmit(selectedProducts)
    setVisible(false)
  }

  return (
    <Modal visibleState={[visible, setVisible]}>
      <section className="flex flex-col">
        <div className="font-semibold">
          Products
        </div>

        <div className="flex items-center justify-between mt-4 mr-4">
          <input
            type="text"
            className="p-2 w-1/2 border border-gray-300 rounded-lg"
            placeholder="Search sku or name"
            onChange={handleSearch}
          />

          <div>
            {
              selectedProducts.length > 0 && (
                <button
                  type="button"
                  className="mr-3 text-sm text-blue-500"
                  onClick={() => setSelectedProducts([])}
                >
                  Clear
                </button>
              )
            }
            <button
              type="button"
              className="px-6 py-2.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer text-sm"
              onClick={add}
            >
              Add
            </button>
          </div>
        </div>

        <div className="mt-3 flex flex-col gap-y-3 mr-4">
          {
            products.map(product => (
              <div
                key={product.sku}
                className="border rounded p-3 flex"
              >
                <img
                  src={product.images[0]}
                  className="aspect-square object-contain w-24"
                />

                <div className="flex flex-col px-3 w-full">
                  <label
                    htmlFor={product.sku}
                    className="font-bold line-clamp-2 cursor-pointer"
                    title={product.displayName}
                  >
                    {product.displayName}
                  </label>
                  <div className="text-sm text-gray-500">
                    {product.brand.name}
                  </div>

                  <div className="flex items-center mt-1">
                    <div className="font-bold mr-1.5 text-red-500">
                      {numberFormatter(product.price.offer, 'Rp')}
                    </div>
                    {
                      product.price.original > product.price.offer && (
                        <div className="font-bold text-xs text-gray-500 line-through">
                          {numberFormatter(product.price.original, 'Rp')}
                        </div>
                      )
                    }
                  </div>
                  <div className="mt-2">
                    <ProductAvailabilityStatus
                      availability={product.availability}
                      stock={product.stock}
                      hidden={product.hidden}
                    />
                  </div>
                </div>

                <input
                  id={product.sku}
                  type="checkbox"
                  value={product.sku}
                  className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  checked={selectedProducts.some(selectedProduct => selectedProduct.sku === product.sku)}
                  onChange={e => handleCheck(e, product)}
                  disabled={selectedProducts.length >= 50 && !selectedProducts.some(selectedProduct => selectedProduct.sku === product.sku)}
                />
              </div>
            ))
          }
        </div>

        {
          !isLoading && !!products.length && (
            <div className="ml-auto mt-3">
              <Pagination
                currentPage={productPage.page}
                totalPage={productPage.totalPage}
                setPage={page => fetchProducts(page)}
              />
            </div>
          )
        }
      </section>
    </Modal>
  )
}

export default ProductListModal