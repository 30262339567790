import classNames from 'classnames'
import React from 'react'

const Header = ({ title, children, slot, freeWidth = false }) => {
  return (
    <div className="p-8">
      <div className="flex items-center w-11/12 2xl:w-10/12 mx-auto">
        <div className="text-2xl font-bold">
          {title}
        </div>
        {slot && slot()}
      </div>

      <div className="h-0.5 w-full bg-gray-800 my-4 rounded-full" />

      <div className={classNames({
        'flex flex-col': true,
        'w-11/12 2xl:w-10/12 mx-auto': !freeWidth
      })}>
        {children}
      </div>
    </div>
  )
}

export default Header