import React from 'react'
import { useDispatch } from 'react-redux'
import { login, setUser } from '@/store/slices/user'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import config from '@/constant/config'
import toast from 'react-hot-toast'
import classNames from 'classnames'

const { path } = config

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required()
})

const LoginPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) })

  const handleLogin = async ({ email, password }) => {
    try {
      const response = await dispatch(login({ email, password })).unwrap()
      dispatch(setUser(response.data))

      const page = searchParams.get('page')
      navigate(page ? window.atob(page) : path.base)
    } catch(err) {
      if (err.status === 401) {
        toast.error('Invalid username or password')
      } else {
        toast.error('Oops something wrong, please try again')
      }
    }
  }

  return (
    <div className="w-full h-screen flex justify-center items-center bg-gray-100">
      <form
        className="w-1/4 p-5 rounded-xl shadow flex flex-col bg-white"
        onSubmit={handleSubmit(handleLogin)}
      >
        <div className="text-2xl font-bold text-center my-5">
          MultiToys Admin
        </div>

        <div className="my-5 flex flex-col">
          <input
            type="text"
            placeholder="Username"
            className={classNames({
              'outline-none border border-gray-300 py-3 px-5 rounded-xl': true,
              'border-red-500': !!errors.email?.message
            })}
            {...register('email')}
          />
          {
            errors.email?.message && (
              <small className="ml-2 text-red-500">
                Invalid email
              </small>
            )
          }
        </div>

        <div className="flex flex-col">
          <input
            type="password"
            placeholder="Password"
            className={classNames({
              'outline-none border border-gray-300 py-3 px-5 rounded-xl': true,
              'border-red-500': !!errors.password?.message
            })}
            {...register('password')}
          />
          {
            errors.password?.message && (
              <small className="ml-2 text-red-500">
                Password must not empty
              </small>
            )
          }
        </div>

        <input
          className="p-3 font-bold bg-gray-800 text-white rounded-full my-5 cursor-pointer"
          type="submit"
          value="Login"
        />
      </form>
    </div>
  )
}

export default LoginPage