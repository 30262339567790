import React, { useState, useEffect } from 'react'
import config from '@/constant/config'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { uploadBannerImage } from '@/store/slices/image'
import { updateBanner, getBanner } from '@/store/slices/banner'
import { getCustomLayoutSlugs } from '@/store/slices/custom-layout'
import { useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { FaTimes } from 'react-icons/fa'
import classNames from 'classnames'

import Modal from '@/components/Modal'
import Dropdown from '@/components/Dropdown'
import CategoryDropdown from '@/components/CategoryDropdown'
import BrandDropdown from '@/components/BrandDropdown'
import SeriesDropdown from '@/components/SeriesDropdown'
import CharacterDropdown from '@/components/CharacterDropdown'
import FullPageLoader from '@/components/FullPageLoader'

const validationSchema = yup.object().shape({
  image: yup.object().shape({
    url: yup.string().url().required(),
    fileName: yup.string().required(),
    originalName: yup.string().required()
  }),
  linkType: yup.string().required(),
  linkValue: yup.lazy(value => Array.isArray(value)
    ? yup.array().of(yup.string()).min(1)
    : yup.string().when('linkType', ([linkType], field) => {
      if (linkType === 'URL') {
        return field.matches(/^(http:\/\/|https:\/\/)/, 'URL_MUST_STARTS_WITH_HTTP')
      }
      return field.required()
    })
  )
})

const EditBannerModal = ({ visibleState, bannerId, onBannerUpdated }) => {
  const dispatch = useDispatch()

  const [isUploadingImage, setIsUploadingImage] = useState(false)
  const [attributes, setAttributes] = useState([])
  const [isUpdatingBanner, setIsUpdatingBanner] = useState(false)
  const [banner, setBanner] = useState()
  const [slugs, setSlugs] = useState([])

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    control,
    reset,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const image = useWatch({
    control,
    name: 'image'
  })

  const linkType = useWatch({
    control,
    name: 'linkType'
  })

  const linkValue = useWatch({
    control,
    name: 'linkValue'
  })

  const fetchBanner = async () => {
    try {
      const { data } = await dispatch(getBanner(bannerId)).unwrap()
      setBanner(data)
    } catch(error) {
      if (error.data.error === 'BANNER_NOT_EXIST') {
        toast.error('Banner not exist')
        visibleState[1](false)
        return
      }
      toast.error('Oops something wrong, please try again')
      visibleState[1](false)
    }
  }

  useEffect(() => {
    register('image')
    register('linkType')
    register('linkValue')

    fetchBanner()
    fetchCustomLayoutSlugs()
  }, [])

  useEffect(() => {
    if (!banner) {
      return
    }

    setValue('image', {
      url: banner.filePath,
      fileName: banner.fileName,
      originalName: banner.originalName
    })
    setValue('linkType', banner.link.type)

    if (Array.isArray(banner.link.value)) {
      setAttributes(banner.link.value.map(attribute => ({ key: attribute.id, value: attribute.name })))
    } else {
      setValue('linkValue', banner.link.value)
    }
  }, [banner])

  useEffect(() => {
    setValue('linkValue', attributes.map(att => att.key))
  }, [attributes])

  const readFile = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        const img = new Image();
        img.onload = () => {
          resolve(img)
        };
        img.src = e.target.result;
      };

      reader.onerror = error => reject(error)
  
      reader.readAsDataURL(file);
    })
  }

  const fetchCustomLayoutSlugs = async () => {
    try {
      const { data } = await dispatch(getCustomLayoutSlugs()).unwrap()
      setSlugs(data.map(d => ({ key: d, value: d })))
    } catch(err) {
      toast.error('Oops something wrong, please try again')
    }
  }

  const handleImageChange = async e => {
    const file = e.target.files[0]
    const { width, height } = await readFile(file)

    if (width !== 1200 && height !== 600) {
      toast.error('Image must be 1200x600 pixel')
      return
    }

    try {
      setIsUploadingImage(true)

      const form = new FormData()
      form.append('image', file)
      const response = await dispatch(uploadBannerImage(form)).unwrap()
      const { data } = response

      setValue('image', {
        fileName: data.name,
        originalName: data.originalName,
        url: data.url
      })
      trigger('image')

      setIsUploadingImage(false)
    } catch(err) {
      setIsUploadingImage(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const handleItemChange = selectedLinkType => {
    setValue('linkType', selectedLinkType.key)
    setAttributes([])
    trigger('linkType')
  }

  const handleAttributeChange = selectedItem => {
    const isExistAttribute = attributes.some(att => att.key === selectedItem.key)
    if (isExistAttribute) {
      return
    }

    if (attributes.length >= 3) {
      toast.error('You can select maximum 3 item')
      return
    }

    setAttributes(prev => [...prev, selectedItem])
  }

  const removeAttribute = key => {
    setAttributes(prev => prev.filter(p => p.key !== key))
  }

  const close = () => {
    visibleState[1](false)
    reset()
    setAttributes([])
  }

  const doUpdateBanner = async data => {
    try {
      setIsUpdatingBanner(true)

      const body = {
        filePath: data.image.url,
        fileName: data.image.fileName,
        originalName: data.image.originalName,
        link: {
          type: data.linkType,
          value: data.linkValue
        }
      }
      await dispatch(updateBanner({ id: banner.id, body })).unwrap()

      setIsUpdatingBanner(false)
      close()
      onBannerUpdated && onBannerUpdated()
    } catch(err) {
      setIsUpdatingBanner(false)

      if (err.data.error === 'EXCEED_MAX_BANNER') {
        toast.error('You already have 10 banner')
        return
      }
      toast.error('Oops something wrong, please try again')
    }
  }

  const handleSlugChange = item => {
    setValue('linkValue', item.key)
  }

  return (
    <>
      {isUpdatingBanner && <FullPageLoader />}

      <Modal
        visibleState={visibleState}
        width="lg:w-4/6 2xl:w-1/2"
      >
        <form
          className="flex flex-col"
          onSubmit={handleSubmit(doUpdateBanner)}
        >
          <div className="text-xl font-bold">
            Update Banner
          </div>

          <hr className="my-3" />

          <section className="flex justify-between gap-x-5">
            <div className="w-1/2">
              <div className="text-gray-400">
                Image must be 1200x600 pixel
              </div>

              <div className="flex flex-col">
                <input
                  className="mt-2"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={handleImageChange}
                />

                {
                  isUploadingImage && (
                    <div className="animate-pulse bg-gray-300 rounded-lg my-4 w-full h-[200px]" />
                  )
                }

                {
                  !!image && !isUploadingImage && (
                    <img
                      src={image.url}
                      className="object-contain my-4"
                      style={{ width: '400px', height: '200px' }}
                    />
                  )
                }

                {
                  !!errors.image && !!errors.image.url && (
                    <small className="mt-2 text-red-500">
                      Upload image
                    </small>
                  )
                }
              </div>
            </div>

            <div className="w-1/2">
              {
                !!banner?.link && (
                  <div className="mb-3">
                    <Dropdown
                      name="Link Type"
                      items={config.banner.linkType}
                      onItemChange={handleItemChange}
                      error={!!errors.linkType}
                      value={config.banner.linkType.find(linkType => linkType.key === banner.link.type)}
                    />
                    {
                      !!errors.linkType && (
                        <small className="ml-2 text-red-500">
                          Select link type
                        </small>
                      )
                    }
                  </div>
                )
              }

              {
                (linkType === 'SEARCH' || linkType === 'URL') && (
                  <div>
                    <input
                      type="text"
                      placeholder="Value"
                      className={classNames({
                        'w-full p-2 border border-gray-300 rounded-lg': true,
                        'border-red-500': !!errors.linkValue
                      })}
                      {...register('linkValue')}
                    />
                    {
                      !!errors.linkValue && (
                        <small className="text-red-500">
                          Must start with http:// or https://
                        </small>
                      )
                    }
                  </div>
                )
              }

              {
                linkType === 'CUSTOM_LAYOUT' && (
                  <Dropdown
                    name="Select custom layout"
                    items={slugs}
                    emptyMessage="No Custom Layout Yet"
                    onItemChange={handleSlugChange}
                    error={!!errors.linkValue}
                    value={linkValue && !Array.isArray(linkValue) ? { key: linkValue, value: linkValue } : undefined}
                  />
                )
              }

              {
                linkType === 'CATEGORY' && (
                  <div>
                    <CategoryDropdown
                      form={{ register, setValue, trigger }}
                      error={!!errors.linkValue}
                      useForm={false}
                      onItemChange={handleAttributeChange}
                    />
                    {
                      !!errors.linkValue && (
                        <small className="text-red-500">
                          Select category
                        </small>
                      )
                    }
                  </div>
                )
              }

              {
                linkType === 'BRAND' && (
                  <div>
                    <BrandDropdown
                      form={{ register, setValue, trigger }}
                      error={!!errors.linkValue}
                      useForm={false}
                      onItemChange={handleAttributeChange}
                    />
                    {
                      !!errors.linkValue && (
                        <small className="text-red-500">
                          Select brand
                        </small>
                      )
                    }
                  </div>
                )
              }

              {
                linkType === 'SERIES' && (
                  <div>
                    <SeriesDropdown
                      form={{ register, setValue, trigger }}
                      error={!!errors.linkValue}
                      useForm={false}
                      onItemChange={handleAttributeChange}
                    />
                    {
                      !!errors.linkValue && (
                        <small className="text-red-500">
                          Select series
                        </small>
                      )
                    }
                  </div>
                )
              }

              {
                linkType === 'CHARACTER' && (
                  <div>
                    <CharacterDropdown
                      form={{ register, setValue, trigger }}
                      error={!!errors.linkValue}
                      useForm={false}
                      onItemChange={handleAttributeChange}
                    />
                    {
                      !!errors.linkValue && (
                        <small className="text-red-500">
                          Select character
                        </small>
                      )
                    }
                  </div>
                )
              }

              {
                !!attributes.length && (
                  <div className="flex flex-wrap gap-2 mt-4">
                    {
                      attributes.map(attribute => (
                        <div
                          key={attribute.key}
                          className="px-4 py-1 rounded-full bg-gray-400 text-white flex items-center"
                        >
                          <div className="mr-3">
                            {attribute.value}
                          </div>
                          <FaTimes
                            className="mt-0.5 cursor-pointer"
                            onClick={() => removeAttribute(attribute.key)}
                          />
                        </div>
                      ))
                    }
                  </div>
                )
              }
            </div>
          </section>

          <div className="self-end mt-4">
            <button
              className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-2"
              onClick={close}
              type="button"
            >
              Cancel
            </button>
            <button
              className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
              type="submit"
            >
              Update
            </button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default EditBannerModal