import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import toast from 'react-hot-toast'
import config from '@/constant/config'
import classNames from 'classnames'
import { useNavigate, useParams } from 'react-router-dom'
import { FiPlus } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { updateFeaturedProduct, getFeaturedProductDetail } from '@/store/slices/featured-product'
import Header from '@/components/Header'
import FullPageLoader from '@/components/FullPageLoader'
import Switch from '@/components/Switch'
import ProductCard from '@/components/ProductCard'
import ProductListModal from '@/components/ProductListModal'
import Modal from '@/components/Modal'

const validationSchema = yup.object().shape({
  title: yup.string().required(),
  products: yup.array().min(1),
  hidden: yup.boolean()
})

const EditFeaturedProductPage = () => {
  const { id } = useParams()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [products, setProducts] = useState([])

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    trigger,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const [visibleProductModal, setVisibleProductModal] = useState(false)
  const [visibleUpdateConfirmationModal, setVisibleUpdateConfirmationModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    fetchFeaturedProduct()
  }, [])

  const fetchFeaturedProduct = async () => {
    try {
      setIsLoading(true)
      const { data } = await dispatch(getFeaturedProductDetail(id)).unwrap()
      const { title, hidden, products } = data
      reset({
        title,
        hidden,
        products: products.map(p => p.sku)
      })
      setProducts(products)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const doUpdateFeaturedProduct = async () => {
    try {
      setIsLoading(true)
      const { title, hidden = false, products } = getValues()
      await dispatch(updateFeaturedProduct({
        id,
        body: {
          title,
          hidden,
          products
        }
      }))
      toast.success('Featured product successfully updated!')
      setIsLoading(false)
      navigate(config.path.content.featuredProduct)
    } catch (err) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const handleSelectedSkus = products => {
    setValue('products', products.map(p => p.sku))
    setProducts(products)
    trigger('products')
  }

  const removeProduct = removedSku => {
    setValue('products', getValues('products').filter(sku => sku !== removedSku))
    setProducts(products.filter(p => p.sku !== removedSku))
  }

  return (
    <Header title="Edit Featured Product">
      {isLoading && <FullPageLoader />}

      {
        visibleUpdateConfirmationModal && (
          <Modal visibleState={[visibleUpdateConfirmationModal, setVisibleUpdateConfirmationModal]}>
            <section className="flex flex-col">
              <div className="font-bold text-xl mb-2">
                Confirmation
              </div>
              <div>
                Are you sure to update this featured product?
              </div>
              <div className="self-end mt-4">
                <button
                  type="button"
                  onClick={() => setVisibleUpdateConfirmationModal(false)}
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-2"
                >
                  Cancel
                </button>
                <input
                  type="submit"
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
                  onClick={doUpdateFeaturedProduct}
                  value="Update"
                />
              </div>
            </section>
          </Modal>
        )
      }

      {
        visibleProductModal && (
          <ProductListModal
            visibleState={[visibleProductModal, setVisibleProductModal]}
            initialProducts={products}
            onSubmit={handleSelectedSkus}
          />
        )
      }

      <form onSubmit={handleSubmit(() => setVisibleUpdateConfirmationModal(true))}>
        <section className="flex gap-x-6">
          <div className="w-1/2">
            <div className="font-semibold mb-1">
              Title
            </div>
            <input
              type="text"
              className={classNames({
                'w-full p-2 border border-gray-300 rounded-lg': true,
                'border-red-500': !!errors.title
              })}
              {...register('title')}
            />
            {
              errors.title && (
                <small className="text-red-500">
                  Title must not empty
                </small>
              )
            }
          </div>

          <div className="w-1/2">
            <div className="font-semibold mb-1">
              Hidden
            </div>
            <Controller
              name="hidden"
              control={control}
              render={({ field }) => <Switch checked={getValues('hidden')} {...field} />}
            />
          </div>
        </section>

        <section className="mt-4">
          <div className="font-semibold">
            Products
          </div>
          {
            errors.products && (
              <small className="text-red-500">
                You must select at least 1 product
              </small>
            )
          }

          <div className="mt-1 grid w-full grid-cols-10 gap-6">
            {
              products.map(product => (
                <ProductCard
                  key={product.sku}
                  id={product.sku}
                  name={product.displayName}
                  brand={product.brand.name}
                  image={product.images[0]}
                  price={product.price.offer}
                  originalPrice={product.price.original}
                  availability={product.availability}
                  stock={product.stock}
                  hidden={product.hidden}
                  closeIconClick={removeProduct}
                />
              ))
            }

            {
              products.length < 20 && (
                <div
                  className={classNames({
                    'border border-dotted border-blue-500 w-[12rem] min-h-[12rem] flex flex-col items-center justify-center cursor-pointer': true,
                    'border-red-500': !!errors.products
                  })}
                  onClick={() => setVisibleProductModal(true)}
                >
                  <FiPlus className="text-blue-500 text-3xl" />
                </div>
              )
            }
          </div>
        </section>

        <section className="flex mt-4 gap-x-3 justify-end">
          <button
            type="button"
            className="px-6 py-2.5 rounded-full border border-gray-800 bg-white text-gray-800 cursor-pointer"
            onClick={() => navigate(config.path.content.featuredProduct)}
          >
            Back
          </button>
          <input
            type="submit"
            className="px-6 py-2.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
            value="Update"
          />
        </section>
      </form>
    </Header>
  )
}

export default EditFeaturedProductPage