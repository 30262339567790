import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getNewsletters = createAsyncThunk(
  'newsletter/getNewsletters',
  async (page, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.newsletter, {
        params: {
          page
        }
      })
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const getNewsletterDetail = createAsyncThunk(
  'newsletter/getNewsletterDetail',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.newsletterDetail(id))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const sendNewsletter = createAsyncThunk(
  'newsletter/sendNewsletter',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.newsletter, body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const getSubscribers = createAsyncThunk(
  'newsletter/getSubscribers',
  async (page, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.subscriber, {
        params: {
          page
        }
      })
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const deleteSubscriber = createAsyncThunk(
  'newsletter/deleteSubscriber',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.delete(api.subscriber , {
        data: {
          email
        }
      })
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const newsletterSlice = createSlice({
  name: 'newsletter',
  initialState: {},
  reducers: {}
})

export default newsletterSlice.reducer