const API_PREFIX = '/backend/dashboard'

export default {
  path: {
    base: '/',
    attribute: {
      base: '/attribute',
      category: '/attribute/category',
      brand: '/attribute/brand',
      character: '/attribute/character',
      series: '/attribute/series'
    },
    product: '/product',
    productCreate: '/product/create',
    productEdit: '/product/edit/:sku',
    login: '/login',
    user: '/user',
    tools: {
      base: '/tools',
      imageUploader: '/tools/image-uploader'
    },
    engagement: {
      base: '/engagement',
      banner: '/engagement/banner',
      voucher: '/engagement/voucher',
      voucherDetail: '/engagement/voucher/:voucherId',
      voucherCreate: '/engagement/voucher/create',
      newsletter: '/engagement/newsletter',
      newsletterCreate: '/engagement/newsletter/create',
      newsletterDetail: '/engagement/newsletter/detail/:id'
    },
    accessibility: {
      base: '/accessibility',
      user: '/accessibility/user',
      userCreate: '/accessibility/user/create',
      userEdit: '/accessibility/user/:userId',
      role: '/accessibility/role',
      roleCreate: '/accessibility/role/create',
      roleEdit: '/accessibility/role/:roleId'
    },
    order: {
      base: '/order',
      detail: '/order/:orderId'
    },
    content: {
      base: '/content',
      homepageProduct: '/content/homepage-product',
      featuredProduct: '/content/featured-product',
      featuredProductCreate: '/content/featured-product/create',
      featuredProductEdit: '/content/featured-product/:id',
      storeLocation: '/content/store-location',
      storeLocationCreate: '/content/store-location/create',
      storeLocationEdit: '/content/store-location/:id',
      customLayout: '/content/custom-layout',
      customLayoutCreate: '/content/custom-layout/create',
      customLayoutEdit: '/content/custom-layout/:id',
    }
  },
  api: {
    category: API_PREFIX + '/category',
    categoryDetail(categoryId) {
      return `${API_PREFIX}/category/${categoryId}`
    },
    brand: API_PREFIX + '/brand',
    brandDetail(brandId) {
      return `${API_PREFIX}/brand/${brandId}`
    },
    character: API_PREFIX + '/character',
    characterDetail(characterId) {
      return `${API_PREFIX}/character/${characterId}`
    },
    series: API_PREFIX + '/series',
    seriesDetail(seriesId) {
      return `${API_PREFIX}/series/${seriesId}`
    },
    user: API_PREFIX + '/user',
    userDetail(email) {
      return `${API_PREFIX}/user/${email}`
    },
    product: API_PREFIX + '/product',
    productDetail(sku) {
      return `${API_PREFIX}/product/${sku}`
    },
    productSkus: API_PREFIX + '/product/skus',
    hideProduct(sku) {
      return `${API_PREFIX}/product/${sku}/_hide`
    },
    unhideProduct(sku) {
      return `${API_PREFIX}/product/${sku}/_unhide`
    },
    bulkUploadTemplate: API_PREFIX + '/product/bulk/template',
    bulkUploadStatus(id) {
      return `${API_PREFIX}/product/bulk/status/${id}`
    },
    bulkUpload: API_PREFIX + '/product/bulk',
    login: API_PREFIX + '/user/_login',
    loginInfo: API_PREFIX + '/user/_login/info',
    logout: API_PREFIX + '/user/_logout',
    changePassword: API_PREFIX + '/user/_change-password',
    changeName(email) {
      return `${API_PREFIX}/user/${email}/_change-name`
    },
    productImage: API_PREFIX + '/image/product',
    bannerImage: API_PREFIX + '/image/banner',
    banner: API_PREFIX + '/banner',
    bannerDetail(id) {
      return `${API_PREFIX}/banner/${id}`
    },
    bannerSequence(id) {
      return `${API_PREFIX}/banner/${id}/sequence`
    },
    voucher: API_PREFIX + '/voucher',
    voucherDetail(id) {
      return `${API_PREFIX}/voucher/${id}`
    },
    voucherTerminate(id) {
      return `${API_PREFIX}/voucher/${id}/_terminate`
    },
    role: API_PREFIX + '/role',
    roleDetail(name) {
      return `${API_PREFIX}/role/${name}`
    },
    order: `${API_PREFIX}/order`,
    orderDetail(id) {
      return `${API_PREFIX}/order/${id}`
    },
    orderReadyToShip(orderId, orderItemId) {
      return `${API_PREFIX}/order/update/${orderId}/${orderItemId}/_ready-to-ship`
    },
    orderConfirmed(orderId, orderItemId) {
      return `${API_PREFIX}/order/update/${orderId}/${orderItemId}/_confirmed`
    },
    orderCancel: '/backend/dashboard/order/update/_cancel',
    orderExport: '/backend/dashboard/order/_export',
    orderPriceAdjustment: '/backend/dashboard/order/price/adjustment',
    shipping: {
      createOrder: `${API_PREFIX}/shipping/_create-order`
    },
    config: {
      homepageProduct: API_PREFIX + '/config/homepage-product'
    },
    subscriber: API_PREFIX + '/subscriber',
    newsletter: API_PREFIX + '/newsletter',
    newsletterDetail(id) {
      return `${API_PREFIX}/newsletter/${id}`
    },
    featuredProduct: API_PREFIX + '/featured-product',
    featuredProductDetail(id) {
      return `${API_PREFIX}/featured-product/${id}`
    },
    featuredProductSequence(id) {
      return `${API_PREFIX}/featured-product/${id}/sequence`
    },
    storeLocation: API_PREFIX + '/store-location',
    storeLocationDetail(id) {
      return `${API_PREFIX}/store-location/${id}`
    },
    storeLocationImage: API_PREFIX + '/image/store-location',
    customLayoutImage: API_PREFIX + '/image/custom-layout',
    image: API_PREFIX + '/image',
    customLayout: API_PREFIX + '/custom-layout',
    customLayoutDetail(id) {
      return `${API_PREFIX}/custom-layout/${id}`
    },
    customLayoutSlug : API_PREFIX + '/custom-layout/slug',
  },
  wording: {
    product: {
      status: {
        DRAFT: 'Draft',
        PUBLISHED: 'Published'
      },
      availability: {
        READY_STOCK: 'Ready Stock',
        PREORDER: 'Pre-order',
        LATE_PREORDER: 'Late Pre-order',
        WAITING_LIST: 'Waiting List'
      }
    },
    banner: {
      linkType: {
        SEARCH: 'Search',
        CATEGORY: 'Category',
        BRAND: 'Brand',
        SERIES: 'Series',
        CHARACTER: 'Character',
        URL: 'Url',
        CUSTOM_LAYOUT: 'Custom Layout',
        NONE: 'None'
      }
    },
    voucher: {
      status: {
        ACTIVE: 'Active',
        UPCOMING: 'Upcoming',
        PASSED: 'Passed',
        TERMINATED: 'Terminated'
      }
    }
  },
  product: {
    status: [
      {
        key: 'DRAFT',
        value: 'Draft'
      },
      {
        key: 'PUBLISHED',
        value: 'Published'
      }
    ],
    availability: [
      {
        key: 'READY_STOCK',
        value: 'Ready Stock'
      },
      {
        key: 'PREORDER',
        value: 'Pre-order'
      },
      {
        key: 'LATE_PREORDER',
        value: 'Late Pre-order'
      },
      {
        key: 'WAITING_LIST',
        value: 'Waiting List'
      }
    ],
    listPageFilter: [
      {
        text: 'All',
        value: 'ALL'
      },
      {
        text: 'Draft',
        value: 'DRAFT',
        field: 'status'
      },
      {
        text: 'Published',
        value: 'PUBLISHED',
        field: 'status'
      },
      {
        text: 'Ready Stock',
        value: 'READY_STOCK',
        field: 'availability'
      },
      {
        text: 'Pre-order',
        value: 'PREORDER',
        field: 'availability'
      },
      {
        text: 'Late Pre-order',
        value: 'LATE_PREORDER',
        field: 'availability'
      },
      {
        text: 'Waiting List',
        value: 'WAITING_LIST',
        field: 'availability'
      }
    ]
  },
  banner: {
    linkType:  [
      {
        key: 'SEARCH',
        value: 'Search'
      },
      {
        key: 'URL',
        value: 'Url'
      },
      {
        key: 'CATEGORY',
        value: 'Category'
      },
      {
        key: 'BRAND',
        value: 'Brand'
      },
      {
        key: 'SERIES',
        value: 'Series'
      },
      {
        key: 'CHARACTER',
        value: 'Character'
      },
      {
        key: 'CUSTOM_LAYOUT',
        value: 'Custom Layout'
      },
      {
        key: 'NONE',
        value: 'None'
      }
    ]
  },
  voucher: {
    type: [
      {
        key: 'AMOUNT_OFF',
        value: 'Amount Off'
      },
      {
        key: 'PERCENTAGE',
        value: 'Percentage'
      }
    ]
  },
  role: {
    resource: {
      CATEGORY: 'category',
      BRAND: 'brand',
      CHARACTER: 'character',
      SERIES: 'series',
      PRODUCT: 'product',
      BANNER: 'banner',
      USER: 'user',
      VOUCHER: 'voucher',
      ROLE: 'role',
      ORDER: 'order',
      HOMEPAGE_PRODUCT: 'homepage_product',
      NEWSLETTER: 'newsletter',
      FEATURED_PRODUCT: 'featured_product',
      STORE_LOCATION: 'store_location',
      CUSTOM_LAYOUT: 'custom_layout'
    }
  },
  order: {
    status: [
      {
        text: 'Pending',
        value: 'PENDING'
      },
      {
        text: 'Processed',
        value: 'PROCESSED'
      },
      {
        text: 'Confirmed',
        value: 'CONFIRMED'
      },
      {
        text: 'Ready to Ship',
        value: 'READY_TO_SHIP'
      },
      {
        text: 'Payment Settled',
        value: 'PAYMENT_SETTLED'
      },
      {
        text: 'In Delivery',
        value: 'IN_DELIVERY'
      },
      {
        text: 'Success',
        value: 'SUCCESS'
      },
      {
        text: 'Failed',
        value: 'FAILED'
      },
      {
        text: 'Canceled',
        value: 'CANCELED'
      }
    ]
  }
}
