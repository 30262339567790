import React, { useEffect, useState } from 'react'
import {
  getCategories,
  saveCategory,
  updateCategory,
  deleteCategory
} from '@/store/slices/category'
import { userGrantsSelector } from '@/store/slices/user'
import { useDispatch, useSelector } from 'react-redux'
import debounce from '@/utils/debouncer'
import toast from 'react-hot-toast'

import Header from '@/components/Header'
import Pagination from '@/components/Pagination'
import AttributeTableItem from '@/components/AttributeTableItem'
import TableLoader from '@/components/TableLoader'

const CategoryPage = () => {
  const dispatch = useDispatch()

  const grants = useSelector(userGrantsSelector)

  const [categories, setCategories] = useState([])
  const [paging, setPaging] = useState({})
  const [search, setSearch] = useState('')
  const [categoryName, setCategoryName] = useState('')
  const [isFetchingCategory, setIsFetchingCategory] = useState(false)

  useEffect(() => {
    fetchCategories()
  }, [search])

  const fetchCategories = async (page = 1) => {
    try {
      setIsFetchingCategory(true)

      const categoriesResponse = await dispatch(getCategories({ page, search })).unwrap()
      setCategories(categoriesResponse.data)
      setPaging(categoriesResponse.paging)

      setIsFetchingCategory(false)
    } catch(err) {
      toast.error('Oops something wrong, please try again')
      setIsFetchingCategory(false)
    }
  }

  const hasCreateCategoryAccess = () => grants.category?.includes('create')

  const hasUpdateCategoryAccess = () => grants.category?.includes('update')

  const hasDeleteCategoryAccess = () => grants.category?.includes('delete')

  const handleSearch = e => {
    debounce(() => setSearch(e.target.value), 300, 'search')
  }

  const createCategory = async () => {
    if (categoryName.length < 3) {
      toast.error('Category name must have minimal 3 letter\'s')
      return
    }

    try {
      await dispatch(saveCategory({ name: categoryName })).unwrap()
      toast.success('Success creating ' + categoryName)
      setCategoryName('')
      fetchCategories()
    } catch(err) {
      toast.error('Fail creating ' + categoryName + ', please try again')
    }
  }

  const onEdit = async (categoryId, name) => {
    if (name.length < 3) {
      toast.error('Category name must have minimal 3 letter\'s')
      return
    }

    try {
      await dispatch(updateCategory({ categoryId, body: { name } })).unwrap()
      toast.success('Category edited')
      fetchCategories()
    } catch(err) {
      toast.error('Fail editing ' + categoryName + ', please try again')
    }
  }

  const onDelete = async category => {
    try {
      await dispatch(deleteCategory(category.id)).unwrap()
      toast.success('Category deleted')
      fetchCategories()
    } catch(err) {
      if (err.data.error === 'CATEGORY_IN_USE') {
        toast.error(`${category.name} is still used on product or banner`)
        return
      }
      toast.error('Fail deleting ' + category.name + ', please try again')
    }
  }

  return (
    <Header title="Categories">
      <div className="flex items-center justify-between mb-8 mt-4">
        <input
          type="text"
          className="p-2 w-1/4 border border-gray-300 rounded-lg mr-4"
          placeholder="Search"
          onChange={handleSearch}
        />

        {
          hasCreateCategoryAccess() && (
            <>
              <input
                type="text"
                className="p-2 ml-auto w-1/5 border border-gray-300 rounded-lg mr-4"
                placeholder="Category name"
                value={categoryName}
                onChange={e => setCategoryName(e.target.value)}
                disabled={isFetchingCategory}
              />
              <button
                className="py-2 px-10 bg-gray-700 text-white rounded-full"
                onClick={createCategory}
                disabled={isFetchingCategory}
              >
                Create
              </button>
            </>
          )
        }
      </div>

      <table className="w-full">
        <thead className="border border-gray-300 w-full">
          <tr>
            <th width="4%" className="p-4 text-left">
              No
            </th>
            <th className="p-4 text-left">
              Name
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody className="border border-gray-300">
          {
            isFetchingCategory && <TableLoader />
          }
          {
            !isFetchingCategory && categories.map((category, idx) => (
              <AttributeTableItem
                key={category.id}
                index={idx}
                number={((paging.page - 1) * paging.size) + idx + 1}
                name={category.name}
                onEdit={newName => onEdit(category.id, newName)}
                onDelete={() => onDelete(category)}
                hasEditAccess={hasUpdateCategoryAccess()}
                hasDeleteAccess={hasDeleteCategoryAccess()}
              />
            ))
          }
        </tbody>
      </table>

      {
        !isFetchingCategory && !!categories.length && (
          <div className="self-end mt-3">
            <Pagination
              currentPage={paging.page}
              totalPage={paging.totalPage}
              setPage={page => fetchCategories(page)}
            />
          </div>
        )
      }
    </Header>
  )
}

export default CategoryPage