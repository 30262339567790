import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import toast from 'react-hot-toast'
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { sendNewsletter } from '@/store/slices/newsletter'
import classNames from 'classnames'
import config from '@/constant/config'

import Header from '@/components/Header'
import FullPageLoader from '@/components/FullPageLoader'
import Modal from '@/components/Modal'

const validationSchema = yup.object().shape({
  subject: yup.string().required(),
  content: yup.object().test('validContent', 'Content is a required field', content => content?.blocks?.length && content?.blocks[0]?.text !== '')
})

const CreateNewsletterPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const [visibleSendConfirmationModal, setVisibleSendConfirmationModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setValue('content', convertToRaw(editorState.getCurrentContent()))
  }, [editorState])

  const doSendNewsletter = async () => {
    try {
      setIsLoading(true)

      const { subject, content } = getValues()
      await dispatch(sendNewsletter({
        subject,
        content: draftToHtml(content)
      })).unwrap()

      setVisibleSendConfirmationModal(false)
      setIsLoading(false)

      toast.success('Sending newsletter to subscribers')
      navigate(config.path.engagement.newsletter)
    } catch (err) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  return (
    <Header title="Create Newsletter">
      {isLoading && <FullPageLoader />}

      {
        visibleSendConfirmationModal && (
          <Modal visibleState={[visibleSendConfirmationModal, setVisibleSendConfirmationModal]}>
            <div className="flex flex-col">
              <div className="text-xl font-bold mb-2">
                Confirmation
              </div>
              <div>
                This newsletter will be send to all subscribers
              </div>
              <div className="self-end mt-4">
                <button
                  type="button"
                  onClick={() => setVisibleSendConfirmationModal(false)}
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-2"
                >
                  Cancel
                </button>
                <input
                  type="submit"
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
                  onClick={doSendNewsletter}
                  value="Send"
                />
              </div>
            </div>
          </Modal>
        )
      }

      <form
        onSubmit={handleSubmit(() => setVisibleSendConfirmationModal(true))}
        className="flex flex-col"
      >
        <div className="font-semibold mb-1 text-sm">
          Subject
        </div>
        <input
          type="text"
          className={classNames({
            'w-1/2 p-2 border border-gray-300 rounded-lg': true,
            'border-red-500': !!errors.subject
          })}
          {...register('subject')}
        />
        {
          errors.subject && (
            <small className="text-red-500">
              Subject must not empty
            </small>
          )
        }

        <div className="mt-4 font-semibold mb-1 text-sm">
          Content
        </div>

        <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          editorClassName={['border', 'px-2', !!errors.content ? 'border-red-500' : 'border-gray-100'].join(' ')}
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'emoji', 'image', 'history'],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough']
            },
            list: {
              options: ['unordered', 'ordered']
            },
            blockType: {
              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
            }
          }}
        />
        {
          !!errors.content && (
            <small className="text-red-500">
              Content must not empty
            </small>
          )
        }

        <div className="flex mt-6 ml-auto">
          <button
            type="button"
            className="px-6 py-2.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-3"
            onClick={() => navigate(config.path.engagement.newsletter)}
          >
            Back
          </button>

          <button
            type="submit"
            className="px-6 py-2.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
          >
            Send
          </button>
        </div>
      </form>
    </Header>
  )
}

export default CreateNewsletterPage