import React, { useEffect, useState } from 'react'
import { getBrands } from '@/store/slices/brand'
import { useDispatch } from 'react-redux'
import debounce from '@/utils/debouncer'
import toast from 'react-hot-toast'

import Dropdown from '@/components/Dropdown'

const BrandDropdown = ({ form, error, value, onItemChange, disabled, disposable, useForm = true }) => {
  const dispatch = useDispatch()

  const [brands, setBrands] = useState({ data: [], paging: {} })
  const [brandSearch, setBrandSearch] = useState(undefined)
  const [isFetchingBrand, setIsFetchingBrand] = useState(false)

  useEffect(() => {
    if (useForm) {
      form.register('brand')
    }
  }, [])

  useEffect(() => {
    if (brandSearch === undefined) {
      return
    }
    debounce(() => fetchBrand(1, false), 200, 'brand-search')
  }, [brandSearch])

  const fetchBrand = async (page, append = true) => {
    try {
      setIsFetchingBrand(true)

      const brandResponses = await dispatch(getBrands({ page, search: brandSearch })).unwrap()
      setBrands({
        data: append ? [...brands.data, ...brandResponses.data] : brandResponses.data,
        paging: brandResponses.paging
      })

      setIsFetchingBrand(false)
    } catch(err) {
      toast.error('Oops something wrong, please try again')
      setIsFetchingBrand(false)
    }
  }

  const getNextPageBrand = () => {
    if (brands.paging.page >= brands.paging.totalPage) {
      return
    }

    fetchBrand(brands.paging.page + 1)
  }

  const brandDropdownItems = () => (brands.data || []).map(b => ({ key: b.id, value: b.name }))

  const handleOnItemChange = selectedItem => {
    if (useForm) {
      form.setValue('brand', selectedItem)
      form.trigger('brand')
    }
    onItemChange && onItemChange(selectedItem)
  }

  return (
    <Dropdown
      name="Select brand"
      items={brandDropdownItems()}
      emptyMessage="No Brands Yet"
      searchState={[brandSearch, setBrandSearch]}
      onDropdownOpen={() => fetchBrand(1, false)}
      isLoading={isFetchingBrand}
      onIntersecting={getNextPageBrand}
      onItemChange={handleOnItemChange}
      error={error}
      value={value}
      disposable={disposable}
      disabled={disabled}
    />
  )
}

export default BrandDropdown