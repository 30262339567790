import React, { useState } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { userSelector } from '@/store/slices/user'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { changePassword, changeName, getUserDetail, setUser } from '@/store/slices/user'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import toast from 'react-hot-toast'

import Header from '@/components/Header'
import FullPageLoader from '@/components/FullPageLoader'

const editUserValidationSchema = yup.object().shape({
  name: yup.string().required().min(3)
})

const changePasswordValidationSchema = yup.object().shape({
  oldPassword: yup.string().required().min(3),
  newPassword: yup.string().required().min(3)
})

const EditUserPage = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(userSelector)
  const [isEditingUser, setIsEditingUser] = useState(false)
  const [isChangingPassword, setIsChangingPassword] = useState(false)

  const editUserForm = useForm({
    resolver: yupResolver(editUserValidationSchema),
    defaultValues: {
      name: user.name
    }
  })

  const changePasswordForm = useForm({
    resolver: yupResolver(changePasswordValidationSchema)
  })

  const doEditUser = async ({ name }) => {
    try {
      setIsEditingUser(true)

      await dispatch(changeName({
        email: user.email,
        body: { name }
      })).unwrap()

      setIsEditingUser(false)

      const response = await dispatch(getUserDetail(user.email)).unwrap()
      dispatch(setUser(response.data))
  
      toast.success('Success updating user')
    } catch(err) {
      setIsEditingUser(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const doChangePassword = async ({ oldPassword, newPassword }) => {
    try {
      setIsChangingPassword(true)

      await dispatch(changePassword({
        oldPassword,
        newPassword
      })).unwrap()

      setIsChangingPassword(false)
      toast.success('Success changing password')
  
    } catch(err) {
      setIsChangingPassword(false)

      if (err.data.error === 'WRONG_PASSWORD') {
        toast.error('Wrong old password')
        return
      }

      if (err.data.error === 'SAME_PASSWORD') {
        toast.error('Password must be different than previous one')
        return
      }

      toast.error('Oops something wrong, please try again')
    }

    changePasswordForm.reset({ oldPassword: '', newPassword: '' })
  }

  return (
    <>
      {
        (isEditingUser || isChangingPassword) && <FullPageLoader />
      }
      <Header title="User">
        <div className="flex">
          <form
            className="mx-auto grid grid-cols-3 mt-6 gap-4 items-center"
            onSubmit={editUserForm.handleSubmit(doEditUser)}
          >
            <div className="col-span-1">
              Email:
            </div>
            <input
              type="text"
              className="col-span-2 outline-none py-2 border border-gray-300 px-5 rounded-xl"
              value={user.email}
              disabled
            />

            <div className="col-span-1">
              Name:
            </div>
            <div className="col-span-2 flex flex-col">
              <input
                type="text"
                placeholder="Username"
                className={classNames({
                  'outline-none border border-gray-300 py-2 px-5 rounded-xl': true,
                  'border-red-500': !!editUserForm.formState.errors.name?.message
                })}
                {...editUserForm.register('name')}
              />
              {
                editUserForm.formState.errors.name?.message && (
                  <small className="ml-2 text-red-500">
                    Fill name
                  </small>
                )
              }
            </div>

            <input
              type="submit"
              value="Save"
              className="col-start-2 col-end-3 p-3 font-bold bg-gray-800 text-white rounded-full cursor-pointer"
            />
          </form>

          <form
            className="mx-auto grid grid-cols-3 mt-6 gap-4 items-center"
            onSubmit={changePasswordForm.handleSubmit(doChangePassword)}
          >
            <div className="col-span-1 whitespace-nowrap">
              Old password:
            </div>
            <div className="col-span-2 flex flex-col">
              <input
                type="password"
                className={classNames({
                  'outline-none border border-gray-300 py-3 px-5 rounded-xl': true,
                  'border-red-500': !!changePasswordForm.formState.errors.oldPassword?.message
                })}
                {...changePasswordForm.register('oldPassword')}
              />
              {
                changePasswordForm.formState.errors.oldPassword?.message && (
                  <small className="ml-2 text-red-500">
                    Fill old password min. 3 characters
                  </small>
                )
              }
            </div>

            <div className="col-span-1 whitespace-nowrap">
              New password:
            </div>
            <div className="col-span-2 flex flex-col">
              <input
                type="password"
                className={classNames({
                  'outline-none border border-gray-300 py-3 px-5 rounded-xl': true,
                  'border-red-500': !!changePasswordForm.formState.errors.newPassword?.message
                })}
                {...changePasswordForm.register('newPassword')}
              />
              {
                changePasswordForm.formState.errors.newPassword?.message && (
                  <small className="ml-2 text-red-500">
                    Fill new password min. 3 characters
                  </small>
                )
              }
            </div>

            <input
              type="submit"
              value="Change Password"
              className="col-start-2 col-end-3 p-3 font-bold bg-white border border-gray-800 rounded-full cursor-pointer"
            />
          </form>
        </div>
      </Header>
    </>
  )
}

export default EditUserPage