import React, { useEffect, useState } from 'react'
import { getCategories } from '@/store/slices/category'
import { useDispatch } from 'react-redux'
import debounce from '@/utils/debouncer'
import toast from 'react-hot-toast'

import Dropdown from '@/components/Dropdown'

const CategoryDropdown = ({ form, error, value, onItemChange, disabled, disposable, useForm = true }) => {
  const dispatch = useDispatch()

  const [categories, setCategories] = useState({ data: [], paging: {} })
  const [categorySearch, setCategorySearch] = useState(undefined)
  const [isFetchingCategory, setIsFetchingCategory] = useState(false)

  useEffect(() => {
    if (useForm) {
      form.register('category')
    }
  }, [])

  useEffect(() => {
    if (categorySearch === undefined) {
      return
    }
    debounce(() => fetchCategory(1, false), 200, 'category-search')
  }, [categorySearch])

  const fetchCategory = async (page, append = true) => {
    try {
      setIsFetchingCategory(true)

      const categoryResponses = await dispatch(getCategories({ page, search: categorySearch })).unwrap()
      setCategories({
        data: append ? [...categories.data, ...categoryResponses.data] : categoryResponses.data,
        paging: categoryResponses.paging
      })

      setIsFetchingCategory(false)
    } catch(err) {
      toast.error('Oops something wrong, please try again')
      setIsFetchingCategory(false)
    }
  }

  const getNextPageCategory = () => {
    if (categories.paging.page >= categories.paging.totalPage) {
      return
    }

    fetchCategory(categories.paging.page + 1)
  }

  const categoryDropdownItems = () => (categories.data || []).map(c => ({ key: c.id, value: c.name }))

  const handleOnItemChange = selectedItem => {
    if (useForm) {
      form.setValue('category', selectedItem)
      form.trigger('category')
    }
    onItemChange && onItemChange(selectedItem)
  }

  return (
    <Dropdown
      name="Select category"
      items={categoryDropdownItems()}
      emptyMessage="No Categories Yet"
      searchState={[categorySearch, setCategorySearch]}
      onDropdownOpen={() => fetchCategory(1, false)}
      isLoading={isFetchingCategory}
      onIntersecting={getNextPageCategory}
      onItemChange={handleOnItemChange}
      error={error}
      value={value}
      disposable={disposable}
      disabled={disabled}
    />
  )
}

export default CategoryDropdown