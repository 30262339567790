import React, { useEffect, useState } from 'react'
import config from '@/constant/config'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userGrantsSelector } from '@/store/slices/user'
import { getStoreLocations } from '@/store/slices/store-location'
import toast from 'react-hot-toast'

import Header from '@/components/Header'
import FullPageLoader from '@/components/FullPageLoader'
import StoreLocationItem from '@/components/StoreLocationItem'

const { path } = config

const StoreLocationPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const grants = useSelector(userGrantsSelector)

  const [isLoading, setIsLoading] = useState(false)
  const [storeLocations, setStoreLocations] = useState([])

  const hasCreateAccess = () => grants.store_location.includes('create')

  useEffect(() => {
    fetchStoreLocations()
  }, [])

  const fetchStoreLocations = async () => {
    try {
      setIsLoading(true)
      const { data } = await dispatch(getStoreLocations()).unwrap()
      setStoreLocations(data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  return (
    <Header title="Store Location">
      {isLoading && <FullPageLoader />}

      <section className="flex flex-col">
        {
          hasCreateAccess() && (
            <button
              type="button"
              className="mr-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
              onClick={() => navigate(path.content.storeLocationCreate)}
            >
              Create
            </button>
          )
        }
        {
          !isLoading && !storeLocations.length && (
            <div className="text-lg font-bold mx-auto my-4">
              No Store Locations Yet
            </div>
          )
        }
        <section className="mt-5 flex flex-col gap-y-4">
          {
            storeLocations.map(storeLocation => (
              <StoreLocationItem
                key={storeLocation.id}
                id={storeLocation.id}
                name={storeLocation.name}
                address={storeLocation.address}
                locationUrl={storeLocation.locationUrl}
                image={storeLocation.image}
                onSuccessDelete={fetchStoreLocations}
              />
            ))
          }
        </section>
      </section>
    </Header>
  )
}

export default StoreLocationPage