import React from 'react'
import toast from 'react-hot-toast'

import ProductListModal from '@/components/ProductListModal'

const ProductSetComponent = ({ visibleState, onSubmit, initial }) => {

  const submit = products => {
    if (!products.length) {
      toast.error('Please select products')
      return
    }

    onSubmit && onSubmit({
      type: 'PRODUCT_SET',
      products
    })
  }

  return (
    <ProductListModal
      visibleState={visibleState}
      initialProducts={initial || []}
      onSubmit={submit}
    />
  )
}

export default ProductSetComponent