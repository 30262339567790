import React, { useState } from 'react'
import { FaChevronRight } from 'react-icons/fa'

import BannerComponent from '@/components/CustomLayout/BannerComponent'
import CarouselComponent from '@/components/CustomLayout/CarouselComponent'
import TextComponent from '@/components/CustomLayout/TextComponent'
import ProductSetComponent from '@/components/CustomLayout/ProductSetComponent'

const COMPONENTS = [
  {
    key: 'BANNER',
    text: 'Banner',
    Component: BannerComponent
  },
  {
    key: 'CAROUSEL',
    text: 'Carousel',
    Component: CarouselComponent
  },
  {
    key: 'TEXT',
    text: 'Text',
    Component: TextComponent
  },
  {
    key: 'PRODUCT_SET',
    text: 'Product Set',
    Component: ProductSetComponent
  }
]

const ComponentDrawer = ({ onAddComponent }) => {
  const [visibleComponentModal, setVisibleComponentModal] = useState({
    BANNER: false,
    CAROUSEL: false,
    TEXT: false,
    PRODUCT_SET: false
  })

  return (
    <>
      {
        COMPONENTS.map((comp, i) => (
          <div key={i}>
            {
              visibleComponentModal[comp.key] && (
                <comp.Component
                  visibleState={[
                    visibleComponentModal[comp.key],
                    s => setVisibleComponentModal({
                      ...visibleComponentModal,
                      [comp.key]: s
                    })
                  ]}
                  onSubmit={onAddComponent}
                />
              )
            }
          </div>
        ))
      }

      <div className="w-1/6 shadow rounded p-4">
        <div className="text-lg font-semibold">
          Components
        </div>

        <hr className="my-3" />

        <section className="flex flex-col gap-y-4">
          {
            COMPONENTS.map(comp => (
              <div key={comp.key}>
                <button
                  className="cursor-pointer flex items-center justify-between w-full"
                  onClick={() => setVisibleComponentModal({
                    ...visibleComponentModal,
                    [comp.key]: true
                  })}
                >
                  <div className="font-semibold mb-1">
                    {comp.text}
                  </div>
                  <FaChevronRight />
                </button>
              </div>
            ))
          }
        </section>
      </div>
    </>
  )
}

export default ComponentDrawer