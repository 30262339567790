import axios from 'axios'
import config from '@/constant/config'

const { api } = config

const axiosInstance = axios.create()

axiosInstance.interceptors.response.use(response => response, error => {
  if (error.response.status === 401 && error.config.url !== api.login && error.config.url !== api.loginInfo) {
    window.location.href = '/login'
  }
  return Promise.reject(error)
})

export default axiosInstance
