import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import toast from 'react-hot-toast'
import { numberInput, toAsiaJakartaZone } from '@/utils/common'
import DatePicker from 'react-datepicker'
import config from '@/constant/config'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { saveVoucher } from '@/store/slices/voucher'
import { FaTimes } from 'react-icons/fa'

import Header from '@/components/Header'
import FullPageLoader from '@/components/FullPageLoader'
import Dropdown from '@/components/Dropdown'
import RadioButton from '@/components/RadioButton'
import ProductDropdown from '@/components/ProductDropdown'

const VoucherSection = ({ title, children }) => (
  <div>
    <div className="font-bold mb-4">
      {title}
    </div>
    {children}
    <hr className="my-6" />
  </div>
)

const validationSchema = yup.object().shape({
  code: yup.string().required().uppercase(),
  quota: yup.number().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required().min(yup.ref('startDate'), 'End date must be greater than start date'),
  voucherType: yup.string().required(),
  value: yup.number().required(),
  maxDiscount: yup.number()
    .when('voucherType', ([voucherType], field) => {
      if (voucherType === 'PERCENTAGE') {
        return field.required('Max discount is required')
      }
      return field.nullable()
    }),
  product: yup.string().required(),
  skus: yup.array().of(yup.string())
    .when('product', ([product], field) => {
      if (product === 'SELECTED_PRODUCT') {
        return field.min(1)
      }
      return field.nullable()
    })
})

const CreateVoucherPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [code, setCode] = useState('')
  const [skus, setSkus] = useState([])
  const [isCreatingVoucher, setIsCreatingVoucher] = useState(false)

  useEffect(() => {
    register('code')
    register('voucherType')
    register('startDate')
    register('endDate')
  }, [])

  useEffect(() => {
    setValue('endDate', endDate)
  }, [endDate])

  useEffect(() => {
    setValue('startDate', startDate)
    if (startDate && endDate && startDate.getTime() > endDate.getTime()) {
      setEndDate(startDate)
    }
  }, [startDate])

  useEffect(() => {
    setValue('skus', skus.map(att => att.key))
  }, [skus])

  useEffect(() => {
    setValue('code', code)
  }, [code])

  const {
    register,
    handleSubmit,
    setValue,
    control,
    resetField,
    trigger,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const voucherType = useWatch({
    control,
    name: 'voucherType'
  })

  const product = useWatch({
    control,
    name: 'product'
  })

  useEffect(() => {
    setSkus([])
  }, [product])

  const basicInputElement = (name, title, isNumberInput = false, optional = false, slot) => (
    <>
      <div className="font-semibold mb-1 text-sm flex">
        <div>
          {title}
        </div>
        {
          optional && (
            <small className="text-gray-400 ml-1">(Optional)</small>
          )
        }
      </div>

      <div className="flex items-center">
        {
          slot ? slot() : (
            <input
              type="text"
              className={classNames({
                'w-full p-2 border border-gray-300 rounded-lg': true,
                'border-red-500': !!errors[name]
              })}
              {...isNumberInput && { onKeyDown: numberInput }}
              {...register(name)}
            />
          )
        }
      </div>
      {
        errors[name] && (
          <small className="ml-2 text-red-500">
            {title} must not empty
          </small>
        )
      }
    </>
  )

  const voucherTypeChangeHandler = selectedItem => {
    setValue('voucherType', selectedItem.key)
    trigger('voucherType')
    resetField('value')
    resetField('minAmount')
    resetField('maxDiscount')
  }

  const createVoucher = async data => {
    try {
      setIsCreatingVoucher(true)

      await dispatch(saveVoucher({
        code: data.code,
        quota: data.quota,
        startDate: data.startDate && toAsiaJakartaZone(data.startDate.getTime()),
        endDate: data.endDate && toAsiaJakartaZone(data.endDate.getTime()),
        type: data.voucherType,
        value: data.value,
        minAmount: data.minAmount,
        maxDiscount: data.maxDiscount,
        skus: data.skus
      })).unwrap()

      toast.success('Success creating voucher')
      navigate(config.path.engagement.voucher)
      setIsCreatingVoucher(false)
    } catch(error) {
      setIsCreatingVoucher(false)
      if (error?.data?.error === 'VOUCHER_CODE_EXIST') {
        toast.error('Voucher code already exist')
        return;
      }
      toast.error('Oops something wrong, please try again')
    }
  }

  const handleCodeChange = e => {
    setCode(e.target.value.toUpperCase())
  }

  const handleProductChange = selectedProduct => {
    const isExistProduct = skus.some(sku => sku.key === selectedProduct.key)
    if (isExistProduct) {
      return
    }

    setSkus(prev => [...prev, selectedProduct])
  }

  const removeSku = key => {
    setSkus(prev => prev.filter(p => p.key !== key))
  }

  return (
    <>
      {isCreatingVoucher && <FullPageLoader />}

      <Header title="Create Voucher">
        <form onSubmit={handleSubmit(createVoucher)} className="flex flex-col">
          <VoucherSection title="Information">
            <div className="grid grid-cols-3 gap-4 mt-4">
              <div className="col-span-1">
                <div className="font-semibold mb-1 text-sm">
                  Code
                </div>
                <input
                  type="text"
                  className={classNames({
                    'w-full p-2 border border-gray-300 rounded-lg': true,
                    'border-red-500': !!errors.code
                  })}
                  onChange={handleCodeChange}
                  value={code}
                />
                {
                  errors.code && (
                    <small className="ml-2 text-red-500">
                      Code must not empty
                    </small>
                  )
                }
              </div>

              <div className="col-span-1">
                {basicInputElement('quota', 'Quota', true)}
              </div>

              <div className="col-span-1" />

              <div className="col-span-1">
                <div className="font-semibold mb-1 text-sm">
                  Start Date
                </div>
                <DatePicker
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  customInput={
                    <input
                      type="button"
                      className={classNames({
                        'w-full text-left p-2 border border-gray-300 rounded-lg': true,
                        'border-red-500': !!errors.startDate?.message
                      })}
                    />
                  }
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  minDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                />
                {
                  !!errors.startDate?.message && (
                    <small className="ml-2 text-red-500">
                      Select start date
                    </small>
                  )
                }
              </div>

              <div className="col-span-1">
                {
                  !!startDate && (
                    <>
                      <div className="font-semibold mb-1 text-sm">
                        End Date
                      </div>
                      <DatePicker
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        customInput={
                          <input
                            type="button"
                            className={classNames({
                              'w-full text-left p-2 border border-gray-300 rounded-lg': true,
                              'border-red-500': !!errors.endDate?.message
                            })}
                          />
                        }
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        dateFormat="dd/MM/yyyy"
                      />
                      {
                        !!errors.endDate?.message && (
                          <small className="ml-2 text-red-500">
                            Select end date
                          </small>
                        )
                      }
                    </>
                  )
                }
              </div>
            </div>
          </VoucherSection>

          <VoucherSection title="Discount">
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-1">
                <div className="font-semibold mb-1 text-sm">
                  Type
                </div>
                <Dropdown
                  name="Select type"
                  items={config.voucher.type}
                  onItemChange={voucherTypeChangeHandler}
                  error={!!errors.voucherType}
                />
                {
                  !!errors.voucherType && (
                    <small className="ml-2 text-red-500">
                      Select voucher type
                    </small>
                  )
                }
              </div>

              <div className="col-span-1">
                {basicInputElement(
                  'value',
                  'Value',
                  true,
                  false,
                  () => (
                    <>
                      {
                        voucherType === 'AMOUNT_OFF' && (
                          <div className="mr-1">
                            Rp
                          </div>
                        )
                      }
                      <input
                        type="text"
                        className={classNames({
                          'w-full p-2 border border-gray-300 rounded-lg': true,
                          'border-red-500': !!errors.value
                        })}
                        onKeyDown={numberInput}
                        {...register('value')}
                      />
                      {
                        voucherType === 'PERCENTAGE' && (
                          <div className="ml-1">
                            %
                          </div>
                        )
                      }
                    </>
                  )
                )}
              </div>

              <div className="col-span-1">
                {
                  voucherType === 'PERCENTAGE' && basicInputElement('maxDiscount', 'Max Discount', true)
                }
              </div>

              <div className="col-span-1">
                {basicInputElement('minAmount', 'Min Amount', true, true)}
              </div>
            </div>
          </VoucherSection>

          <VoucherSection title="Product">
            <RadioButton
              id="ALL_PRODUCT"
              text="Apply voucher to all products"
              value="ALL_PRODUCT"
              name="VOUCHER_PRODUCT"
              checked={product === 'ALL_PRODUCT'}
              className="mb-4"
              {...register('product')}
            />

            <RadioButton
              id="SELECTED_PRODUCT"
              text="Apply voucher to selected products"
              value="SELECTED_PRODUCT"
              name="VOUCHER_PRODUCT"
              checked={product === 'SELECTED_PRODUCT'}
              className="mb-4"
              {...register('product')}
            />

            {
              product === 'SELECTED_PRODUCT' && (
                <div className="w-1/2">
                  <ProductDropdown
                    error={!!errors.skus}
                    onItemChange={handleProductChange}
                  />
                </div>
              )
            }

            {
              !!skus.length && (
                <div className="flex flex-wrap gap-2 mt-4">
                  {
                    skus.map(sku => (
                      <div
                        key={sku.key}
                        className="px-4 py-1 rounded-full bg-gray-400 text-white flex items-center"
                      >
                        <div className="mr-3">
                          {sku.value}
                        </div>
                        <FaTimes
                          className="mt-0.5 cursor-pointer"
                          onClick={() => removeSku(sku.key)}
                        />
                      </div>
                    ))
                  }
                </div>
              )
            }

            {
              errors.product && (
                <small className="text-red-500">
                  Select voucher product restriction
                </small>
              )
            }

            {
              errors.skus && (
                <small className="ml-2 text-red-500">
                  Select minimal 1 sku
                </small>
              )
            }
          </VoucherSection>

          <div className="flex self-end">
            <button
              className="px-6 py-2.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-3"
              onClick={() => navigate(config.path.engagement.voucher)}
              type="button"
            >
              Back
            </button>

            <input
              type="submit"
              className="px-6 py-2.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
              value="Create"
            />
          </div>
        </form>
      </Header>
    </>
  )
}

export default CreateVoucherPage