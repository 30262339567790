import React from 'react'

const STATUS_MAP = {
  PENDING: {
    text: 'Pending',
    color: 'bg-orange-500'
  },
  PROCESSED: {
    text: 'Processed',
    color: 'bg-blue-500'
  },
  CONFIRMED: {
    text: 'Confirmed',
    color: 'bg-blue-500'
  },
  READY_TO_SHIP: {
    text: 'Ready to Ship',
    color: 'bg-blue-500'
  },
  PAYMENT_SETTLED: {
    text: 'Payment Settled',
    color: 'bg-blue-500'
  },
  IN_DELIVERY: {
    text: 'In Delivery',
    color: 'bg-blue-500'
  },
  SUCCESS: {
    text: 'Success',
    color: 'bg-green-500'
  },
  FAILED: {
    text: 'Failed',
    color: 'bg-red-500'
  },
  CANCELED: {
    text: 'Canceled',
    color: 'bg-gray-500'
  }
}

const OrderStatusLabel = ({ status }) => {
  const statusDetail = STATUS_MAP[status]
  return (
    <div className={`rounded-full text-center text-white text-[11px] px-2 py-1 ${statusDetail.color}`}>
      {statusDetail.text}
    </div>
  )
}

export default OrderStatusLabel