import React from 'react'

import './full-page-loader.scss'

const FullPageLoader = () => {
  return (
    <div className="FullPageLoader">
      <div className="lds-ripple">
        <div />
        <div />
      </div>
    </div>
  )
}

export default FullPageLoader