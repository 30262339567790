import React, { useEffect, useState } from 'react'
import config from '@/constant/config'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getFeaturedProducts, updateFeaturedProductSequence } from '@/store/slices/featured-product'
import { ReactSortable } from 'react-sortablejs'
import toast from 'react-hot-toast'
import { userGrantsSelector } from '@/store/slices/user'
import classNames from 'classnames'

import Header from '@/components/Header'
import FullPageLoader from '@/components/FullPageLoader'
import FeaturedProductItem from '@/components/FeaturedProductItem'

const { path } = config

const FeaturedProductPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const grants = useSelector(userGrantsSelector)

  const [isLoading, setIsLoading] = useState(false)
  const [featuredProducts, setFeaturedProducts] = useState([])

  useEffect(() => {
    fetchFeaturedProducts()
  }, [])

  const hasCreateFeaturedProductAccess = () => grants.featured_product.includes('create')
  const hasEditFeaturedProductAccess = () => grants.featured_product.includes('update')

  const fetchFeaturedProducts = async () => {
    try {
      setIsLoading(true)

      const { data } = await dispatch(getFeaturedProducts()).unwrap()
      setFeaturedProducts(data)

      setIsLoading(false)
    } catch(err) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const moveFeaturedProduct = async e => {
    if (e.oldIndex === e.newIndex) {
      return
    }

    try {
      setIsLoading(true)
      const moved = featuredProducts[e.oldIndex]
      await dispatch(updateFeaturedProductSequence({
        id: moved.id,
        body: {
          sequence: e.newIndex + 1
        }
      })).unwrap()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  return (
    <Header title="Featured Product">
      {isLoading && <FullPageLoader />}

      <section className="flex flex-col">
        {
          hasCreateFeaturedProductAccess() && (
            <button
              type="button"
              className="mr-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
              onClick={() => navigate(path.content.featuredProductCreate)}
            >
              Create
            </button>
          )
        }
        {
          !isLoading && !featuredProducts.length && (
            <div className="text-lg font-bold mx-auto my-4">
              No Featured Products Yet
            </div>
          )
        }
        {
          !isLoading && !!featuredProducts.length && (
            <ReactSortable
              className={classNames({
                'flex flex-col mt-4 gap-y-4': true,
                'cursor-move': hasEditFeaturedProductAccess(),
                'cursor-default': !hasEditFeaturedProductAccess()
              })}
              list={featuredProducts}
              setList={setFeaturedProducts}
              onEnd={moveFeaturedProduct}
              disabled={!hasEditFeaturedProductAccess()}
            >
              {
                featuredProducts.map(featuredProduct => (
                  <FeaturedProductItem
                    key={featuredProduct.id}
                    id={featuredProduct.id}
                    title={featuredProduct.title}
                    hidden={featuredProduct.hidden}
                    products={featuredProduct.products}
                    onSuccessDelete={fetchFeaturedProducts}
                  />
                ))
              }
            </ReactSortable>
          )
        }
      </section>
    </Header>
  )
}

export default FeaturedProductPage