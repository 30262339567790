import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { deleteStoreLocation } from '@/store/slices/store-location'
import { userGrantsSelector } from '@/store/slices/user'
import { FaTrash, FaEdit } from 'react-icons/fa'
import config from '@/constant/config'
import toast from 'react-hot-toast'

import Modal from '@/components/Modal'
import FullPageLoader from '@/components/FullPageLoader'

const StoreLocationItem = ({
  id,
  image,
  name,
  locationUrl,
  address,
  onSuccessDelete
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const grants = useSelector(userGrantsSelector)

  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const hasEditAccess = () => grants.store_location.includes('update')
  const hasDeleteAccess = () => grants.store_location.includes('delete')

  const doDeleteStoreLocation = async () => {
    try {
      setIsLoading(true)
      await dispatch(deleteStoreLocation(id)).unwrap()
      onSuccessDelete && await onSuccessDelete()
      setVisibleDeleteModal(false)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  return (
    <>
      {isLoading && <FullPageLoader />}

      {
        visibleDeleteModal && (
          <Modal visibleState={[visibleDeleteModal, setVisibleDeleteModal]}>
            <section className="flex flex-col">
              <div className="font-bold text-xl mb-2">
                Confirmation
              </div>
              <div>
                Are you sure to delete this store location?
              </div>
              <div className="self-end mt-4">
                <button
                  type="button"
                  onClick={() => setVisibleDeleteModal(false)}
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-2"
                >
                  Cancel
                </button>
                <input
                  type="submit"
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
                  onClick={doDeleteStoreLocation}
                  value="Delete"
                />
              </div>
            </section>
          </Modal>
        )
      }
      <div className="w-1/2 border border-dotted border-blue-500 p-2 flex gap-x-5">
        <img
          src={image.url}
          alt=""
          className="object-contain w-32 aspect-square"
        />

        <div>
          <div className="font-semibold text-xl mb-1.5">
            {name}
          </div>
          <div>
            {address}
          </div>
          <a
            href={locationUrl}
            className="text-blue-500"
            target="_blank"
          >
            {locationUrl}
          </a>
        </div>

        <div className="self-start flex gap-x-3 ml-auto">
          {
            hasEditAccess() && (
              <button
                type="button"
                onClick={() => navigate(config.path.content.storeLocationEdit.replace(':id', id))}
              >
                <FaEdit className="text-blue-500" />
              </button>
            )
          }
          {
            hasDeleteAccess() && (
              <button
                type="button"
                onClick={() => setVisibleDeleteModal(true)}
              >
                <FaTrash className="text-blue-500" />
              </button>
            )
          }
        </div>
      </div>
    </>
  )
}

export default StoreLocationItem