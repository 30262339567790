import React, { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import toast from 'react-hot-toast'
import config from '@/constant/config'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { deleteImage, uploadStoreLocationImage } from '@/store/slices/image'
import { saveStoreLocation } from '@/store/slices/store-location'
import { FaUpload, FaTimes } from 'react-icons/fa'

import Header from '@/components/Header'
import FullPageLoader from '@/components/FullPageLoader'
import Modal from '@/components/Modal'
import Spinner from '@/components/Spinner'

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  address: yup.string().required(),
  locationUrl: yup.string().url().required(),
  image: yup.object().shape({
    name: yup.string().required(),
    originalName: yup.string(),
    url: yup.string().required()
  })
})

const CreateStoreLocationPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [visibleCreateConfirmationModal, setVisibleCreateConfirmationModal] = useState(false)
  const [isUploadingImage, setIsUploadingImage] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const image = useWatch({
    control,
    name: 'image'
  })

  const handleImageChange = async e => {
    try {
      setIsUploadingImage(true)

      const form = new FormData()
      form.append('image', e.target.files[0])
      const { data } = await dispatch(uploadStoreLocationImage(form)).unwrap()

      setValue('image', data)
      trigger('image')

      setIsUploadingImage(false)
    } catch(err) {
      console.log(err)
      setIsUploadingImage(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const removeImage = async () => {
    try {
      setValue('image', null)
      await dispatch(deleteImage([image.name])).unwrap()
    } catch(err) {
    }
  }

  const createStoreLocation = async () => {
    try {
      setIsLoading(true)
      const { name, address, locationUrl, image } = getValues()
      await dispatch(saveStoreLocation({
        name,
        address,
        locationUrl,
        image: {
          filename: image.name,
          url: image.url
        }
      })).unwrap()
      toast.success('Store location successfully created!')
      setIsLoading(false)
      navigate(config.path.content.storeLocation)
    } catch (error) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  return (
    <Header title="Create Store Location">
      {isLoading && <FullPageLoader />}

      {
        visibleCreateConfirmationModal && (
          <Modal visibleState={[visibleCreateConfirmationModal, setVisibleCreateConfirmationModal]}>
            <section className="flex flex-col">
              <div className="font-bold text-xl mb-2">
                Confirmation
              </div>
              <div>
                Are you sure to create this store location?
              </div>
              <div className="self-end mt-4">
                <button
                  type="button"
                  onClick={() => setVisibleCreateConfirmationModal(false)}
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-2"
                >
                  Cancel
                </button>
                <input
                  type="submit"
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
                  onClick={createStoreLocation}
                  value="Create"
                />
              </div>
            </section>
          </Modal>
        )
      }

      <form
        onSubmit={handleSubmit(() => setVisibleCreateConfirmationModal(true))}
        className="flex flex-col"
      >
        <div className="font-semibold mb-1">
          Name
        </div>
        <input
          type="text"
          className={classNames({
            'w-1/2 p-2 border border-gray-300 rounded-lg': true,
            'border-red-500': !!errors.name
          })}
          {...register('name')}
        />
        {
          errors.name && (
            <small className="text-red-500">
              Name must not empty
            </small>
          )
        }

        <div className="font-semibold mb-1 mt-4">
          Address
        </div>
        <textarea
          className={classNames({
            'w-1/2 p-2 border border-gray-300 rounded-lg': true,
            'border-red-500': !!errors.address
          })}
          {...register('address')}
        />
        {
          errors.address && (
            <small className="text-red-500">
              Address must not empty
            </small>
          )
        }

        <div className="font-semibold mb-1 mt-4">
          Location Url
        </div>
        <input
          type="text"
          placeholder="https://"
          className={classNames({
            'w-1/2 p-2 border border-gray-300 rounded-lg': true,
            'border-red-500': !!errors.locationUrl
          })}
          {...register('locationUrl')}
        />
        {
          errors.locationUrl && (
            <small className="text-red-500">
              Location must a valid url
            </small>
          )
        }

        <div className="font-semibold mb-1 mt-4">
          Image
        </div>
        {
          !!image && (
            <div className="relative">
              <img
                src={image.url}
                className="w-48 h-48 object-contain"
              />
              <button
                type="button"
                className="absolute left-0 top-0 p-1.5 bg-white opacity-50"
                onClick={removeImage}
              >
                <FaTimes />
              </button>
            </div>
          )
        }
        {
          isUploadingImage && (
            <div className="w-48 h-48 mr-5 flex items-center justify-center border border-gray-200">
              <Spinner />
            </div>
          )
        }
        {
          !isUploadingImage && !image && (
            <div>
              <div className={classNames({
                'w-48 h-48 border-2 border-dotted': true,
                'border-blue-300': !errors.image?.message,
                'border-red-500': !!errors.image?.message
              })}>
                <label htmlFor="image-input" className="w-full h-full flex flex-col items-center justify-center cursor-pointer">
                  <FaUpload className="text-3xl text-blue-300" />
                  <div className="mt-1 text-blue-300">
                    Upload
                  </div>
                </label>

                <input
                  id="image-input"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={handleImageChange}
                  style={{
                    width: '0.1px',
                    height: '0.1px',
                    opacity: '0',
                    overflow: 'hidden',
                    position: 'absolute',
                    zIndex: '-1'
                  }}
                />
              </div>
            </div>
          )
        }
        {
          !!errors.image?.message && (
            <small className="ml-2 text-red-500">
              Upload an image
            </small>
          )
        }

        <section className="flex mt-4 gap-x-3 justify-end">
          <button
            type="button"
            className="px-6 py-2.5 rounded-full border border-gray-800 bg-white text-gray-800 cursor-pointer"
            onClick={() => navigate(config.path.content.storeLocation)}
          >
            Back
          </button>
          <input
            type="submit"
            className="px-6 py-2.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
            value="Create"
          />
        </section>
      </form>
    </Header>
  )
}

export default CreateStoreLocationPage