import React from 'react'

const TableLoader = ({ column = 2 }) => {
  return (
    <>
      {
        [...new Array(5)].map((_, i) => (
          <tr className="animate-pulse" key={i}>
            {
              [...new Array(column)].map((_, columnIdx) => (
                <td className="p-4" key={columnIdx}>
                  <div className="bg-gray-300 rounded-full h-6" />
                </td>
              ))
            }
          </tr>
        ))
      }
    </>
  )
}

export default TableLoader