import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getHomepageProductConfig, updateHomepageProductConfig } from '@/store/slices/config'
import { userGrantsSelector } from '@/store/slices/user'
import toast from 'react-hot-toast'
import config from '@/constant/config'

import Header from '@/components/Header'
import FullPageLoader from '@/components/FullPageLoader'

const { path } = config
const { availability } = config.product

const ContentHomepageProduct = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const grants = useSelector(userGrantsSelector)

  const [isFetchingConfig, setIsFetchingConfig] = useState(false)
  const [configs, setConfigs] = useState([])

  const hasUpdateAccess = () => grants.homepage_product?.includes('update')

  useEffect(() => {
    fetchConfig()
  }, [])

  const fetchConfig = async () => {
    try {
      setIsFetchingConfig(true)

      const { data } = await dispatch(getHomepageProductConfig()).unwrap()
      setConfigs(data)

      setIsFetchingConfig(false)
    } catch(error) {
      toast.error('Oops something wrong, please try again')
      setIsFetchingConfig(false)
    }
  }

  const handleConfigCheck = e => {
    if (e.target.checked) {
      setConfigs([
        ...configs,
        e.target.id
      ])
    } else {
      setConfigs(configs.filter(c => c !== e.target.id))
    }
  }

  const updateConfig = async () => {
    try {
      setIsFetchingConfig(true)

      await dispatch(updateHomepageProductConfig({
        availabilities: configs
      })).unwrap()

      setIsFetchingConfig(false)
      navigate(path.base)
      toast.success('Homepage product filter updated')
    } catch (error) {
      toast.error('Oops something wrong, please try again')
      setIsFetchingConfig(false)
    }
  }

  return (
    <>
      {isFetchingConfig && <FullPageLoader />}
      <Header title="Homepage Product">
        <div className="font-semibold">
          Availabilities
        </div>

        <section className="my-4">
          {
            availability.map(av => (
              <div key={av.key} className="flex items-center">
                <input
                  id={av.key}
                  type="checkbox"
                  value={av.value}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  checked={configs.includes(av.key)}
                  onChange={handleConfigCheck}
                  disabled={!hasUpdateAccess()}
                />
                <label
                  htmlFor={av.key}
                  className="ml-2 text-sm"
                >
                  {av.value}
                </label>
              </div>
            ))
          }
        </section>

        {
          hasUpdateAccess()  && (
            <div>
              <button
                className="py-2 px-10 border border-gray-700 bg-gray-700 text-white rounded-full"
                onClick={updateConfig}
              >
                Update
              </button>
            </div>
          )
        }
      </Header>
    </>
  )
}

export default ContentHomepageProduct