import React from 'react'
import { userSelector, userGrantsSelector } from '@/store/slices/user' 
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import config from '@/constant/config'

const { path } = config

const ProtectedRoute = ({ resource, action, children }) => {
  const location = useLocation()
  const { user, isGettingUser } = useSelector(userSelector)
  const grants = useSelector(userGrantsSelector)

  const hasAccessToResource = () => !resource || (grants[resource] && grants[resource].includes(action))

  return (
    <>
      {
        !isGettingUser && !user && <Navigate to={{ pathname: path.login, search: `?page=${window.btoa(location.pathname)}` }} />
      }
      {
        !isGettingUser && !!user && !hasAccessToResource() && <Navigate to={path.base} />
      }
      {
        !isGettingUser && !!user && hasAccessToResource() && children
      }
    </>
  )
}

export default ProtectedRoute