import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getBrands = createAsyncThunk(
  'brand/getBrands',
  async ({ page, size, search }, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.brand, { params: { page, search, size } })
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const getBrandDetail = createAsyncThunk(
  'brand/getBrandDetail',
  async (brandId, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.brandDetail(brandId))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const saveBrand = createAsyncThunk(
  'brand/saveBrand',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.brand, body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const updateBrand = createAsyncThunk(
  'brand/updateBrand',
  async ({ brandId, body }, { rejectWithValue }) => {
    try {
      const response = await axios.put(api.brandDetail(brandId), body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const deleteBrand = createAsyncThunk(
  'brand/deleteBrand',
  async (brandId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(api.brandDetail(brandId))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const brandSlice = createSlice({
  name: 'brand',
  initialState: {},
  reducers: {}
})

export default brandSlice.reducer
