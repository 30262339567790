import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getOrderList = createAsyncThunk(
  'order/getOrderList',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.order, { params })
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const getOrderDetail = createAsyncThunk(
  'order/getOrderDetail',
  async (orderId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.orderDetail(orderId))
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const setOrderReadyToShip = createAsyncThunk(
  'order/setOrderReadyToShip',
  async ({ orderId, orderItemId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.orderReadyToShip(orderId, orderItemId))
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const setOrderConfirmed = createAsyncThunk(
  'order/setOrderConfirmed',
  async ({ orderId, orderItemId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.orderConfirmed(orderId, orderItemId))
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const cancelOrder = createAsyncThunk(
  'order/cancelOrder',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(api.orderCancel, {
        data: body
      })
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const exportOrder = createAsyncThunk(
  'order/exportOrder',
  async (params, { rejectWithValue }) => {
    try {
      const { data, headers } = await axios.get(api.orderExport, { params })
      return {
        value: data.data,
        contentType: headers['content-type']
      }
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const adjustPrice = createAsyncThunk(
  'order/adjustPrice',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.orderPriceAdjustment, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const orderSlice = createSlice({
  name: 'order',
  initialState: {},
  reducers: {}
})

export default orderSlice.reducer