import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const login = createAsyncThunk(
  'user/login',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.login, body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const loginInfo = createAsyncThunk(
  'user/loginInfo',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.loginInfo)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const logout = createAsyncThunk(
  'user/logout',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.delete(api.logout)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const changePassword = createAsyncThunk(
  'user/changePassword',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.changePassword, body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const changeName = createAsyncThunk(
  'user/changeName',
  async ({ email, body }, { rejectWithValue }) => {
    try {
      const response = await axios.put(api.changeName(email), body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const getUser = createAsyncThunk(
  'user/getUser',
  async ({ page, size, search }, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.user, { params: { page, search, size } })
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const getUserDetail = createAsyncThunk(
  'user/getUserDetail',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.userDetail(email))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const saveUser = createAsyncThunk(
  'user/saveUser',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.user, body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async ({ email, body }, { rejectWithValue }) => {
    try {
      const response = await axios.put(api.userDetail(email), body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const deleteUser = createAsyncThunk(
  'user/deleteUser',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.delete(api.userDetail(email))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    isGettingUser: true
  },
  reducers: {
    setUser(state, actions) {
      state.user = actions.payload
    },
    setIsGettingUser(state, actions) {
      state.isGettingUser = actions.payload
    }
  }
})

export const userSelector = state => state.user

export const userGrantsSelector = state => {
  const grants = state.user.user?.grants || []
  return grants.reduce((acc, grant) => ({
    ...acc,
    [grant.resource]: grant.actions
  }), {})
}

export const { setUser, setIsGettingUser } = userSlice.actions

export default userSlice.reducer
