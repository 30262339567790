import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getCustomLayouts = createAsyncThunk(
  'customLayout/getCustomLayouts',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.customLayout, { params })
      return data
    } catch(error) {
      console.log(error)
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const getCustomLayoutSlugs = createAsyncThunk(
  'customLayout/getCustomLayoutSlugs',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.customLayoutSlug)
      return data
    } catch(error) {
      console.log(error)
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const getCustomLayoutDetail = createAsyncThunk(
  'customLayout/getCustomLayoutDetail',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.customLayoutDetail(id))
      return data
    } catch(error) {
      console.log(error)
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const saveCustomLayout = createAsyncThunk(
  'customLayout/saveCustomLayout',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.customLayout, body)
      return data
    } catch(error) {
      console.log(error)
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const updateCustomLayout = createAsyncThunk(
  'customLayout/updateCustomLayout',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(api.customLayoutDetail(id), body)
      return data
    } catch(error) {
      console.log(error)
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const deleteCustomLayout = createAsyncThunk(
  'customLayout/deleteCustomLayout',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(api.customLayoutDetail(id))
      return data
    } catch(error) {
      console.log(error)
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const customLayoutSlice = createSlice({
  name: 'customLayout',
  initialState: {},
  reducers: {}
})

export default customLayoutSlice.reducer