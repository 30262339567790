import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getCharacters = createAsyncThunk(
  'character/getCharacters',
  async ({ page, size, search }, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.character, { params: { page, search, size } })
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const getCharacterDetail = createAsyncThunk(
  'character/getCharacterDetail',
  async (characterId, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.characterDetail(characterId))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const saveCharacter = createAsyncThunk(
  'character/saveCharacter',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.character, body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const updateCharacter = createAsyncThunk(
  'character/updateCharacter',
  async ({ characterId, body }, { rejectWithValue }) => {
    try {
      const response = await axios.put(api.characterDetail(characterId), body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const deleteCharacter = createAsyncThunk(
  'character/deleteCharacter',
  async (characterId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(api.characterDetail(characterId))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const characterSlice = createSlice({
  name: 'character',
  initialState: {},
  reducers: {}
})

export default characterSlice.reducer
