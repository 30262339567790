export const readFileAsDataUrl = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = e => {
      const img = new Image();
      img.onload = () => {
        resolve(img)
      };
      img.src = e.target.result;
    };

    reader.onerror = error => reject(error)

    reader.readAsDataURL(file);
  })
}