import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import toast from 'react-hot-toast'
import { numberInput, toAsiaJakartaZone } from '@/utils/common'
import DatePicker from 'react-datepicker'
import config from '@/constant/config'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { userGrantsSelector } from '@/store/slices/user'
import { useDispatch, useSelector } from 'react-redux'
import { getVoucher, updateVoucher, terminateVoucher } from '@/store/slices/voucher'
import { FaTimes } from 'react-icons/fa'
import { useParams } from 'react-router-dom'

import Header from '@/components/Header'
import FullPageLoader from '@/components/FullPageLoader'
import Dropdown from '@/components/Dropdown'
import RadioButton from '@/components/RadioButton'
import ProductDropdown from '@/components/ProductDropdown'
import Modal from '@/components/Modal'

const VoucherSection = ({ title, children }) => (
  <div>
    <div className="font-bold mb-4">
      {title}
    </div>
    {children}
    <hr className="my-6" />
  </div>
)

const validationSchema = yup.object().shape({
  code: yup.string().required().uppercase(),
  quota: yup.number().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required().min(yup.ref('startDate'), 'End date must be greater than start date'),
  voucherType: yup.string().required(),
  value: yup.number().required(),
  maxDiscount: yup.number()
    .when('voucherType', ([voucherType], field) => {
      if (voucherType === 'PERCENTAGE') {
        return field.required('Max discount is required')
      }
      return field.nullable()
    }),
  product: yup.string().required(),
  skus: yup.array().of(yup.string())
    .when('product', ([product], field) => {
      if (product === 'SELECTED_PRODUCT') {
        return field.min(1)
      }
      return field.nullable()
    })
})

const EDIT_FIELD_CONTROL = {
  code: ['UPCOMING'],
  quota: ['UPCOMING', 'ACTIVE'],
  startDate: ['UPCOMING'],
  endDate: ['UPCOMING', 'ACTIVE'],
  voucherType: ['UPCOMING'],
  value: ['UPCOMING'],
  maxDiscount: ['UPCOMING'],
  minAmount: ['UPCOMING'],
  product: ['UPCOMING', 'ACTIVE'],
  skus: ['UPCOMING', 'ACTIVE']
}

const EditVoucherPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const grants = useSelector(userGrantsSelector)

  const { voucherId } = useParams()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [code, setCode] = useState('')
  const [skus, setSkus] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [voucher, setVoucher] = useState()
  const [visibleTerminateConfirmationModal, setVisibleTerminateConfirmationModal] = useState(false)

  const fetchVoucher = async () => {
    try {
      setIsLoading(true)

      const { data } = await dispatch(getVoucher(voucherId)).unwrap()
      setVoucher(data)

      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)
      if (error.data && error.data.error === 'VOUCHER_NOT_EXIST') {
        navigate(config.path.engagement.voucher)
        return
      }
      toast.error('Oops something wrong, please try again')
    }
  }

  useEffect(() => {
    register('code')
    register('voucherType')
    register('startDate')
    register('endDate')

    fetchVoucher()
  }, [])

  useEffect(() => {
    if (!voucher) {
      return
    }

    setCode(voucher.code)
    setStartDate(new Date(voucher.startDate))
    setEndDate(new Date(voucher.endDate))
    voucher.skus && setSkus(voucher.skus.map(s => ({ key: s.sku, value: `${s.sku} - ${s.name}` })))

    setValue('product', voucher.skus && voucher.skus.length ? 'SELECTED_PRODUCT' : 'ALL_PRODUCT')
    setValue('quota', voucher.quota)
    setValue('voucherType', voucher.type)
    setValue('value', voucher.value)
    setValue('maxDiscount', voucher.maxDiscount)
    setValue('minAmount', voucher.minAmount)
  }, [voucher])

  useEffect(() => {
    setValue('endDate', endDate)
  }, [endDate])

  useEffect(() => {
    setValue('startDate', startDate)
    if (startDate && endDate && startDate.getTime() > endDate.getTime()) {
      setEndDate(startDate)
    }
  }, [startDate])

  useEffect(() => {
    setValue('skus', skus.map(att => att.key))
  }, [skus])

  useEffect(() => {
    setValue('code', code)
  }, [code])

  const {
    register,
    handleSubmit,
    setValue,
    control,
    resetField,
    trigger,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const voucherType = useWatch({
    control,
    name: 'voucherType'
  })

  const product = useWatch({
    control,
    name: 'product'
  })

  useEffect(() => {
    if (product === 'ALL_PRODUCT') {
      setSkus([])
    }
  }, [product])

  const hasDeleteVoucherAccess = () => grants.voucher?.includes('delete')

  const basicInputElement = (name, title, isNumberInput = false, optional = false, slot) => (
    <>
      <div className="font-semibold mb-1 text-sm flex">
        <div>
          {title}
        </div>
        {
          optional && (
            <small className="text-gray-400 ml-1">(Optional)</small>
          )
        }
      </div>

      <div className="flex items-center">
        {
          slot ? slot() : (
            <input
              type="text"
              placeholder={title}
              className={classNames({
                'w-full p-2 border border-gray-300 rounded-lg': true,
                'border-red-500': !!errors[name]
              })}
              {...isNumberInput && { onKeyDown: numberInput }}
              {...register(name)}
              disabled={!EDIT_FIELD_CONTROL[name].includes(voucher.status)}
            />
          )
        }
      </div>
      {
        errors[name] && (
          <small className="ml-2 text-red-500">
            {title} must not empty
          </small>
        )
      }
    </>
  )

  const voucherTypeChangeHandler = selectedItem => {
    setValue('voucherType', selectedItem.key)
    trigger('voucherType')
    resetField('value')
    resetField('minAmount')
    resetField('maxDiscount')
  }

  const doUpdateVoucher = async data => {
    try {
      setIsLoading(true)

      await dispatch(updateVoucher({
        voucherId: voucher.id,
        body: {
          code: data.code,
          quota: data.quota,
          startDate: data.startDate && toAsiaJakartaZone(data.startDate.getTime()),
          endDate: data.endDate && toAsiaJakartaZone(data.endDate?.getTime()),
          type: data.voucherType,
          value: data.value,
          minAmount: data.minAmount,
          maxDiscount: data.maxDiscount,
          skus: data.skus
        }
      })).unwrap()

      toast.success('Success updating voucher')
      navigate(config.path.engagement.voucher)
      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const handleCodeChange = e => {
    setCode(e.target.value.toUpperCase())
  }

  const handleProductChange = selectedProduct => {
    const isExistProduct = skus.some(sku => sku.key === selectedProduct.key)
    if (isExistProduct) {
      return
    }

    setSkus(prev => [...prev, selectedProduct])
  }

  const removeSku = key => {
    setSkus(prev => prev.filter(p => p.key !== key))
  }

  const terminate = async () => {
    try {
      setIsLoading(true)
  
      await dispatch(terminateVoucher(voucher.id)).unwrap();
      navigate(config.path.engagement.voucher)
      toast.success('Voucher terminated')

      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  return (
    <>
      {isLoading && <FullPageLoader />}

      {
        visibleTerminateConfirmationModal && (
          <Modal visibleState={[visibleTerminateConfirmationModal, setVisibleTerminateConfirmationModal]}>
            <div className="flex flex-col">
              <div className="font-bold text-xl mb-2">
                Terminate Voucher
              </div>

              <p>
                Once terminated, you can't activate this voucher again. <br />
                If customer already used this voucher, it will still applied to their order.
              </p>

              <div className="self-end mt-4">
                <button
                  className="px-6 py-2 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-3"
                  onClick={() => setVisibleTerminateConfirmationModal(false)}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="px-6 py-2 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
                  onClick={terminate}
                  type="button"
                >
                  Terminate
                </button>
              </div>
            </div>
          </Modal>
        )
      }

      {
        voucher && (
          <Header title="Update Voucher">
            <form onSubmit={handleSubmit(doUpdateVoucher)} className="flex flex-col">
              <VoucherSection title="Information">
                <div className="grid grid-cols-3 gap-4 mt-4">
                  <div className="col-span-1">
                    <div className="font-semibold mb-1 text-sm">
                      Code
                    </div>
                    <input
                      type="text"
                      placeholder="Code"
                      className={classNames({
                        'w-full p-2 border border-gray-300 rounded-lg': true,
                        'border-red-500': !!errors.code
                      })}
                      onChange={handleCodeChange}
                      value={code}
                      disabled={!EDIT_FIELD_CONTROL.code.includes(voucher.status)}
                    />
                    {
                      errors.code && (
                        <small className="ml-2 text-red-500">
                          Code must not empty
                        </small>
                      )
                    }
                  </div>

                  <div className="col-span-1">
                    {basicInputElement('quota', 'Quota', true)}
                  </div>

                  <div className="col-span-1" />

                  <div className="col-span-1">
                    <div className="font-semibold mb-1 text-sm">
                      Start Date
                    </div>
                    <DatePicker
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                      customInput={
                        <input
                          type="button"
                          className={classNames({
                            'w-full text-left p-2 border border-gray-300 rounded-lg': true,
                            'border-red-500': !!errors.startDate?.message,
                            'bg-gray-50': !EDIT_FIELD_CONTROL.startDate.includes(voucher.status)
                          })}
                        />
                      }
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      minDate={new Date()}
                      readOnly={!EDIT_FIELD_CONTROL.startDate.includes(voucher.status)}
                      dateFormat="dd/MM/yyyy"
                    />
                    {
                      !!errors.startDate?.message && (
                        <small className="ml-2 text-red-500">
                          Select start date
                        </small>
                      )
                    }
                  </div>

                  <div className="col-span-1">
                    {
                      !!startDate && (
                        <>
                          <div className="font-semibold mb-1 text-sm">
                            End Date
                          </div>
                          <DatePicker
                            selected={endDate}
                            onChange={date => setEndDate(date)}
                            customInput={
                              <input
                                type="button"
                                className={classNames({
                                  'w-full text-left p-2 border border-gray-300 rounded-lg': true,
                                  'border-red-500': !!errors.endDate?.message,
                                  'bg-gray-50': !EDIT_FIELD_CONTROL.endDate.includes(voucher.status)
                                })}
                              />
                            }
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            readOnly={!EDIT_FIELD_CONTROL.endDate.includes(voucher.status)}
                            dateFormat="dd/MM/yyyy"
                          />
                          {
                            !!errors.endDate?.message && (
                              <small className="ml-2 text-red-500">
                                Select end date
                              </small>
                            )
                          }
                        </>
                      )
                    }
                  </div>
                </div>
              </VoucherSection>

              <VoucherSection title="Discount">
                <div className="grid grid-cols-3 gap-4">
                  {
                    voucherType && (
                      <div className="col-span-1">
                        <div className="font-semibold mb-1 text-sm">
                          Type
                        </div>
                        <Dropdown
                          name="Type"
                          items={config.voucher.type}
                          onItemChange={voucherTypeChangeHandler}
                          error={!!errors.voucherType}
                          value={config.voucher.type.find(v => v.key === voucher.type)}
                          disabled={!EDIT_FIELD_CONTROL.voucherType.includes(voucher.status)}
                        />
                        {
                          !!errors.voucherType && (
                            <small className="ml-2 text-red-500">
                              Select voucher type
                            </small>
                          )
                        }
                      </div>
                    )
                  }

                  <div className="col-span-1">
                    {basicInputElement(
                      'value',
                      'Value',
                      true,
                      false,
                      () => (
                        <>
                          {
                            voucherType === 'AMOUNT_OFF' && (
                              <div className="mr-1">
                                Rp
                              </div>
                            )
                          }
                          <input
                            type="text"
                            className={classNames({
                              'w-full p-2 border border-gray-300 rounded-lg': true,
                              'border-red-500': !!errors.value
                            })}
                            onKeyDown={numberInput}
                            {...register('value')}
                            disabled={!EDIT_FIELD_CONTROL['value'].includes(voucher.status)}
                          />
                          {
                            voucherType === 'PERCENTAGE' && (
                              <div className="ml-1">
                                %
                              </div>
                            )
                          }
                        </>
                      )
                    )}
                  </div>

                  <div className="col-span-1">
                    {
                      voucherType === 'PERCENTAGE' && basicInputElement('maxDiscount', 'Max Discount', true)
                    }
                  </div>

                  <div className="col-span-1">
                    {basicInputElement('minAmount', 'Min Amount', true, true)}
                  </div>
                </div>
              </VoucherSection>

              <VoucherSection title="Product">
                <RadioButton
                  id="ALL_PRODUCT"
                  text="Apply voucher to all products"
                  value="ALL_PRODUCT"
                  name="VOUCHER_PRODUCT"
                  checked={product === 'ALL_PRODUCT'}
                  className="mb-4"
                  {...register('product')}
                  disabled={!EDIT_FIELD_CONTROL.product.includes(voucher.status)}
                />

                <RadioButton
                  id="SELECTED_PRODUCT"
                  text="Apply voucher to selected products"
                  value="SELECTED_PRODUCT"
                  name="VOUCHER_PRODUCT"
                  checked={product === 'SELECTED_PRODUCT'}
                  className="mb-4"
                  {...register('product')}
                  disabled={!EDIT_FIELD_CONTROL.product.includes(voucher.status)}
                />

                {
                  product === 'SELECTED_PRODUCT' && (
                    <div className="w-1/2">
                      <ProductDropdown
                        error={!!errors.skus}
                        onItemChange={handleProductChange}
                        disabled={!EDIT_FIELD_CONTROL.skus.includes(voucher.status)}
                      />
                    </div>
                  )
                }

                {
                  !!skus && !!skus.length && (
                    <div className="flex flex-wrap gap-2 mt-4">
                      {
                        skus.map(sku => (
                          <div
                            key={sku.key}
                            className="px-4 py-1 rounded-full bg-gray-400 text-white flex items-center"
                          >
                            <div className="mr-3">
                              {sku.value}
                            </div>
                            {
                              EDIT_FIELD_CONTROL.skus.includes(voucher.status) && (
                                <FaTimes
                                  className="mt-0.5 cursor-pointer"
                                  onClick={() => removeSku(sku.key)}
                                />
                              )
                            }
                          </div>
                        ))
                      }
                    </div>
                  )
                }

                {
                  errors.product && (
                    <small className="text-red-500">
                      Select voucher product restriction
                    </small>
                  )
                }

                {
                  errors.skus && (
                    <small className="ml-2 text-red-500">
                      Select minimal 1 sku
                    </small>
                  )
                }
              </VoucherSection>

              <div className="flex">
                <button
                  className="px-6 py-2.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-3"
                  onClick={() => navigate(config.path.engagement.voucher)}
                  type="button"
                >
                  Back
                </button>

                <div className="ml-auto">
                  {
                    voucher.status === 'ACTIVE' && hasDeleteVoucherAccess() && (
                      <button
                        className="px-6 py-2.5 rounded-full border border-red-500 text-red-500 cursor-pointer mr-3"
                        onClick={() => setVisibleTerminateConfirmationModal(true)}
                        type="button"
                      >
                        Terminate
                      </button>
                    )
                  }

                  {
                    (voucher.status === 'ACTIVE' || voucher.status === 'UPCOMING') && (
                      <input
                        type="submit"
                        className="px-6 py-2.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
                        value="Update"
                      />
                    )
                  }
                </div>
              </div>
            </form>
          </Header>
        )
      }

    </>
  )
}

export default EditVoucherPage