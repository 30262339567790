import React, { useEffect, useState } from 'react'
import config from '@/constant/config'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userGrantsSelector } from '@/store/slices/user'
import { getCustomLayouts, deleteCustomLayout } from '@/store/slices/custom-layout'
import { FaTrash } from 'react-icons/fa'
import toast from 'react-hot-toast'
import useDidUpdateEffect from '@/hooks/did-update-effect'

import Pagination from '@/components/Pagination'
import Header from '@/components/Header'
import Table from '@/components/Table'
import FullPageLoader from '@/components/FullPageLoader'
import Modal from '@/components/Modal'

const { path } = config

const CustomLayoutPage = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const grants = useSelector(userGrantsSelector)

  const [isLoading, setIsLoading] = useState(false)
  const [customLayouts, setCustomLayouts] = useState([])
  const [paging, setPaging] = useState({})
  const [deletedLayout, setDeletedLayout] = useState()
  const [visibleDeleteLayoutModal, setVisibleDeleteLayoutModal] = useState(false)

  const hasCreateAccess = () => grants.custom_layout.includes('create')
  const hasUpdateAccess = () => grants.custom_layout.includes('update')
  const hasDeleteAccess = () => grants.custom_layout.includes('delete')

  const TABLE_HEADERS = [
    {
      text: 'Title',
      value: 'title'
    },
    {
      text: 'Slug',
      value: 'slug'
    },
    {
      text: 'Created At',
      value: 'createdAt',
      date: true,
      hms: true
    },
    {
      text: 'Last Modified At',
      value: 'lastModifiedAt',
      date: true,
      hms: true
    },
    {
      text: '',
      value: 'delete',
      slot: item => (
        hasDeleteAccess() ? (
          <FaTrash
            className="cursor-pointer"
            onClick={e => {
              setDeletedLayout(item)
              e.stopPropagation()
            }}
          />
        ) : <></>
      )
    }
  ]

  useEffect(() => {
    fetchCustomLayouts()
  }, [])

  useDidUpdateEffect(() => {
    if (deletedLayout) {
      setVisibleDeleteLayoutModal(true)
    }
  }, [deletedLayout])

  const fetchCustomLayouts = async (page = 1) => {
    try {
      setIsLoading(true)

      const { data, paging } = await dispatch(getCustomLayouts({
        page,
        size: 10
      })).unwrap()
      setCustomLayouts(data)
      setPaging(paging)

      setIsLoading(false)
    } catch(error) {
      toast.error('Oops something wrong, please try again')
      setIsLoading(false)
    }
  }

  const doDeleteLayout = async () => {
    try {
      setIsLoading(true)

      await dispatch(deleteCustomLayout(deletedLayout.id)).unwrap()
      toast.success('Custom layout deleted')
      setDeletedLayout(null)
      setVisibleDeleteLayoutModal(false)

      await fetchCustomLayouts()

      setIsLoading(false)
    } catch(error) {
      toast.error('Oops something wrong, please try again')
      setIsLoading(false)
    }
  }

  return (
    <>
      {
        visibleDeleteLayoutModal && (
          <Modal visibleState={[visibleDeleteLayoutModal, setVisibleDeleteLayoutModal]}>
            <div className="flex flex-col">
              <div className="text-xl font-bold mb-2">
                Delete Custom Layout
              </div>
              <div>
                Are you sure to delete custom layout?
              </div>
              <div className="self-end mt-4">
                <button
                  type="button"
                  onClick={() => setVisibleDeleteLayoutModal(false)}
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-2"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
                  onClick={doDeleteLayout}
                >
                  Delete
                </button>
              </div>
            </div>
          </Modal>
        )
      }

      <Header title="Custom Layout">
        {isLoading && <FullPageLoader />}

        <section>
          {
            hasCreateAccess() && (
              <button
                type="button"
                className="mr-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
                onClick={() => navigate(path.content.customLayoutCreate)}
              >
                Create
              </button>
            )
          }

        <div className="mt-4">
          <Table
            headers={TABLE_HEADERS}
            items={customLayouts}
            isLoading={isLoading}
            {...hasUpdateAccess() && { onRowClick: item => navigate(path.content.customLayoutEdit.replace(':id', item.id)) }}
          />
          {
            !isLoading && !!customLayouts.length && (
              <div className="self-end mt-3">
                <Pagination
                  currentPage={paging.page}
                  totalPage={paging.totalPage}
                  setPage={page => fetchCustomLayouts(page)}
                />
              </div>
            )
          }
        </div>
        </section>
      </Header>
    </>
  )
}

export default CustomLayoutPage