import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getVouchers = createAsyncThunk(
  'voucher/getVouchers',
  async ({ page, size, search, status }, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.voucher, { params: { page, search, size, status } })
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const getVoucher = createAsyncThunk(
  'voucher/getVoucher',
  async (voucherId, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.voucherDetail(voucherId))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const saveVoucher = createAsyncThunk(
  'product/saveVoucher',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.voucher, body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const updateVoucher = createAsyncThunk(
  'product/updateVoucher',
  async ({ voucherId, body }, { rejectWithValue }) => {
    try {
      const response = await axios.put(api.voucherDetail(voucherId), body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const terminateVoucher = createAsyncThunk(
  'product/terminateVoucher',
  async (voucherId, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.voucherTerminate(voucherId))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const voucherSlice = createSlice({
  name: 'voucher',
  initialState: {},
  reducers: {}
})

export default voucherSlice.reducer