import React, { useState } from 'react'
import { numberFormatter, numberInput } from '@/utils/common'
import toast from 'react-hot-toast'

import Modal from '@/components/Modal'

const PriceAdjustmentModal = ({ visibleState, onSubmit, products }) => {
  const [visibleConfirmationModal, setVisibleConfirmationModal] = useState(false)

  const [reason, setReason] = useState('')
  const [adjustments, setAdjustments] = useState(products.map(p => ({
    sku: p._id,
    old: p.price.offer,
    new: p.price.offer,
    qty: p.quantity,
    name: p.name,
    image: p.images[0]
  })))

  const trySubmit = () => {
    if (!reason) {
      toast.error('Please fill adjustment reason!')
      return
    }

    const hasCheaperAdjustment = adjustments.some(adj => {
      const o = +adj.old
      const n = +adj.new
      return n - o < 0
    })
    if (hasCheaperAdjustment) {
      toast.error('Make sure all new price are higher than old price!')
      return
    }

    setVisibleConfirmationModal(true)
  }

  const submit = async () => {
    const adjusted = adjustments.filter(adj => +adj.new > +adj.old)
      .map(adj => ({
        sku: adj.sku,
        old: adj.old,
        new: +adj.new,
        qty: adj.qty
      }))
    await onSubmit(adjusted, reason)
  }

  const handlePriceChange = (e, sku) => {
    const updated = [...adjustments]
    const f = updated.find(u => u.sku === sku)
    f.new = e.target.value
    setAdjustments(updated)
  }

  return (
    <>
      <Modal
        visibleState={visibleState}
        width="w-2/3"
      >
        <div className="flex flex-col">
          <div className="text-xl font-bold mb-4">
            Adjust Price
          </div>

          <section className="mb-5">
            <div className="font-semibold mb-1">
              Reason
            </div>
            <input
              type="text"
              className="w-3/4 p-1.5 border border-gray-500 rounded-lg"
              placeholder="Reason is visible to customer"
              onChange={e => setReason(e.target.value)}
            />
          </section>

          <section className="flex flex-col gap-y-5">
            {
              adjustments.map(adjustment => (
                <div
                  key={adjustment.sku}
                  className="flex border border-gray-300"
                >
                  <img
                    src={adjustment.image}
                    className="w-36 object-cover aspect-square"
                    alt=""
                  />

                  <div className="p-3 flex flex-col w-full border">
                    <div className="font-bold">
                      {adjustment.sku}
                    </div>
                    <div
                      className="line-clamp-2"
                      title={adjustment.name}
                    >
                      {adjustment.name}
                    </div>

                    <div className="flex mt-auto">
                      <div className="w-1/2 self-end">
                        <div>
                          Offer price:
                        </div>
                        <div className="font-semibold">
                          <small className="mr-2">
                            x{adjustment.qty}
                          </small>
                          {numberFormatter(adjustment.old, 'Rp')}
                        </div>
                      </div>

                      <div className="w-1/2">
                        <div>
                          New price:
                        </div>
                        <div className="text-sm text-gray-500 mb-1.5">
                          Price per 1 quantity
                        </div>
                        <input
                          type="text"
                          className="w-full p-1.5 border border-gray-500 rounded-lg"
                          onKeyDown={numberInput}
                          onChange={e => handlePriceChange(e, adjustment.sku)}
                          value={adjustment.new}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </section>

          <div className="self-end mt-4">
            <button
              type="button"
              onClick={() => visibleState[1](false)}
              className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
              onClick={trySubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>

      <Modal visibleState={[visibleConfirmationModal, setVisibleConfirmationModal]}>
        <div className="flex flex-col">
          <div className="text-xl font-bold mb-2">
            Confirmation
          </div>

          <section>
            Customer will be notified via email, are you sure to submit price adjustment?
          </section>

          <div className="self-end mt-4">
            <button
              type="button"
              onClick={() => setVisibleConfirmationModal(false)}
              className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
              onClick={submit}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default PriceAdjustmentModal