import React, { useState } from 'react'

import ProductCard from '@/components/ProductCard'
import ProductSetComponent from '@/components/CustomLayout/ProductSetComponent'
import BaseItem from '@/components/CustomLayout/BaseItem'

const ProductSetItem = ({ component, index, onUpdateComponent, onRemoveComponent }) => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      {
        visible && (
          <ProductSetComponent
            visibleState={[visible, setVisible]}
            onSubmit={comp => onUpdateComponent(comp, index)}
            initial={component.products}
          />
        )
      }

      <BaseItem
        onUpdateComponent={() => setVisible(true)}
        onRemoveComponent={() => onRemoveComponent(component, index)}
      >
        <div className="grid w-full grid-cols-8 gap-6">
          {
            component.products.map(product => (
              <ProductCard
                key={product.sku}
                id={product.sku}
                name={product.displayName}
                brand={product.brand.name}
                image={product.images[0]}
                price={product.price.offer}
                originalPrice={product.price.original}
                availability={product.availability}
                stock={product.stock}
                hidden={product.hidden}
              />
            ))
          }
        </div>
      </BaseItem>
    </>
  )
}

export default ProductSetItem