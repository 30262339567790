import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { uploadCustomLayoutImage } from '@/store/slices/image'
import { FaUpload } from 'react-icons/fa'
import toast from 'react-hot-toast'
import classNames from 'classnames'
import { FaTimes } from 'react-icons/fa'
import { readFileAsDataUrl } from '@/utils/file'
import { ReactSortable } from 'react-sortablejs'
import useDidUpdateEffect from '@/hooks/did-update-effect'

import FullPageLoader from '@/components/FullPageLoader'
import Modal from '@/components/Modal'

const ORIENTATIONS = [
  {
    key: 'LANDSCAPE',
    text: 'Landscape'
  },
  {
    key: 'PORTRAIT',
    text: 'Portrait'
  }
]

const BannerComponent = ({ visibleState, onSubmit, initial }) => {
  const dispatch = useDispatch()

  const [images, setImages] = useState(() => {
    if (initial?.images?.length) {
      return initial?.images?.map(i => ({
        ...i,
        preview: i.url
      }))
    }
    return []
  })
  const [isLoading, setIsLoading] = useState(false)
  const [selectedOrientation, setSelectedOrientation] = useState(() => initial?.orientation || ORIENTATIONS[0].key)

  useDidUpdateEffect(() => {
    setImages([])
  }, [selectedOrientation])

  const handleImageChange = async e => {
    e.preventDefault && e.preventDefault()

    const file = e.target.files[0]
    if (!file) {
      return
    }

    const { width, height, src } = await readFileAsDataUrl(file)

    if (selectedOrientation === 'LANDSCAPE' && (width !== 600 || height !== 400)) {
      toast.error('Image must be 600x400 pixel')
      return
    }
    if (selectedOrientation === 'PORTRAIT' && (width !== 400 || height !== 600)) {
      toast.error('Image must be 400x600 pixel')
      return
    }

    setImages([
      ...images,
      {
        file,
        preview: src
      }
    ])
  }

  const removeImg = index => {
    setImages(images.filter((_, i) => i !== index))
  }

  const getImageUrls = async () => {
    const urls = []

    for (const image of images) {
      if (image.url && image.filename) {
        urls.push(image)
        continue
      }

      const form = new FormData()
      form.append('files', image.file)
      const { data = [] } = await dispatch(uploadCustomLayoutImage(form)).unwrap()
      const [imgResp] = data

      urls.push({
        filename: imgResp.name,
        url: imgResp.url
      })
    }

    return urls
  }

  const submit = async () => {
    if (!images.length) {
      toast.error('Please upload image')
      return
    }

    try {
      setIsLoading(true)

      const images = await getImageUrls()

      onSubmit && onSubmit({
        type: 'BANNER',
        orientation: selectedOrientation,
        images
      })

      visibleState[1](false)

      setImages([])

      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  return (
    <>
      {isLoading && <FullPageLoader />}
      <Modal visibleState={visibleState} width="w-1/2">
        <section className="flex flex-col">
          <div className="font-semibold text-lg">
            Banner
          </div>

          <hr className="my-3" />

          <section className="mt-2">
            <div className="flex gap-x-3 mb-4">
              {
                ORIENTATIONS.map(orientation => (
                  <button
                    key={orientation.key}
                    className={classNames({
                      'w-24 h-24 rounded shadow flex flex-col items-center p-2 border transition duration-300': true,
                      'border-blue-500': selectedOrientation === orientation.key
                    })}
                    onClick={() => setSelectedOrientation(orientation.key)}
                  >
                    {
                      orientation.key === 'LANDSCAPE' && (
                        <div className="bg-gray-400 w-[4rem] h-[1.2rem] rounded mt-auto" />
                      )
                    }
                    {
                      orientation.key === 'PORTRAIT' && (
                        <div className="bg-gray-400 h-[2.8rem] w-[1.2rem] rounded mt-auto" />
                      )
                    }
                    <div className="text-sm text-gray-500 mt-auto">
                      {orientation.text}
                    </div>
                  </button>
                ))
              }
            </div>

            <div className="text-sm text-gray-600 mb-4">
              {
                selectedOrientation === 'LANDSCAPE' && (
                  <div>1. Image must be 600x400 (WxH) pixel</div>
                )
              }
              {
                selectedOrientation === 'PORTRAIT' && (
                  <div>1. Image must be 400x600 (WxH) pixel</div>
                )
              }
              <div>2. You must upload min 1 and max 3 images</div>
            </div>

            <section className="flex flex-wrap gap-x-2">
              <ReactSortable
                list={images}
                setList={setImages}
                className="flex gap-x-4"
              >
                {
                  !!images.length && images.map((img, index) => (
                    <div key={index} className="relative">
                      <img
                        src={img.preview}
                        className={classNames({
                          'w-[200px] h-[300px]': selectedOrientation === 'PORTRAIT',
                          'w-[300px] h-[200px]': selectedOrientation === 'LANDSCAPE',
                        })}
                      />
                      <button>
                        <FaTimes
                          className="text-white absolute right-2 top-2 text-lg"
                          onClick={() => removeImg(index)}
                        />
                      </button>
                    </div>
                  ))
                }
              </ReactSortable>

              {
                images.length < 3 && (
                  <div>
                    <div className={classNames({
                      'border-2 border-dotted': true,
                      'w-[200px] h-[300px]': selectedOrientation === 'PORTRAIT',
                      'w-[300px] h-[200px]': selectedOrientation === 'LANDSCAPE',
                      'border-blue-300': true,
                      'border-red-500': false
                    })}>
                      <label htmlFor="image-input" className="w-full h-full flex flex-col items-center justify-center cursor-pointer">
                        <FaUpload className="text-3xl text-blue-300" />
                      </label>

                      <input
                        id="image-input"
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={handleImageChange}
                        onClick={e => {
                          e.target.value = ''
                        }}
                        style={{
                          width: '0.1px',
                          height: '0.1px',
                          opacity: '0',
                          overflow: 'hidden',
                          position: 'absolute',
                          zIndex: '-1'
                        }}
                      />
                    </div>
                  </div>
                )
              }
            </section>
          </section>

          <section className="mt-4 self-end">
            <button
              className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-2"
              onClick={() => visibleState[1](false)}
              type="button"
            >
              Cancel
            </button>
            <button
              className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
              onClick={submit}
            >
              Create
            </button>
          </section>
        </section>
      </Modal>
    </>
  )
}

export default BannerComponent