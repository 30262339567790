import React from 'react'

import './line-loader.scss'

const LineLoader = ({ className = '' }) => {
  return (
    <div className={className + ' loader'}>
      <div className="loader-item" />
    </div>
  )
}

export default LineLoader