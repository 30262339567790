import React, { useState } from 'react'

import BaseItem from '@/components/CustomLayout/BaseItem'
import BannerComponent from '@/components/CustomLayout/BannerComponent'

const BannerItem = ({ component, index, onUpdateComponent, onRemoveComponent }) => {
  const [visible, setVisible] = useState(false)
  return (
    <>
      {
        visible && (
          <BannerComponent
            visibleState={[visible, setVisible]}
            onSubmit={comp => onUpdateComponent(comp, index)}
            initial={component}
          />
        )
      }

      <BaseItem
        onUpdateComponent={() => setVisible(true)}
        onRemoveComponent={() => onRemoveComponent(component, index)}
      >
        <div className={['grid w-full gap-x-4', `grid-cols-${component.images.length}`].join(' ')}>
          {
            component.images.map((img, index) => (
              <div key={index}>
                <img
                  src={img.url}
                  className="w-full"
                />
              </div>
            ))
          }
        </div>
      </BaseItem>
    </>
  )
}

export default BannerItem