import React, { useEffect, useState } from 'react'
import { FaUserAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logout } from '@/store/slices/user'
import { useSelector } from 'react-redux'
import { userSelector, userGrantsSelector } from '@/store/slices/user'
import config from '@/constant/config'
import logo from '@/assets/logo.png'

import HeaderMenuItem from '@/components/HeaderMenuItem'

const { path } = config

const ATTRIBUTES_MENUS = [
  {
    key: 'CATEGORY',
    text: 'Category',
    path: path.attribute.category,
    resource: 'category:read'
  },
  {
    key: 'BRAND',
    text: 'Brand',
    path: path.attribute.brand,
    resource: 'brand:read'
  },
  {
    key: 'CHARACTER',
    text: 'Character',
    path: path.attribute.character,
    resource: 'character:read'
  },
  {
    key: 'SERIES',
    text: 'Series',
    path: path.attribute.series,
    resource: 'series:read'
  }
]

const ENGAGEMENT_MENUS = [
  {
    key: 'BANNER',
    text: 'Banner',
    path: path.engagement.banner,
    resource: 'banner:read'
  },
  {
    key: 'VOUCHER',
    text: 'Voucher',
    path: path.engagement.voucher,
    resource: 'voucher:read'
  },
  {
    key: 'NEWSLETTER',
    text: 'Newsletter',
    path: path.engagement.newsletter,
    resource: 'newsletter:read'
  }
]

const CONTENT_MENUS = [
  {
    key: 'HOMEPAGE_PRODUCT',
    text: 'Homepage Product',
    path: path.content.homepageProduct,
    resource: 'homepage_product:read'
  },
  {
    key: 'FEATURED_PRODUCT',
    text: 'Featured Product',
    path: path.content.featuredProduct,
    resource: 'featured_product:read'
  },
  {
    key: 'STORE_LOCATION',
    text: 'Store Location',
    path: path.content.storeLocation,
    resource: 'store_location:read'
  },
  {
    key: 'CUSTOM_LAYOUT',
    text: 'Custom Layout',
    path: path.content.customLayout,
    resource: 'custom_layout:read'
  }
]

const TOOLS_MENUS = [
  {
    key: 'IMAGE_UPLOADER',
    text: 'Image Uploader',
    path: path.tools.imageUploader,
    resource: 'product:create'
  }
]

const ACCESSIBILITY_MENUS = [
  {
    key: 'USER',
    text: 'User',
    path: path.accessibility.user,
    resource: 'user:read'
  },
  {
    key: 'ROLE',
    text: 'Role',
    path: path.accessibility.role,
    resource: 'role:read'
  }
]

const MENUS = [
  {
    key: 'PRODUCT',
    text: 'Product',
    path: path.product,
    resource: 'product:read'
  },
  {
    key: 'ATTRIBUTE',
    text: 'Attribute',
    path: path.attribute.base,
    children: ATTRIBUTES_MENUS
  },
  {
    key: 'ORDER',
    text: 'Order',
    path: path.order.base,
    resource: 'order:read'
  },
  {
    key: 'ENGAGEMENT',
    text: 'Engagement',
    path: path.engagement.base,
    children: ENGAGEMENT_MENUS
  },
  {
    key: 'CONTENT',
    text: 'Content',
    path: path.content.base,
    children: CONTENT_MENUS
  },
  {
    key: 'ACCESSIBILITY',
    text: 'Accessibility',
    path: path.accessibility.base,
    children: ACCESSIBILITY_MENUS
  },
  {
    key: 'TOOLS',
    text: 'Tools',
    path: path.tools.base,
    children: TOOLS_MENUS
  }
]

const BaseLayout = ({ children }) => {
  const { user } = useSelector(userSelector)
  const grants = useSelector(userGrantsSelector)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [visibleUserMenuModal, setVisibleUserMenuModal] = useState(false)
  const [menus, setMenus] = useState(MENUS)

  const doLogout = async () => {
    await dispatch(logout()).unwrap()
    navigate(path.login)
  }

  const toEditUserPage = () => {
    navigate(path.user)
  }

  useEffect(() => {
    const hasAccessToMenu = menu => {
      if (!menu.resource) {
        return true
      }
      const [resource, action] = menu.resource.split(':')
      return grants[resource] && grants[resource].includes(action)
    }

    const adjustedMenus = menus.filter(hasAccessToMenu)
      .map(menu => {
        if (menu.children) {
          return {
            ...menu,
            children: menu.children.filter(childMenu => hasAccessToMenu(childMenu))
          }
        }
        return menu
      })
      .filter(menu => !menu.children || !!menu.children.length)
    setMenus(adjustedMenus)
  }, [])

  return (
    <div className="flex flex-col">
      <div className="w-full py-6 px-32 bg-white shadow">

        <div className="flex justify-between items-center">
          <img
            src={logo}
            className="w-32 cursor-pointer"
            onClick={() => navigate(path.base)}
          />

          <div
            className="relative"
            onMouseEnter={() => setVisibleUserMenuModal(true)}
            onMouseLeave={() => setVisibleUserMenuModal(false)}
          >
            <div className="flex items-center">
              <FaUserAlt />
              <div className="ml-2 font-bold text-xl cursor-pointer">
                Hi, {user?.name}
              </div>
            </div>

            {
              visibleUserMenuModal && (
                <div className="absolute right-0">
                  <div className="h-1.5"></div>
                  <div className="bg-white px-6 py-3 w-48 shadow rounded text-right">
                    <div
                      onClick={toEditUserPage}
                      className="cursor-pointer mb-2"
                    >
                      Edit account
                    </div>
                    <div
                      onClick={doLogout}
                      className="cursor-pointer"
                    >
                      Logout
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>

        <div className="flex mt-4">
          {
            menus.map(menu => (
              <HeaderMenuItem
                key={menu.key}
                menu={menu}
              />
            ))
          }
        </div>
      </div>

      {children}
    </div>
  )
}

export default BaseLayout