import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getCategories = createAsyncThunk(
  'category/getCategories',
  async ({ page, size, search }, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.category, { params: { page, search, size } })
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const getCategoryDetail = createAsyncThunk(
  'category/getCategoryDetail',
  async (categoryId, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.categoryDetail(categoryId))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const saveCategory = createAsyncThunk(
  'category/saveCategory',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.category, body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const updateCategory = createAsyncThunk(
  'category/updateCategory',
  async ({ categoryId, body }, { rejectWithValue }) => {
    try {
      const response = await axios.put(api.categoryDetail(categoryId), body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const deleteCategory = createAsyncThunk(
  'category/deleteCategory',
  async (categoryId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(api.categoryDetail(categoryId))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const categorySlice = createSlice({
  name: 'category',
  initialState: {},
  reducers: {}
})

export default categorySlice.reducer
