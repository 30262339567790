import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getNewsletterDetail } from '@/store/slices/newsletter'
import toast from 'react-hot-toast'
import config from '@/constant/config'

import Header from '@/components/Header'
import FullPageLoader from '@/components/FullPageLoader'

const NewsletterDetailPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { id }= useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [newsletterDetail, setNewsletterDetail] = useState({})

  useEffect(() => {
    fetchNewsletterDetail()
  }, [])

  const fetchNewsletterDetail = async () => {
    try {
      setIsLoading(true)

      const { data } = await dispatch(getNewsletterDetail(id)).unwrap()
      setNewsletterDetail(data)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const getRecipients = (fields, title) => {
    if (!fields?.length) {
      return <></>
    }

    return <>
      <div className="mt-4 font-semibold mb-1 text-sm">
        {title}
      </div>
      <div>
        {
          fields.map(field => (
            <div key={field}>
              {field}
            </div>
          ))
        }
      </div>
    </>
  }

  return (
    <Header title="Newsletter Detail">
      {isLoading && <FullPageLoader />}

      {
        !isLoading && (
          <section className="flex flex-col">
            <div className="font-semibold mb-1 text-sm">
              Subject
            </div>
            <input
              type="text"
              className="w-1/2 p-2 border border-gray-300 rounded-lg"
              value={newsletterDetail.subject}
              disabled
            />

            <div className="mt-4 font-semibold mb-1 text-sm">
              Content
            </div>
            <div
              className="border p-2 react-draft-preview"
              dangerouslySetInnerHTML={{ __html: newsletterDetail.content }}
            />

            {getRecipients(newsletterDetail.recipients, 'Recipients')}
            {getRecipients(newsletterDetail.sent, 'Sent')}
            {getRecipients(newsletterDetail.failed, 'Failed')}

            <div className="flex mt-6">
              <button
                type="button"
                className="px-6 py-2.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-3"
                onClick={() => navigate(config.path.engagement.newsletter)}
              >
                Back
              </button>
            </div>
          </section>
        )
      }
    </Header>
  )
}

export default NewsletterDetailPage