import React, { useEffect, useState } from 'react'
import { getCharacters } from '@/store/slices/character'
import { useDispatch } from 'react-redux'
import debounce from '@/utils/debouncer'
import toast from 'react-hot-toast'

import Dropdown from '@/components/Dropdown'

const CharacterDropdown = ({ form, error, value, onItemChange, disabled, disposable, useForm = true }) => {
  const dispatch = useDispatch()

  const [characters, setCharacters] = useState({ data: [], paging: {} })
  const [characterSearch, setCharacterSearch] = useState(undefined)
  const [isFetchingCharacter, setIsFetchingCharacter] = useState(false)

  useEffect(() => {
    if (useForm) {
      form.register('character')
    }
  }, [])

  useEffect(() => {
    if (characterSearch === undefined) {
      return
    }
    debounce(() => fetchCharacter(1, false), 200, 'character-search')
  }, [characterSearch])

  const fetchCharacter = async (page, append = true) => {
    try {
      setIsFetchingCharacter(true)

      const characterResponses = await dispatch(getCharacters({ page, search: characterSearch })).unwrap()
      setCharacters({
        data: append ? [...characters.data, ...characterResponses.data] : characterResponses.data,
        paging: characterResponses.paging
      })

      setIsFetchingCharacter(false)
    } catch(err) {
      toast.error('Oops something wrong, please try again')
      setIsFetchingCharacter(false)
    }
  }

  const getNextPageCharacter = () => {
    if (characters.paging.page >= characters.paging.totalPage) {
      return
    }

    fetchCharacter(characters.paging.page + 1)
  }

  const characterDropdownItems = () => (characters.data || []).map(b => ({ key: b.id, value: b.name }))

  const handleOnItemChange = selectedItem => {
    if (useForm) {
      form.setValue('character', selectedItem)
      form.trigger('character')
    }
    onItemChange && onItemChange(selectedItem)
  }

  return (
    <Dropdown
      name="Select character"
      items={characterDropdownItems()}
      emptyMessage="No Characters Yet"
      searchState={[characterSearch, setCharacterSearch]}
      onDropdownOpen={() => fetchCharacter(1, false)}
      isLoading={isFetchingCharacter}
      onIntersecting={getNextPageCharacter}
      onItemChange={handleOnItemChange}
      error={error}
      value={value}
      disposable={disposable}
      disabled={disabled}
    />
  )
}

export default CharacterDropdown