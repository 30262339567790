import React, { useEffect, useState } from 'react'
import className from 'classnames'
import { useNavigate } from 'react-router-dom'
import config from '@/constant/config'
import { getVouchers } from '@/store/slices/voucher'
import { userGrantsSelector } from '@/store/slices/user'
import { useDispatch, useSelector } from 'react-redux'
import debounce from '@/utils/debouncer'
import toast from 'react-hot-toast'

import Pagination from '@/components/Pagination'
import Header from '@/components/Header'
import Table from '@/components/Table'

const { path, wording } = config

const VOUCHER_FILTERS = [
  {
    text: 'All',
    value: 'ALL'
  },
  {
    text: 'Active',
    value: 'ACTIVE'
  },
  {
    text: 'Upcoming',
    value: 'UPCOMING'
  },
  {
    text: 'Passed',
    value: 'PASSED'
  },
  {
    text: 'Terminated',
    value: 'TERMINATED'
  }
]

const TABLE_HEADERS = [
  {
    text: 'Code',
    value: 'code'
  },
  {
    text: 'Quota',
    value: 'quota'
  },
  {
    text: 'Start Date',
    value: 'startDate',
    date: true
  },
  {
    text: 'End Date',
    value: 'endDate',
    date: true
  },
  {
    text: 'Status',
    value: 'status',
    slot: item => (
      <div className={className({
        'p-1 text-center rounded-full text-white': true,
        'bg-green-500': item.status === 'ACTIVE',
        'bg-blue-500': item.status === 'UPCOMING',
        'bg-gray-400': item.status === 'PASSED',
        'bg-red-500': item.status === 'TERMINATED'
      })}>
        {wording.voucher.status[item.status]}
      </div>
    )
  }
]

const VoucherPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const grants = useSelector(userGrantsSelector)

  const [selectedFilter, setSelectedFilter] = useState(VOUCHER_FILTERS[0])
  const [isFetchingVoucher, setIsFetchingVoucher] = useState(false)
  const [vouchers, setVouchers] = useState([])
  const [paging, setPaging] = useState([])
  const [search, setSearch] = useState('')


  useEffect(() => {
    fetchVouchers()
  }, [search, selectedFilter])

  const fetchVouchers = async (page = 1) => {
    try {
      setIsFetchingVoucher(true)

      const params = {
        page,
        search,
        ...(selectedFilter.value !== 'ALL' ? { status: selectedFilter.value } : {})
      }

      const voucherResponse = await dispatch(getVouchers(params)).unwrap()
      setVouchers(voucherResponse.data)
      setPaging(voucherResponse.paging)

      setIsFetchingVoucher(false)
    } catch(err) {
      toast.error('Oops something wrong, please try again')
      setIsFetchingVoucher(false)
    }
  }

  const hasCreateVoucherAccess = () => grants.voucher?.includes('create')

  const hasUpdateVoucherAccess = () => grants.voucher?.includes('update')

  const handleSearch = e => {
    debounce(() => setSearch(e.target.value), 300, 'search')
  }

  return (
    <Header title="Voucher">
      <div className="flex items-center">
        <div className="flex">
          {
            VOUCHER_FILTERS.map(filter => (
              <button
                key={filter.value}
                className={className({
                  'py-4 px-6 font-semibold cursor-pointer': true,
                  'text-blue-600': filter.value === selectedFilter.value
                })}
                onClick={() => setSelectedFilter(filter)}
              >
                {filter.text}
              </button>
            ))
          }
        </div>

        {
          hasCreateVoucherAccess() && (
            <button
                className="ml-auto mr-2 py-2 px-10 border border-gray-700 bg-gray-700 text-white rounded-full"
                onClick={() => navigate(path.engagement.voucherCreate)}
              >
                Create
              </button>
          )
        }

      </div>

      <hr className="mt-2 mb-4" />

      <input
        type="text"
        className="p-2 w-1/4 border border-gray-300 rounded-lg mb-8 mt-4"
        placeholder="Search voucher"
        onChange={handleSearch}
      />

      <Table
        headers={TABLE_HEADERS}
        items={vouchers}
        isLoading={isFetchingVoucher}
        {...hasUpdateVoucherAccess() && { onRowClick: item => navigate(path.engagement.voucherDetail.replace(':voucherId', item.id)) }}
      />

      {
        !isFetchingVoucher && !!vouchers.length && (
          <div className="self-end mt-3">
            <Pagination
              currentPage={paging.page}
              totalPage={paging.totalPage}
              setPage={page => fetchVouchers(page)}
            />
          </div>
        )
      }
    </Header>
  )
}

export default VoucherPage