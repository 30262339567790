import React, { forwardRef } from 'react'

const RadioButton = forwardRef(({ id, text, className = '', ...props }, ref) => {
  return (
    <div className={'flex items-center ' + className}>
      <input
        id={id}
        type="radio"
        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
        {...props}
        ref={ref}
      />
      <label
        htmlFor={id}
        className="ml-2 text-sm font-medium"
      >
        {text}
      </label>
    </div>
  )
})

export default RadioButton