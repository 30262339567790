import React, { useEffect, useState } from 'react'
import {
  getSeries,
  saveSeries,
  updateSeries,
  deleteSeries
} from '@/store/slices/series'
import { userGrantsSelector } from '@/store/slices/user'
import { useDispatch, useSelector } from 'react-redux'
import debounce from '@/utils/debouncer'
import toast from 'react-hot-toast'

import Header from '@/components/Header'
import Pagination from '@/components/Pagination'
import AttributeTableItem from '@/components/AttributeTableItem'
import TableLoader from '@/components/TableLoader'

const SeriesPage = () => {
  const dispatch = useDispatch()

  const grants = useSelector(userGrantsSelector)

  const [series, setSeries] = useState([])
  const [paging, setPaging] = useState({})
  const [search, setSearch] = useState('')
  const [seriesName, setSeriesName] = useState('')
  const [isFetchingSeries, setIsFetchingSeries] = useState(false)

  
  useEffect(() => {
    fetchSeries()
  }, [search])

  const fetchSeries = async (page = 1) => {
    try {
      setIsFetchingSeries(true)

      const seriesResponses = await dispatch(getSeries({ page, search })).unwrap()
      setSeries(seriesResponses.data)
      setPaging(seriesResponses.paging)

      setIsFetchingSeries(false)
    } catch(err) {
      toast.error('Oops something wrong, please try again')
      setIsFetchingSeries(false)
    }
  }

  const hasCreateSeriesAccess = () => grants.series?.includes('create')

  const hasUpdateSeriesAccess = () => grants.series?.includes('update')

  const hasDeleteSeriesAccess = () => grants.series?.includes('delete')

  const handleSearch = e => {
    debounce(() => setSearch(e.target.value), 300, 'search')
  }

  const createSeries = async () => {
    if (seriesName.length < 3) {
      toast.error('Series name must have minimal 3 letter\'s')
      return
    }

    try {
      await dispatch(saveSeries({ name: seriesName })).unwrap()
      toast.success('Success creating ' + seriesName)
      setSeriesName('')
      fetchSeries()
    } catch(err) {
      toast.error('Fail creating ' + seriesName + ', please try again')
    }
  }

  const onEdit = async (seriesId, name) => {
    if (name.length < 3) {
      toast.error('Series name must have minimal 3 letter\'s')
      return
    }

    try {
      await dispatch(updateSeries({ seriesId, body: { name } })).unwrap()
      toast.success('Series edited')
      fetchSeries()
    } catch(err) {
      toast.error('Fail editing ' + seriesName + ', please try again')
    }
  }

  const onDelete = async series => {
    try {
      await dispatch(deleteSeries(series.id)).unwrap()
      toast.success('Series deleted')
      fetchSeries()
    } catch(err) {
      if (err.data.error === 'SERIES_IN_USE') {
        toast.error(`${series.name} is still used on product or banner`)
        return
      }
      toast.error('Fail deleting ' + series.name + ', please try again')
    }
  }

  return (
    <Header title="Series">
      <div className="flex items-center justify-between mb-8 mt-4">
        <input
          type="text"
          className="p-2 w-1/4 border border-gray-300 rounded-lg mr-4"
          placeholder="Search"
          onChange={handleSearch}
        />

        {
          hasCreateSeriesAccess() && (
            <>
              <input
                type="text"
                className="p-2 ml-auto w-1/5 border border-gray-300 rounded-lg mr-4"
                placeholder="Series name"
                value={seriesName}
                onChange={e => setSeriesName(e.target.value)}
                disabled={isFetchingSeries}
              />
              <button
                className="py-2 px-10 bg-gray-700 text-white rounded-full"
                onClick={createSeries}
                disabled={isFetchingSeries}
              >
                Create
              </button>
            </>
          )
        }

      </div>

      <table className="w-full">
        <thead className="border border-gray-300 w-full">
          <tr>
            <th width="4%" className="p-4 text-left">
              No
            </th>
            <th className="p-4 text-left">
              Name
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody className="border border-gray-300">
          {
            isFetchingSeries && <TableLoader />
          }
          {
            !isFetchingSeries && series.map((series, idx) => (
              <AttributeTableItem
                key={series.id}
                index={idx}
                number={((paging.page - 1) * paging.size) + idx + 1}
                name={series.name}
                onEdit={newName => onEdit(series.id, newName)}
                onDelete={() => onDelete(series)}
                hasEditAccess={hasUpdateSeriesAccess()}
                hasDeleteAccess={hasDeleteSeriesAccess()}
              />
            ))
          }
        </tbody>
      </table>

      {
        !isFetchingSeries && !!series.length && (
          <div className="self-end mt-3">
            <Pagination
              currentPage={paging.page}
              totalPage={paging.totalPage}
              setPage={page => fetchSeries(page)}
            />
          </div>
        )
      }
    </Header>
  )
}

export default SeriesPage