import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import debounce from '@/utils/debouncer'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getOrderList } from '@/store/slices/order'
import classNames from 'classnames'
import { toFormattedDate, numberFormatter } from '@/utils/common'
import config from '@/constant/config'

import Pagination from '@/components/Pagination'
import Header from '@/components/Header'
import OrderStatusLabel from '@/components/OrderStatusLabel'
import ProductAvailabilityLabel from '@/components/ProductAvailabilityLabel'
import ExportOrderModal from '@/components/ExportOrderModal'

const { path } = config

const ORDER_STATUS_FILTER = config.order.status

const OrderPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [selectedFilter, setSelectedFilter] = useState(ORDER_STATUS_FILTER[0])
  const [isFetchingOrder, setIsFetchingOrder] = useState(false)
  const [orders, setOrders] = useState([])
  const [paging, setPaging] = useState([])
  const [search, setSearch] = useState('')
  const [visibleExportOrderModal, setVisibleExportOrderModal] = useState()

  useEffect(() => {
    fetchOrderList()
  }, [search, selectedFilter])

  const fetchOrderList = async (page = 1) => {
    try {
      setIsFetchingOrder(true)

      const params = {
        page,
        search,
        size: 10,
        status: selectedFilter.value
      }
      const response =await dispatch(getOrderList(params)).unwrap()
      setOrders(response.data)
      setPaging(response.paging)

      setIsFetchingOrder(false)
    } catch(error) {
      setIsFetchingOrder(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const handleSearch = e => {
    debounce(() => setSearch(e.target.value), 300, 'search')
  }

  return (
    <>
      <ExportOrderModal visibleState={[visibleExportOrderModal, setVisibleExportOrderModal]} />

      <Header
        title="Order"
        slot={() => (
          <button
            type="button"
            className="ml-auto py-2 px-10 border border-gray-700 bg-white text-gray-700 rounded-full"
            onClick={() => setVisibleExportOrderModal(true)}
          >
            Export
          </button>
        )}
      >
        <div className="flex">
          {
            ORDER_STATUS_FILTER.map(filter => (
              <button
                key={filter.value}
                className={classNames({
                  'py-4 px-6 font-semibold cursor-pointer line-clamp-1': true,
                  'text-blue-600': filter.value === selectedFilter.value
                })}
                onClick={() => setSelectedFilter(filter)}
              >
                {filter.text}
              </button>
            ))
          }
        </div>

        <hr className="mt-2 mb-4" />

        <input
          type="text"
          className="p-2 w-1/3 border border-gray-300 rounded-lg mb-8 mt-4"
          placeholder="Input order id, user id, or product sku"
          onChange={handleSearch}
        />

        <section>
          {
            orders.map(order => (
              <div
                key={order.id}
                className="border border-gray-300 rounded w-full mb-8 cursor-pointer"
                onClick={() => navigate(path.order.detail.replace(':orderId', order.id))}
              >
                <div className="p-4 w-full bg-gray-100">
                  <div className="flex justify-between items-center font-semibold text-sm">
                    <div>
                      Order Id: {order.id}
                    </div>
                    <div>
                      User Id: {order.userId}
                    </div>
                    <div>
                      Order Date: {toFormattedDate(order.date)}
                    </div>
                    <OrderStatusLabel status={order.status} />
                  </div>
                </div>

                <section className="p-6">
                  {
                    order.items?.map((item, index) => (
                      <div key={item.id}>
                        <div className="font-bold mb-2">
                          Order {index + 1}
                        </div>

                        <div className="flex">
                          <div className="w-3/4 flex">
                            <img
                              src={item.product.image}
                              className="w-24 aspect-square object-contain bg-gray-200"
                              alt="product image"
                            />

                            <div className="mx-3 flex flex-col items-start">
                              <div className="flex">
                                <strong>
                                  {item.product.sku}
                                </strong>
                                <div className="ml-2">
                                  <ProductAvailabilityLabel availability={item.product.availability} />
                                </div>
                              </div>
                              <div
                                className="font-semibold line-clamp-2 mt-2"
                                title={item.product.name}
                              >
                                {item.product.name}
                              </div>
                              {
                                item.product.downPayment && (
                                  <small className="text-gray-500">
                                    50% Down Payment
                                  </small>
                                )
                              }
                              <div className="text-sm mt-2">
                                {item.product.quantity}x &nbsp;&nbsp;
                                {numberFormatter(item.product.price, 'Rp')}
                              </div>
                            </div>
                          </div>

                          <div>
                            <div className="font-semibold mb-2">
                              Subtotal
                            </div>
                            {
                              item.subtotal.map(total => (
                                <div
                                  key={total.id}
                                  className="flex gap-x-4 text-sm justify-between"
                                >
                                  <div>
                                    {total.text}
                                  </div>
                                  <div>
                                    {numberFormatter(total.nominal, 'Rp')}
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>

                        {
                          item.totalProducts > 1 && (
                            <div className="text-sm text-blue-900 font-semibold mt-3">
                              + {item.totalProducts - 1} more products
                            </div>
                          )
                        }

                        {order.items?.length !== index + 1 && <hr className="my-4" />}
                      </div>
                    ))
                  }
                </section>

                <hr />

                <div className="p-4 w-full flex items-center justify-between">
                  <div className="w-1/2">
                    Total Price: &nbsp;
                    <strong>
                      {numberFormatter(order.totalPrice, 'Rp')}
                    </strong>
                  </div>

                  {
                    order.notes && (
                      <div
                        className="text-sm italic w-1/2 line-clamp-6"
                        title={order.notes}
                      >
                        Notes: {order.notes}
                      </div>
                    )
                  }
                </div>
              </div>
            ))
          }
        </section>

        {
          !isFetchingOrder && !!orders.length && (
            <div className="self-end mt-3">
              <Pagination
                currentPage={paging.page}
                totalPage={paging.totalPage}
                setPage={page => fetchOrderList(page)}
              />
            </div>
          )
        }
      </Header>
    </>
  )
}

export default OrderPage