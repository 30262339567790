import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getBanners = createAsyncThunk(
  'banner/getBanners',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.banner)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const getBanner = createAsyncThunk(
  'banner/getBanner',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(api.bannerDetail(id))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const saveBanner = createAsyncThunk(
  'banner/saveBanner',
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.banner, body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const updateBanner = createAsyncThunk(
  'banner/updateBanner',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const response = await axios.put(api.bannerDetail(id), body)
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const updateBannerSequence = createAsyncThunk(
  'banner/updateBannerSequence',
  async ({ id, sequence }, { rejectWithValue }) => {
    try {
      const response = await axios.put(api.bannerSequence(id), null, {
        params: { sequence }
      })
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const deleteBanner = createAsyncThunk(
  'banner/deleteBanner',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(api.bannerDetail(id))
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const bannerSlice = createSlice({
  name: 'banner',
  initialState: {},
  reducers: {}
})

export default bannerSlice.reducer