import React, { useEffect, useState } from 'react'
import { userGrantsSelector } from '@/store/slices/user'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '@/store/slices/user'
import { useNavigate } from 'react-router-dom'
import config from '@/constant/config'
import toast from 'react-hot-toast'

import Header from '@/components/Header'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination'

const { path } = config

const TABLE_HEADERS = [
  {
    text: 'Email',
    value: 'email'
  },
  {
    text: 'Name',
    value: 'name'
  },
  {
    text: 'Role',
    value: 'role'
  }
]

const UserAdminPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const grants = useSelector(userGrantsSelector)

  const [users, setUsers] = useState([])
  const [isFetchingUsers, setIsFetchingUsers] = useState(false)
  const [paging, setPaging] = useState({})

  const fetchUsers = async () => {
    try {
      setIsFetchingUsers(true)
      const { data, paging } = await dispatch(getUser({ page: 1 })).unwrap()
      setUsers(data)
      setPaging(paging)
      setIsFetchingUsers(false)
    } catch(err) {
      setIsFetchingUsers(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  const hasCreateUserAccess = () => grants.user?.includes('create')

  const hasUpdateUserAccess = () => grants.user?.includes('update')

  return (
    <Header title="User">
      <div className="my-4">
        {
          hasCreateUserAccess() && (
            <button
              className="mr-2 py-2 px-10 border border-gray-700 bg-gray-700 text-white rounded-full"
              onClick={() => navigate(path.accessibility.userCreate)}
            >
              Create
            </button>
          )
        }
      </div>

      <Table
        headers={TABLE_HEADERS}
        items={users}
        isLoading={isFetchingUsers}
        {...hasUpdateUserAccess() && { onRowClick: item => navigate(path.accessibility.userEdit.replace(':userId', window.btoa(item.email))) }}
      />

      {
        !isFetchingUsers && !!users.length && (
          <div className="self-end mt-3">
            <Pagination
              currentPage={paging.page}
              totalPage={paging.totalPage}
              setPage={page => fetchUsers(page)}
            />
          </div>
        )
      }
    </Header>
  )
}

export default UserAdminPage