import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const uploadBannerImage = createAsyncThunk(
  'image/uploadBannerImage',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.bannerImage, formData ,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const uploadProductImage = createAsyncThunk(
  'image/uploadProductImage',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.productImage, formData ,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const deleteProductImage = createAsyncThunk(
  'image/deleteProductImage',
  async (filename, { rejectWithValue }) => {
    try {
      const response = await axios.delete(api.productImage , {
        params: {
          filename
        }
      })
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const uploadStoreLocationImage = createAsyncThunk(
  'image/uploadStoreLocationImage',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.storeLocationImage, formData ,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const uploadCustomLayoutImage = createAsyncThunk(
  'image/uploadCustomLayoutImage',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(api.customLayoutImage, formData ,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const deleteImage = createAsyncThunk(
  'image/deleteImage',
  async (filenames, { rejectWithValue }) => {
    try {
      const response = await axios.delete(api.image , {
        params: {
          filenames
        }
      })
      return response.data
    } catch(err) {
      return rejectWithValue(toResponse(err.response))
    }
  }
)

export const imageSlice = createSlice({
  name: 'image',
  initialState: {},
  reducers: {}
})

export default imageSlice.reducer