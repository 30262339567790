import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaAngleDown } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

const HeaderMenuItem = ({ menu }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [visibleChildMenu, setVisibleChildMenu] = useState(false)

  const toPage = menu => {
    if (menu.children && menu.children.length) {
      return
    }
    navigate(menu.path)
    setVisibleChildMenu(false)
  }

  return (
    <a
      className="relative mr-5"
      onMouseEnter={() => setVisibleChildMenu(true)}
      onMouseLeave={() => setVisibleChildMenu(false)}
    >
      <div
        className={classNames({
          'flex items-center': true,
          'text-blue-600': location.pathname.includes(menu.path)
        })}
        onClick={() => toPage(menu)}
      >
        <div className="font-semibold cursor-pointer">
          {menu.text}
        </div>
        {!!menu.children && !!menu.children.length && <FaAngleDown className="ml-1 mt-0.5" />}
      </div>

      {
        !!menu.children && !!menu.children.length && visibleChildMenu && (
          <div className="absolute">
            <div className="h-1.5" />
            <div className="bg-white w-56 shadow rounded px-6 py-3 flex flex-col">
              {
                menu.children.map(childMenu => (
                  <div
                    key={childMenu.key}
                    className={classNames({
                      'mb-3 cursor-pointer': true,
                      'text-blue-600': location.pathname.includes(childMenu.path)
                    })}
                    onClick={() => toPage(childMenu)}
                  >
                    {childMenu.text}
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
    </a>
  )
}

export default HeaderMenuItem