import config from '@/constant/config'
import { Outlet, createBrowserRouter } from 'react-router-dom'

import ProtectedRoute from '@/components/ProtectedRoute'
import BaseLayout from '@/components/BaseLayout'

import HomePage from '@/pages/HomePage'
import CategoryPage from '@/pages/CategoryPage'
import BrandPage from '@/pages/BrandPage'
import CharacterPage from '@/pages/CharacterPage'
import SeriesPage from '@/pages/SeriesPage'
import LoginPage from '@/pages/LoginPage'
import EditUserPage from '@/pages/EditUserPage'
import ProductPage from '@/pages/ProductPage'
import CreateProductPage from '@/pages/CreateProductPage'
import EditProductPage from '@/pages/EditProductPage'
import ImageUploaderPage from '@/pages/ImageUploaderPage'
import BannerPage from '@/pages/BannerPage'
import VoucherPage from '@/pages/VoucherPage'
import CreateVoucherPage from '@/pages/CreateVoucherPage'
import EditVoucherPage from '@/pages/EditVoucherPage'
import UserAdminPage from '@/pages/UserAdminPage'
import RoleAdminPage from '@/pages/RoleAdminPage'
import CreateRolePage from '@/pages/CreateRolePage'
import EditRolePage from '@/pages/EditRolePage'
import CreateUserPage from '@/pages/CreateUserPage'
import EditUserAdminPage from '@/pages/EditUserAdminPage'
import OrderPage from '@/pages/OrderPage'
import OrderDetailPage from '@/pages/OrderDetailPage'
import ContentHomepageProduct from '@/pages/ContentHomepageProduct'
import NewsletterPage from '@/pages/NewsletterPage'
import CreateNewsletterPage from '@/pages/CreateNewsletterPage'
import NewsletterDetailPage from '@/pages/NewsletterDetailPage'
import FeaturedProductPage from '@/pages/FeaturedProductPage'
import CreateFeaturedProductPage from '@/pages/CreateFeaturedProductPage'
import EditFeaturedProductPage from '@/pages/EditFeaturedProductPage'
import StoreLocationPage from '@/pages/StoreLocationPage'
import CreateStoreLocationPage from '@/pages/CreateStoreLocationPage'
import EditStoreLocationPage from '@/pages/EditStoreLocationPage'
import CustomLayoutPage from '@/pages/CustomLayoutPage'
import CreateCustomLayoutPage from '@/pages/CreateCustomLayoutPage'
import EditCustomLayoutPage from '@/pages/EditCustomLayoutPage'

const { path, role } = config
const { resource } = role

const Parent = ({ resource, action = 'read', children }) => (
  <ProtectedRoute resource={resource} action={action}>
    <BaseLayout>
      {children}
    </BaseLayout>
  </ProtectedRoute>
)

const router = createBrowserRouter([
  {
    element: <Outlet />,
    children: [
      {
        path: path.base,
        element: (
          <Parent>
            <HomePage />
          </Parent>
        ),
        index: true
      },
      {
        path: path.login,
        element: <LoginPage />
      },
      {
        element: <Outlet />,
        children: [
          {
            path: path.attribute.category,
            element: (
              <Parent resource={resource.CATEGORY}>
                <CategoryPage />
              </Parent>
            )
          },
          {
            path: path.attribute.brand,
            element: (
              <Parent resource={resource.BRAND}>
                <BrandPage />
              </Parent>
            )
          },
          {
            path: path.attribute.character,
            element: (
              <Parent resource={resource.CHARACTER}>
                <CharacterPage />
              </Parent>
            )
          },
          {
            path: path.attribute.series,
            element: (
              <Parent resource={resource.SERIES}>
                <SeriesPage />
              </Parent>
            )
          }
        ]
      },
      {
        element: <Outlet />,
        children: [
          {
            path: path.user,
            element: (
              <Parent>
                <EditUserPage />
              </Parent>
            ),
            index: true
          }
        ]
      },
      {
        element: <Outlet />,
        children: [
          {
            path: path.product,
            element: (
              <Parent resource={resource.PRODUCT}>
                <ProductPage />
              </Parent>
            ),
            index: true
          },
          {
            path: path.productCreate,
            element: (
              <Parent resource={resource.PRODUCT} action="create">
                <CreateProductPage />
              </Parent>
            )
          },
          {
            path: path.productEdit,
            element: (
              <Parent resource={resource.PRODUCT} action="update">
                <EditProductPage />
              </Parent>
            )
          }
        ]
      },
      {
        element: <Outlet />,
        children: [
          {
            path: path.engagement.banner,
            element: (
              <Parent resource={resource.BANNER}>
                <BannerPage />
              </Parent>
            )
          },
          {
            path: path.engagement.voucher,
            element: (
              <Parent resource={resource.VOUCHER}>
                <VoucherPage />
              </Parent>
            )
          },
          {
            path: path.engagement.voucherCreate,
            element: (
              <Parent resource={resource.VOUCHER} action="create">
                <CreateVoucherPage />
              </Parent>
            )
          },
          {
            path: path.engagement.voucherDetail,
            element: (
              <Parent resource={resource.VOUCHER} action="update">
                <EditVoucherPage />
              </Parent>
            )
          },
          {
            path: path.engagement.newsletter,
            element: (
              <Parent resource={resource.NEWSLETTER}>
                <NewsletterPage />
              </Parent>
            )
          },
          {
            path: path.engagement.newsletterCreate,
            element: (
              <Parent resource={resource.NEWSLETTER} action="create">
                <CreateNewsletterPage />
              </Parent>
            )
          },
          {
            path: path.engagement.newsletterDetail,
            element: (
              <Parent resource={resource.NEWSLETTER}>
                <NewsletterDetailPage />
              </Parent>
            )
          }
        ]
      },
      {
        element: <Outlet />,
        children: [
          {
            path: path.content.homepageProduct,
            element: (
              <Parent resource={resource.HOMEPAGE_PRODUCT}>
                <ContentHomepageProduct />
              </Parent>
            )
          },
          {
            path: path.content.featuredProduct,
            element: (
              <Parent resource={resource.FEATURED_PRODUCT}>
                <FeaturedProductPage />
              </Parent>
            )
          },
          {
            path: path.content.featuredProductCreate,
            element: (
              <Parent resource={resource.FEATURED_PRODUCT} action="create">
                <CreateFeaturedProductPage />
              </Parent>
            )
          },
          {
            path: path.content.featuredProductEdit,
            element: (
              <Parent resource={resource.FEATURED_PRODUCT} action="update">
                <EditFeaturedProductPage />
              </Parent>
            )
          },
          {
            path: path.content.storeLocation,
            element: (
              <Parent resource={resource.STORE_LOCATION}>
                <StoreLocationPage />
              </Parent>
            )
          },
          {
            path: path.content.storeLocationCreate,
            element: (
              <Parent resource={resource.STORE_LOCATION} action="create">
                <CreateStoreLocationPage />
              </Parent>
            )
          },
          {
            path: path.content.storeLocationEdit,
            element: (
              <Parent resource={resource.STORE_LOCATION} action="update">
                <EditStoreLocationPage />
              </Parent>
            )
          },
          {
            path: path.content.customLayout,
            element: (
              <Parent resource={resource.CUSTOM_LAYOUT}>
                <CustomLayoutPage />
              </Parent>
            )
          },
          {
            path: path.content.customLayoutCreate,
            element: (
              <Parent resource={resource.CUSTOM_LAYOUT} action="create">
                <CreateCustomLayoutPage />
              </Parent>
            )
          },
          {
            path: path.content.customLayoutEdit,
            element: (
              <Parent resource={resource.CUSTOM_LAYOUT} action="update">
                <EditCustomLayoutPage />
              </Parent>
            )
          }
        ]
      },
      {
        element: <Outlet />,
        children: [
          {
            path: path.accessibility.user,
            element: (
              <Parent resource={resource.USER}>
                <UserAdminPage />
              </Parent>
            )
          },
          {
            path: path.accessibility.role,
            element: (
              <Parent resource={resource.ROLE}>
                <RoleAdminPage />
              </Parent>
            )
          },
          {
            path: path.accessibility.roleCreate,
            element: (
              <Parent resource={resource.ROLE} action="create">
                <CreateRolePage />
              </Parent>
            )
          },
          {
            path: path.accessibility.roleEdit,
            element: (
              <Parent resource={resource.ROLE} action="update">
                <EditRolePage />
              </Parent>
            )
          },
          {
            path: path.accessibility.userCreate,
            element: (
              <Parent resource={resource.USER} action="create">
                <CreateUserPage />
              </Parent>
            )
          },
          {
            path: path.accessibility.userEdit,
            element: (
              <Parent resource={resource.USER} action="update">
                <EditUserAdminPage />
              </Parent>
            )
          }
        ]
      },
      {
        element: <Outlet />,
        children: [
          {
            path: path.tools.imageUploader,
            element: (
              <Parent resource={resource.PRODUCT} action="create">
                <ImageUploaderPage />
              </Parent>
            )
          }
        ]
      },
      {
        element: <Outlet />,
        children: [
          {
            path: path.order.base,
            element: (
              <Parent resource={resource.ORDER}>
                <OrderPage />
              </Parent>
            )
          }
        ]
      },
      {
        element: <Outlet />,
        children: [
          {
            path: path.order.detail,
            element: (
              <Parent resource={resource.ORDER}>
                <OrderDetailPage />
              </Parent>
            )
          }
        ]
      },
      {
        path: '*',
        element: <div>Not Found</div>
      }
    ]
  }
])

export default router