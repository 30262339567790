import React, { useEffect, useState } from 'react'
import { userGrantsSelector } from '@/store/slices/user'
import { useDispatch, useSelector } from 'react-redux'
import { getRoles } from '@/store/slices/role'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import config from '@/constant/config'

import Header from '@/components/Header'
import Table from '@/components/Table'

const { path } = config

const TABLE_HEADERS = [
  {
    text: 'Name',
    value: 'name'
  }
]

const RoleAdminPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const grants = useSelector(userGrantsSelector)

  const [roles, setRoles] = useState([])
  const [isFetchingRoles, setIsFetchingRoles] = useState(false)

  const fetchRoles = async () => {
    try {
      setIsFetchingRoles(true)

      const { data } = await dispatch(getRoles()).unwrap()
      setRoles(data)

      setIsFetchingRoles(false)
    } catch(error) {
      setIsFetchingRoles(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  useEffect(() => {
    fetchRoles()
  }, [])

  const hasCreateRoleAccess = () => grants.role?.includes('create')

  const hasUpdateRoleAccess = () => grants.role?.includes('update')

  return (
    <Header title="Role">
      <div className="my-4">
        {
          hasCreateRoleAccess() && (
            <button
              className="mr-2 py-2 px-10 border border-gray-700 bg-gray-700 text-white rounded-full"
              onClick={() => navigate(path.accessibility.roleCreate)}
            >
              Create
            </button>
          )
        }
      </div>

      <Table
        headers={TABLE_HEADERS}
        items={roles}
        isLoading={isFetchingRoles}
        {...hasUpdateRoleAccess() && { onRowClick: item => navigate(path.accessibility.roleEdit.replace(':roleId', item.name)) }}
      />
    </Header>
  )
}

export default RoleAdminPage