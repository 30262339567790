import React from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import PropTypes from 'prop-types'
import tw from 'tailwind-styled-components'

const Button = tw.button`p-2 cursor-pointer`

const Pagination = ({ currentPage, totalPage, setPage }) => {
  const previousPage = () => {
    if (currentPage - 1 < 1) {
      return
    }
    setPage(currentPage - 1)
  }

  const nextPage = () => {
    if (currentPage + 1 > totalPage) {
      return
    }
    setPage(currentPage + 1)
  }

  return (
    <div className="flex items-center">
      <FaChevronLeft
        className="cursor-pointer"
        onClick={previousPage}
      />

      {
        currentPage > 1 && (
          <Button onClick={() => setPage(1)}>
            1
          </Button>
        )
      }

      {
        currentPage > 2 && (
          <>
            <div className="p-2">
              ...
            </div>

            <Button onClick={() => setPage(currentPage - 1)}>
              {currentPage - 1}
            </Button>
          </>
        )
      }

      <Button className="bg-gray-800 text-white rounded-full mx-1.5 py-1 px-2.5">
        {currentPage}
      </Button>

      {
        currentPage < totalPage - 1 && (
          <>
            <Button onClick={() => setPage(currentPage + 1)}>
              {currentPage + 1}
            </Button>

            <div className="p-2">
              ...
            </div>
          </>
        )
      }

      {
        currentPage < totalPage && (
          <Button onClick={() => setPage(totalPage)}>
            {totalPage}
          </Button>
        )
      }

      <FaChevronRight
        className="cursor-pointer"
        onClick={nextPage}
      />
    </div>
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  setPage: PropTypes.func
}

export default Pagination