import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { saveRole } from '@/store/slices/role'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import config from '@/constant/config'
import classNames from 'classnames'
import toast from 'react-hot-toast'
import { toCapitalCase } from '../utils/common'

import Header from '@/components/Header'
import FullPageLoader from '@/components/FullPageLoader'

const { resource } = config.role
const { path } = config

const actions = [
  'read',
  'create',
  'update',
  'delete'
]

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  grants: yup.array().of(yup.string()).min(1)
})

const CreateRolePage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isSavingRole, setIsSavingRole] = useState()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const createRole = async data => {
    try {
      setIsSavingRole(true)

      const grantsObject = data.grants.reduce((acc, grant) => {
        const [resource, action] = grant.split(':')
        return {
          ...acc,
          [resource]: {
            resource,
            actions: acc[resource] ? [...acc[resource].actions, action] : [action]
          }
        }
      }, {})

      const requestBody = {
        name: data.name,
        grants: Object.values(grantsObject)
      }
      await dispatch(saveRole(requestBody)).unwrap()

      setIsSavingRole(false)
      navigate(path.accessibility.role)
      toast.success('Success creating role')
    } catch(err) {
      setIsSavingRole(false)

      if (err.data.error === 'ROLE_ALREADY_EXIST') {
        toast.error(`Role with name ${data.name} already exist`)
        return
      }
      toast.error('Oops something wrong, please try again')
    }
  }

  return (
    <>
      {isSavingRole && <FullPageLoader />}
      <Header title="Create Role">
        <form
          onSubmit={handleSubmit(createRole)}
          className="flex flex-col"
        >
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-1">
              <div className="font-semibold mb-1 text-sm">
                Name
              </div>
              <input
                type="text"
                className={classNames({
                  'w-full p-2 border border-gray-300 rounded-lg': true,
                  'border-red-500': !!errors.name
                })}
                {...register('name')}
              />
              {
                errors.name && (
                  <small className="ml-2 text-red-500">
                    Name must not empty
                  </small>
                )
              }
            </div>

            <div className="col-span-3">
              <div className="font-semibold">
                Grants
              </div>

              {
                errors.grants && (
                  <small className="text-red-500">
                    Select at least 1 grant
                  </small>
                )
              }
            </div>

            {
              Object.values(resource).map(r => (
                <div
                  className="col-span-1 border border-gray-300 p-3 rounded"
                  key={r}
                >
                  <div className="font-semibold text-sm mb-2">
                    {toCapitalCase(r)}
                  </div>

                  <div>
                    {
                      actions.map((action, i) => (
                        <div
                          className="my-1"
                          key={i}
                        >
                          <div className="flex items-center">
                            <input
                              id={`${r}:${action}`}
                              type="checkbox"
                              value={`${r}:${action}`}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                              {...register('grants')}
                              disabled={
                                `${r}:${action}` === 'order:create' ||
                                `${r}:${action}` === 'order:delete' ||
                                `${r}:${action}` === 'homepage_product:create' ||
                                `${r}:${action}` === 'homepage_product:delete' ||
                                `${r}:${action}` === 'newsletter:update'
                              }
                            />
                            <label
                              htmlFor={`${r}:${action}`}
                              className="ml-2 text-sm"
                            >
                              {toCapitalCase(action)}
                            </label>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))
            }
          </div>

          <div className="flex ml-auto mt-5">
            <button
              type="button"
              className="mr-2 py-2 px-10 border border-gray-700 bg-white text-black rounded-full"
              onClick={() => navigate(path.accessibility.role)}
            >
              Back
            </button>
            <input
              type="submit"
              value="Create"
              className="py-2 px-10 border border-gray-700 bg-gray-700 text-white rounded-full cursor-pointer"
            />
          </div>
        </form>
      </Header>
    </>
  )
}

export default CreateRolePage