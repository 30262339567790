import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { deleteFeaturedProduct } from '@/store/slices/featured-product'
import { FaTrash, FaEdit } from 'react-icons/fa'
import config from '@/constant/config'
import toast from 'react-hot-toast'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import { userGrantsSelector } from '@/store/slices/user'

import ProductCard from '@/components/ProductCard'
import Modal from '@/components/Modal'
import FullPageLoader from '@/components/FullPageLoader'

import 'swiper/css'
import 'swiper/css/navigation'

const FeaturedProductItem = ({
  id,
  title,
  hidden,
  products,
  onSuccessDelete
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const grants = useSelector(userGrantsSelector)

  const [isLoading, setIsLoading] = useState(false)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)

  const hasEditFeaturedProductAccess = () => grants.featured_product.includes('update')
  const hasDeleteFeaturedProductAccess = () => grants.featured_product.includes('delete')

  const doDeleteFeaturedProduct = async () => {
    try {
      setIsLoading(true)

      await dispatch(deleteFeaturedProduct(id)).unwrap()
      setVisibleDeleteModal(false)
      onSuccessDelete && onSuccessDelete()
      setIsLoading(false)

      toast.success('Featured product deleted')
    } catch (error) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  return (
    <>
      {isLoading && <FullPageLoader />}

      <Modal visibleState={[visibleDeleteModal, setVisibleDeleteModal]}>
        <section className="flex flex-col">
          <div className="font-bold text-xl mb-2">
            Confirmation
          </div>
          <div>
            Are you sure to delete this featured product?
          </div>
          <div className="self-end mt-4">
            <button
              type="button"
              onClick={() => setVisibleDeleteModal(false)}
              className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-2"
            >
              Cancel
            </button>
            <input
              type="submit"
              className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
              onClick={doDeleteFeaturedProduct}
              value="Delete"
            />
          </div>
        </section>
      </Modal>

      <div className="border border-dotted border-blue-500 p-3.5">
        <div className="flex justify-between">
          <div className="flex items-center gap-x-2">
            <div className="font-bold text-xl">
              {title}
            </div>
            <small className="text-gray-500 italic">
              {
                hidden ? '(Hidden)' : ''
              }
            </small>
          </div>

          <div className="flex gap-x-4">
            {
              hasEditFeaturedProductAccess() && (
                <button
                  type="button"
                  onClick={() => navigate(config.path.content.featuredProductEdit.replace(':id', id))}
                >
                  <FaEdit className="text-blue-500" />
                </button>
              )
            }
            {
              hasDeleteFeaturedProductAccess() && (
                <button
                  type="button"
                  onClick={() => setVisibleDeleteModal(true)}
                >
                  <FaTrash className="text-blue-500" />
                </button>
              )
            }
          </div>
        </div>

        <Swiper
          className="mt-1.5 h-full"
          spaceBetween={10}
          slidesPerView={10}
          modules={[Navigation]}
          navigation={{
            enabled: true
          }}
        >
          {
            products.map(product => (
              <SwiperSlide
                key={product.sku}
                className="h-full"
              >
                <ProductCard
                  id={product.sku}
                  name={product.displayName}
                  brand={product.brand.name}
                  image={product.images[0]}
                  price={product.price.offer}
                  originalPrice={product.price.original}
                  availability={product.availability}
                  stock={product.stock}
                  hidden={product.hidden}
                />
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </>
  )
}

export default FeaturedProductItem