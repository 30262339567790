import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import toast from 'react-hot-toast'
import { numberInput, toAsiaJakartaZone } from '@/utils/common'
import DatePicker from 'react-datepicker'
import config from '@/constant/config'
import { useNavigate } from 'react-router-dom'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import classNames from 'classnames'
import debounce from '@/utils/debouncer'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateProduct,
  getProductDetail,
  deleteProduct,
  hideProduct,
  unhideProduct
} from '@/store/slices/product'
import { userGrantsSelector } from '@/store/slices/user'
import { uploadProductImage } from '@/store/slices/image'
import { FaUpload } from 'react-icons/fa'

import Header from '@/components/Header'
import BrandDropdown from '@/components/BrandDropdown'
import CategoryDropdown from '@/components/CategoryDropdown'
import CharacterDropdown from '@/components/CharacterDropdown'
import SeriesDropdown from '@/components/SeriesDropdown'
import Dropdown from '@/components/Dropdown'
import FullPageLoader from '@/components/FullPageLoader'
import Modal from '@/components/Modal'
import Switch from '@/components/Switch'
import ProductImage from '@/components/ProductImage'
import Spinner from '@/components/Spinner'

const ProductSection = ({ title, children }) => (
  <div>
    <div className="font-bold mb-4">
      {title}
    </div>
    {children}
    <hr className="my-6" />
  </div>
)

const validationSchema = yup.object().shape({
  sku: yup.string().required(),
  name: yup.string().required(),
  janCode: yup.string().required(),
  weight: yup.string().required(),
  width: yup.string().nullable(),
  length: yup.string().nullable(),
  height: yup.string().nullable(),
  brand: yup.object().shape({
    key: yup.string().required(),
    value: yup.string().required()
  }),
  category: yup.object().shape({
    key: yup.string().required(),
    value: yup.string().required()
  }),
  series: yup.object().shape({
    key: yup.string().required(),
    value: yup.string().required()
  }),
  character: yup.object().shape({
    key: yup.string().required(),
    value: yup.string().required()
  }),
  images: yup.array().min(1),
  originalPrice: yup.string().required(),
  offerPrice: yup.string().required(),
  description: yup.object().test('validDescription', 'Description is a required field', content => content?.blocks?.length && content?.blocks[0]?.text !== ''),
  availability: yup.string().required(),
  endDate: yup.date()
    .when('availability', ([availability], field) => {
      if (availability === 'PREORDER' || availability === 'LATE_PREORDER' || availability === 'WAITING_LIST') {
        return field.required('End date is required')
      }
      return field.nullable()
    }),
  estimatedArrivalDate: yup.date()
    .when('availability', ([availability], field) => {
      if (availability === 'PREORDER' || availability === 'LATE_PREORDER' || availability === 'WAITING_LIST') {
        return field.required('Estimated arrival date is required')
      }
      return field.nullable()
    }),
  maxQuantityPerOrder: yup.string().nullable(),
  stock: yup.string()
    .when('availability', ([availability], field) => {
      if (availability === 'PREORDER' || availability === 'LATE_PREORDER' || availability === 'READY_STOCK') {
        return field.required('Stock is required')
      }
      return field.nullable()
    })
})

const EditProductPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { sku } = useParams()

  const grants = useSelector(userGrantsSelector)

  const {
    register,
    handleSubmit,
    setValue,
    control,
    resetField,
    reset,
    trigger,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      images: []
    }
  })

  const selectedAvailability = useWatch({
    control,
    name: 'availability'
  })

  const images = useWatch({
    control,
    name: 'images'
  })

  const [endDate, setEndDate] = useState(null)
  const [estimatedArrivalDate, setEstimatedArrivalDate] = useState(null)
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const [isLoading, setIsLoading] = useState(false)
  const [isGettingProductDetail, setIsGettingProductDetail] = useState(false)
  const [visibleDeleteProductModal, setVisibleDeleteProductModal] = useState(false)
  const [productDetail, setProductDetail] = useState({})
  const [isHidden, setIsHidden] = useState()
  const [isUploadingImage, setIsUploadingImage] = useState(false)

  useEffect(() => {
    register('availability')
    register('endDate')
    register('estimatedArrivalDate')
    register('description')
    register('images')

    const populateForm = async () => {
      try {
        setIsGettingProductDetail(true)
  
        const { data } = await dispatch(getProductDetail(sku)).unwrap()

        setProductDetail(data)

        const { brand, category, character, series } = data
  
        reset({
          sku: data.sku,
          name: data.name,
          janCode: data.janCode,
          weight: data.weight,
          width: data.width,
          length: data.length,
          height: data.height,
          originalPrice: data.price.original,
          offerPrice: data.price.offer,
          category: {
            key: category.id,
            value: category.name
          },
          brand: {
            key: brand.id,
            value: brand.name
          },
          character: {
            key: character.id,
            value: character.name
          },
          series: {
            key: series.id,
            value: series.name
          },
          maxQuantityPerOrder: data.maxQuantityPerOrder,
          stock: data.stock,
          availability: data.availability
        })

        if (data.description) {
          const blocksFromHtml = htmlToDraft(data.description)
          const { contentBlocks, entityMap } = blocksFromHtml
          const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
          setEditorState(EditorState.createWithContent(contentState))
        }

        setValue('images', data.images.map(img => {
          const parts = img.split('/')
          return { url: img, name: parts[parts.length - 1] }
        }))

        data.endDate && setEndDate(new Date(data.endDate))
        data.estimatedArrivalDate && setEstimatedArrivalDate(new Date(data.estimatedArrivalDate))

        setIsHidden(data.hidden)

        setIsGettingProductDetail(false)
      } catch(err) {
        setIsGettingProductDetail(false)
        if (err?.data?.error === 'PRODUCT_NOT_FOUND') {
          navigate(config.path.product)
          return
        }
        toast.error('Oops something wrong, please try again')
      }
    }
    populateForm()
  }, [])

  useEffect(() => {
    setValue('endDate', endDate)
  }, [endDate])

  useEffect(() => {
    setValue('estimatedArrivalDate', estimatedArrivalDate)
  }, [estimatedArrivalDate])

  useEffect(() => {
    debounce(() => setValue('description', convertToRaw(editorState.getCurrentContent())), 300, 'update-description')
  }, [editorState])

  const hasDeleteProductAccess = () => grants.product?.includes('delete')

  const getRequestBody = (data, status) => ({
    sku: data.sku,
    name: data.name,
    janCode: data.janCode,
    weight: +data.weight,
    width: data.width ? +data.width : undefined,
    length: data.length ? +data.length : undefined,
    height: data.height ? +data.height : undefined,
    brand: data.brand?.key,
    category: data.category?.key,
    series: data.series?.key,
    character: data.character?.key,
    price: {
      original: data.originalPrice ? +data.originalPrice : undefined,
      offer: data.offerPrice ? +data.offerPrice : undefined
    },
    images: images.map(i => i.url),
    description: data.description && draftToHtml(data.description),
    status,
    availability: data.availability,
    endDate: data.endDate && toAsiaJakartaZone(data.endDate.getTime()),
    estimatedArrivalDate: data.estimatedArrivalDate && toAsiaJakartaZone(data.estimatedArrivalDate.getTime()),
    maxQuantityPerOrder: data.maxQuantityPerOrder ? +data.maxQuantityPerOrder : undefined,
    stock: data.stock && +data.stock
  })

  const editDraftProduct = async () => {
    try {
      setIsLoading(true)

      const form = getValues()
      await dispatch(updateProduct({ sku: form.sku, body: getRequestBody(form, 'DRAFT') })).unwrap()
      toast.success('Success updating product')

      setIsLoading(false)
      navigate(config.path.product)
    } catch(err) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const editPublishedProduct = async data => {
    try {
      setIsLoading(true)

      await dispatch(updateProduct({ sku: data.sku, body: getRequestBody(data, 'PUBLISHED') })).unwrap()
      toast.success('Success updating product')

      setIsLoading(false)
      navigate(config.path.product)
    } catch(err) {
      setIsLoading(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const doDeleteProduct = async () => {
    try {
      setIsLoading(true)

      await dispatch(deleteProduct(sku)).unwrap()
      toast.success('Success deleting product')
      navigate(config.path.product)
      setVisibleDeleteProductModal(false)

      setIsLoading(false)
    } catch(err) {
      setIsLoading(false)

      if (err.data?.error === 'PRODUCT_IS_USED') {
        toast.error('There\'s still active order/voucher that uses this product')
        return
      }
      toast.error('Oops something wrong, please try again')
    }
  }

  const hideOrUnhideProduct = async e => {
    if (e.target.checked) {
      try {
        await dispatch(hideProduct(sku)).unwrap()
        setIsHidden(true)
        toast.success('Product hidden')
      } catch(err) {
        toast.error('Oops something wrong, please try again')
      }
      return
    }
    
    try {
      await dispatch(unhideProduct(sku)).unwrap()
      setIsHidden(false)
      toast.success('Product visible')
    } catch(err) {
      toast.error('Oops something wrong, please try again')
    }
  }

  const availabilityChangeHandler = selectedItem => {
    setValue('availability', selectedItem.key)
    setEndDate(null)
    setEstimatedArrivalDate(null)
    resetField('stock')
    resetField('maxQuantityPerOrder')
    trigger('availability')
  }

  const basicInputElement = (name, title, isNumberInput = false, optional = false) => (
    <>
      <div className="font-semibold mb-1 text-sm flex">
        <div>
          {title}
        </div>
        {
          optional && (
            <small className="text-gray-400 ml-1">(Optional)</small>
          )
        }
      </div>
      <input
        type="text"
        placeholder={title}
        className={classNames({
          'w-full p-2 border border-gray-300 rounded-lg': true,
          'border-red-500': !!errors[name]
        })}
        {...isNumberInput && { onKeyDown: numberInput }}
        {...register(name)}
      />
      {
        errors[name] && (
          <small className="ml-2 text-red-500">
            {title} must not empty
          </small>
        )
      }
    </>
  )

  const handleImageChange = async e => {
    try {
      setIsUploadingImage(true)

      const form = new FormData()
      form.append('files', e.target.files[0])
      const response = await dispatch(uploadProductImage(form)).unwrap()
      const [uploadedImage] = response.data

      setValue('images', [...(images || []), uploadedImage])
      trigger('images')

      setIsUploadingImage(false)
    } catch(err) {
      setIsUploadingImage(false)
      toast.error('Oops something wrong, please try again')
    }
  }

  const removeImage = async img => {
    setValue('images', images.filter(i => i.url !== img.url))
  }

  const visibleStockInput = () => {
    return (
      selectedAvailability === 'READY_STOCK' ||
      selectedAvailability === 'PREORDER' ||
      selectedAvailability === 'LATE_PREORDER'
    )
  }

  const visibleNonReadyStockInput = () => {
    return (
      selectedAvailability === 'WAITING_LIST' ||
      selectedAvailability === 'PREORDER' ||
      selectedAvailability === 'LATE_PREORDER'
    )
  }

  return (
    <Header title="Edit Product">
      {
        visibleDeleteProductModal && (
          <Modal visibleState={[visibleDeleteProductModal, setVisibleDeleteProductModal]}>
            <div className="flex flex-col">
              <div className="text-xl font-bold mb-2">
                Delete Product
              </div>
              <div>
                Are you sure to delete {sku}?
              </div>
              <div className="self-end mt-4">
                <button
                  type="button"
                  onClick={() => setVisibleDeleteProductModal(false)}
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-2"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="ml-auto px-6 py-1.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
                  onClick={doDeleteProduct}
                >
                  Delete
                </button>
              </div>
            </div>
          </Modal>
        )
      }

      {(isLoading || isGettingProductDetail) && <FullPageLoader />}

      {
        !isGettingProductDetail && (
          <form
            onSubmit={handleSubmit(editPublishedProduct)}
            className="flex flex-col"
          >
            <ProductSection title="Information">
              <div className="grid grid-cols-4 gap-4 mt-4">
                <div className="col-span-1 ">
                  <div className="font-semibold mb-1 text-sm">
                    Sku
                  </div>
                  <input
                    type="text"
                    placeholder="Sku"
                    className={classNames({
                      'w-full p-2 border border-gray-300 rounded-lg': true,
                      'border-red-500': !!errors.sku
                    })}
                    disabled
                    {...register('sku')}
                  />
                </div>

                <div className="col-span-1">
                  {basicInputElement('janCode', 'JAN Code')}
                </div>

                <div className="col-span-2">
                  {basicInputElement('name', 'Name')}
                </div>

                <div className="col-span-1">
                  {basicInputElement('originalPrice', 'Original Price', true)}
                </div>

                <div className="col-span-1">
                  {basicInputElement('offerPrice', 'Offer Price', true)}
                </div>

                {
                  !!productDetail && productDetail.status === 'PUBLISHED' && (
                    <div className="col-span-1">
                      <div className="font-semibold mb-1 text-sm">
                        Hide Product
                      </div>
                      <Switch onChange={hideOrUnhideProduct} checked={isHidden} />
                    </div>
                  )
                }
              </div>
            </ProductSection>

            <ProductSection title="Availability">
              <div className="grid grid-cols-4 gap-4 mt-4">
                <div className="col-span-1">
                  <Dropdown
                    name="Select availability"
                    items={config.product.availability}
                    onItemChange={availabilityChangeHandler}
                    error={!!errors.availability}
                    value={config.product.availability.find(a => a.key === selectedAvailability)}
                  />
                  {
                    !!errors.availability && (
                      <small className="ml-2 text-red-500">
                        Select availability
                      </small>
                    )
                  }
                </div>

                <div className="col-span-3" />

                {
                  visibleStockInput() && (
                    <div className="col-span-1">
                      {basicInputElement('stock', 'Stock', true)}
                    </div>
                  )
                }

                {
                  visibleNonReadyStockInput() && (
                    <>
                      <div className="col-span-1">
                        <div className="font-semibold mb-1 text-sm">
                          End Date
                        </div>
                        <DatePicker
                          selected={endDate}
                          onChange={date => setEndDate(date)}
                          dateFormat="dd/MM/yyyy"
                          className={classNames({
                            'w-full text-left p-2 border border-gray-300 rounded-lg': true,
                            'border-red-500': !!errors.endDate?.message
                          })}
                        />
                        {
                          !!errors.endDate?.message && (
                            <small className="ml-2 text-red-500">
                              Select end date
                            </small>
                          )
                        }
                      </div>

                      <div className="col-span-1">
                        <div className="font-semibold mb-1 text-sm">
                          Estimated Arrival Date
                        </div>
                        <DatePicker
                          selected={estimatedArrivalDate}
                          onChange={date => setEstimatedArrivalDate(date)}
                          showMonthYearPicker
                          dateFormat="MMMM yyyy"
                          className={classNames({
                            'w-full text-left p-2 border border-gray-300 rounded-lg': true,
                            'border-red-500': !!errors.estimatedArrivalDate?.message
                          })}
                        />
                        {
                          !!errors.estimatedArrivalDate?.message && (
                            <small className="ml-2 text-red-500">
                              Select estimated arrival date
                            </small>
                          )
                        }
                      </div>

                      <div className="col-span-1">
                        {basicInputElement('maxQuantityPerOrder', 'Max Quantity Per Order', true, true)}
                      </div>
                    </>
                  )
                }
              </div>
            </ProductSection>

            <ProductSection title="Attributes">
              <div className="grid grid-cols-4 gap-4 mt-4">
                <div className="col-span-1">
                  <div className="font-semibold mb-1 text-sm">
                    Category
                  </div>
                  <CategoryDropdown
                    form={{ register, setValue, trigger }}
                    error={!!errors.category}
                    value={getValues('category')}
                  />
                  {
                    !!errors.category && (
                      <small className="ml-2 text-red-500">
                        Select category
                      </small>
                    )
                  }
                </div>

                <div className="col-span-1">
                  <div className="font-semibold mb-1 text-sm">
                    Brand
                  </div>
                  <BrandDropdown
                    form={{ register, setValue, trigger }}
                    error={!!errors.brand}
                    value={getValues('brand')}
                  />
                  {
                    !!errors.brand && (
                      <small className="ml-2 text-red-500">
                        Select brand
                      </small>
                    )
                  }
                </div>

                <div className="col-span-1">
                  <div className="font-semibold mb-1 text-sm">
                    Character
                  </div>
                  <CharacterDropdown
                    form={{ register, setValue, trigger }}
                    error={!!errors.character}
                    value={getValues('character')}
                  />
                  {
                    !!errors.character && (
                      <small className="ml-2 text-red-500">
                        Select character
                      </small>
                    )
                  }
                </div>

                <div className="col-span-1">
                  <div className="font-semibold mb-1 text-sm">
                    Series
                  </div>
                  <SeriesDropdown
                    form={{ register, setValue, trigger }}
                    error={!!errors.series}
                    value={getValues().series}
                  />
                  {
                    !!errors.series && (
                      <small className="ml-2 text-red-500">
                        Select series
                      </small>
                    )
                  }
                </div>
              </div>
            </ProductSection>

            <ProductSection title="Dimension">
              <div className="grid grid-cols-4 gap-4 mt-4">
                <div className="col-span-1">
                  {basicInputElement('weight', 'Weight (gr)', true)}
                </div>

                <div className="col-span-1">
                  {basicInputElement('width', 'Width (cm)', true, true)}
                </div>

                <div className="col-span-1">
                  {basicInputElement('length', 'Length (cm)', true, true)}
                </div>

                <div className="col-span-1">
                  {basicInputElement('height', 'Height (cm)', true, true)}
                </div>
              </div>
            </ProductSection>

            <ProductSection title="Image">
              <div className="mt-4 flex flex-wrap gap-4 items-center">
                {
                  images.map((img, i) => (
                    <ProductImage
                      key={i}
                      url={img.url}
                      onRemove={() => removeImage(img)}
                    />
                  ))
                }
                {
                  isUploadingImage && (
                    <div className="w-48 h-48 mr-5 flex items-center justify-center border border-gray-200">
                      <Spinner />
                    </div>
                  )
                }
                {
                  !isUploadingImage && images.length < 10 && (
                    <div className={classNames({
                      'w-48 h-48 border-2 border-dotted': true,
                      'border-blue-300': !errors.images?.message,
                      'border-red-500': !!errors.images?.message
                    })}>
                      <label htmlFor="image-input" className="w-full h-full flex flex-col items-center justify-center cursor-pointer">
                        <FaUpload className="text-3xl text-blue-300" />
                        <div className="mt-1 text-blue-300">
                          Upload
                        </div>
                      </label>
                      <input
                        id="image-input"
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={handleImageChange}
                        style={{
                          width: '0.1px',
                          height: '0.1px',
                          opacity: '0',
                          overflow: 'hidden',
                          position: 'absolute',
                          zIndex: '-1'
                        }}
                      />
                    </div>
                  )
                }
              </div>
              {
                !!errors.images?.message && (
                  <small className="ml-2 text-red-500">
                    Upload minimal 1 image
                  </small>
                )
              }
            </ProductSection>

            <ProductSection title="Description">
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                toolbar={{
                  options: ['inline', 'list', 'textAlign'],
                  inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough']
                  },
                  list: {
                    options: ['unordered', 'ordered']
                  },
                }}
                editorClassName={['border', 'px-2', !!errors.description?.message ? 'border-red-500' : 'border-gray-100'].join(' ')}
              />
              {
                !!errors.description?.message && (
                  <small className="ml-2 text-red-500">
                    Description must not empty
                  </small>
                )
              }
            </ProductSection>

            <div className="flex mt-6">
              <button
                type="button"
                className="px-6 py-2.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-3"
                onClick={() => navigate(config.path.product)}
              >
                Back
              </button>

              <div className="ml-auto" />

              {
                hasDeleteProductAccess() && (
                  <input
                    type="button"
                    className="px-6 py-2.5 rounded-full border border-red-500 text-red-500 cursor-pointer mr-3"
                    value="Delete"
                    onClick={() => setVisibleDeleteProductModal(true)}
                  />
                )
              }


              {
                productDetail?.status === 'DRAFT' && (
                  <>
                    <input
                      type="button"
                      className="px-6 py-2.5 rounded-full border border-gray-800 text-gray-800 cursor-pointer mr-3"
                      value="Save"
                      onClick={editDraftProduct}
                    />

                    <input
                      type="submit"
                      className="px-6 py-2.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
                      value="Save and Publish"
                    />
                  </>
                )
              }
              {
                productDetail?.status === 'PUBLISHED' && (
                  <input
                    type="submit"
                    className="px-6 py-2.5 rounded-full border border-gray-800 bg-gray-800 text-white cursor-pointer"
                    value="Save"
                  />
                )
              }
            </div>
          </form>
        )
      }
    </Header>
  )
}

export default EditProductPage