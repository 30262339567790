import React from 'react'
import { FaTrash, FaGripLines, FaPencilAlt } from 'react-icons/fa'

const BaseItem = ({ children, onRemoveComponent, onUpdateComponent }) => {
  return (
    <div className="p-2 rounded-lg border border-white hover:border-gray-300 hover:shadow transition ease-in duration-200 flex gap-x-3 justify-between items-center">
      {children}

      <div className="flex flex-col gap-y-3">
        <button className="cursor-move text-lg cl-drag-handle">
          <FaGripLines />
        </button>
        <button onClick={onUpdateComponent}>
          <FaPencilAlt />
        </button>
        <button onClick={onRemoveComponent}>
          <FaTrash />
        </button>
      </div>
    </div>
  )
}

export default BaseItem